import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../../services/auth.service";
import {
  ZIPTEAMS_OAUTH_CLIENT_ID,
  ZIPTEAMS_OAUTH_ENDPOINT,
  APP_BASE_URL,
} from "../../utils/constants";

const OAuth = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("code")) {
      (async () => {
        await AuthService.authenticate(String(searchParams.get("code")));
        if ((searchParams.get("state") && searchParams.get("state")?.includes("action=ziplanding")) && searchParams.get("slug")) {
          const url = `${searchParams.get("state")}&slug=${searchParams.get("slug")}`;
          navigate(decodeURI(String(url)));
        } else {
          navigate(decodeURI(String(searchParams.get("state"))));
        }
      })();
    } else {
      const oauthURL = new URL(String(ZIPTEAMS_OAUTH_ENDPOINT));
      oauthURL.searchParams.append(
        "client_id",
        String(ZIPTEAMS_OAUTH_CLIENT_ID)
      );
      oauthURL.searchParams.append(
        "redirect_uri",
        `${String(APP_BASE_URL)}/manage/oauth`
      );
      oauthURL.searchParams.append("response_type", "code");
      oauthURL.searchParams.append(
        "state",
        (location.state as { redirect: string }).redirect
      );

      window.location.href = oauthURL.href;
    }
  }, []);

  return <></>;
};

export default OAuth;
