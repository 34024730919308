import { IFieldProps } from "../question-form/index";

const CheckboxComponent = (props: IFieldProps) => {
  const { label, required, error, options, value, onChange, defaultValue, formikProps, ...rest } = props;
  return (
    <div className="flex flex-col mb-3.5 md:mb-[18px]">
      <div className="flex mb-1 items-center">
        <label className="text-sm font-bold text-primary-dark cursor-pointer flex items-center">
          <input
            {...rest}
            disabled={Boolean(props?.disabled)}
            type="checkbox"
            defaultChecked={defaultValue === 'Y'}
            value={value}
            className="w-4 h-4 text-secondary bg-white cursor-pointer focus:ring-0 mr-2"
            onChange={(e) => {
              formikProps?.setFieldValue(props?.name, e.target.checked ? 'Y' : 'N');
            }}
          />
          {label}
        </label>
      </div>
      <div className="flex items-baseline">
        {/* <label className="text-sm font-bold text-primary-dark mb-1.5 md:mb-3">{`${label} ${
          required ? "*" : ""
        }`}</label> */}
        <div className="text-red-500 text-xs font-semibold ml-3">{error}</div>
      </div>
    </div>
  );
};

export default CheckboxComponent;
