import { API_BASE_URL } from "../utils/constants";
import HttpClient from "../utils/http-client";

class Auth {
  authenticate = (code: string) =>
    HttpClient.get(`${API_BASE_URL}/auth/token?code=${code}`, { withCredentials: true });

  verify = () =>
    HttpClient.get(`${API_BASE_URL}/auth`, { withCredentials: true });
};

const AuthService = new Auth();
export default AuthService;