import { BiWorld } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { BsCalendarEventFill, BsCameraVideoFill } from "react-icons/bs";
import { FaBuilding } from 'react-icons/fa'
import { EMBED_TYPE_POPUP } from "../../utils/constants";
import { formattedSlot } from "../../utils/helper";
import ProfileImg from "../profile-img";
import { CheckCircle } from "react-feather";

export type Slot = {
  startTime: Date;
  endTime: Date;
  timeZone: string;
};

export type ConfirmationProps = {
  organisationName: string;
  eventName: string;
  meetURL: URL | undefined;
  scheduledSlot: Slot;
  allowRestartFlow: boolean;
  embedType: string | null;
  onRestartFlow(): any;
  avatar: string;
  rescheduleText?: boolean;
  location: string
};

const Confirmation = (props: ConfirmationProps): JSX.Element => {
  const {
    organisationName,
    eventName,
    meetURL,
    scheduledSlot,
    allowRestartFlow,
    embedType,
    onRestartFlow,
    avatar,
    rescheduleText,
    location
  } = props;

  const styles = {
    wrapper: "w-full max-w-[450px] p-1 md:p-5 rounded-[18px]",
    header: {
      base: "text-primary-dark md:pb-4 mb-4 w-full flex justify-center items-center gap-3",
      label: "text-xl font-bold leading-6 mb-0.5",
      details: "text-sm md:text-base leading-4 font-normal",
    },
    body: {
      base: "text-primary-dark pt-4 border-b border-b-app-input-border",
      event: "text-base md:text-xl leading-4 font-semibold mb-[18px] underline",
      slot: {
        base: "flex items-center mb-4",
        icon_base: "flex items-center justify-center",
        icon: "w-4 md:w-5 h-4 md:h-5 text-secondary",
        label:
          "text-sm md:text-base text-primary-dark font-normal ml-2.5 md:ml-3",
        tz_base: "flex items-center mb-4",
      },
      meet: {
        base: "flex items-start mb-4",
        link: "block text-sm md:text-base max-w-[430px] break-all text-secondary font-bold ml-2.5 md:ml-3 hover:underline cursor-pointer",
      },
      email: {
        base: "text-sm md:text-base leading-4 font-semibold text-center mt-[18px] mb-3",
      },
    },
    restart_flow: {
      base: `${
        embedType === EMBED_TYPE_POPUP ? "mt-4" : "mt-auto"
      } md:mt-4 flex justify-center`,
      button: "text-secondary font-bold",
    },
  };

  return (
    <div
      className={`${
        embedType === EMBED_TYPE_POPUP ? "flex-auto " : ""
      } w-full p-4 flex flex-col items-center`}
    >
      <div className={`${styles.wrapper}`}>
        {/* <div className={`${styles.header.base}`}>
          <FaCheckCircle className="w-10 md:w-12 h-10 md:h-12 text-secondary" />
          <div className="round">
            <div className={`${styles.header.label}`}>Confirmed</div>
            <div className={`${styles.header.details}`}>
              You are scheduled with {organisationName}
            </div>
          </div>
        </div> */}
        <div className="flex gap-1 items-center my-4">
          <ProfileImg avatar={avatar} small embedType={embedType || ""} />
          <div className={`w-auto font-bold text-xl leading-6 ml-3 md:ml-4`}>
            <span>
              {rescheduleText
                ? "Meeting is rescheduled. Look forward to meeting you soon!"
                : "Meeting is scheduled. Look to meet you soon!"}
            </span>
          </div>
          <FaCheckCircle className="min-w-[20px] w-7 h-7 text-secondary ml-2 self-start mt-1.5" />
        </div>
        <div className={`${styles.body.base}`}>
          {/* <div className={`${styles.body.event}`}>{eventName}</div> */}
          <div className={`${styles.body.slot.base}`}>
            <div className={`${styles.body.slot.icon_base}`}>
              <BsCalendarEventFill className={`${styles.body.slot.icon}`} />
            </div>
            <div className={`${styles.body.slot.label}`}>
              {formattedSlot(scheduledSlot)}
            </div>
          </div>
          <div className={`${styles.body.slot.tz_base}`}>
            <div className={`${styles.body.slot.icon_base}`}>
              <BiWorld className={`${styles.body.slot.icon}`} />
            </div>
            <div className={`${styles.body.slot.label}`}>
              {scheduledSlot.timeZone}
            </div>
          </div>
          {location ? (
            <div className={`${styles.body.meet.base}`}>
              <div className={`${styles.body.slot.icon_base}`}>
                <FaBuilding className={`${styles.body.slot.icon}`} />
              </div>
              <div className={`${styles.body.slot.label}`}>
                {location}
              </div>
            </div>
          ) : (
            <div className={`${styles.body.meet.base}`}>
              <div className={`${styles.body.slot.icon_base}`}>
                <BsCameraVideoFill className={`${styles.body.slot.icon}`} />
              </div>
              <a
                href={meetURL?.href || ""}
                className={`${styles.body.meet.link}`}
                target="_blank"
                rel="noreferrer"
              >
                {meetURL?.href || ""}
              </a>
            </div>
          )}
        </div>
        <div className={`${styles.body.email.base}`}>
          A calendar invitation has been sent to your email address.
        </div>
      </div>
      {allowRestartFlow ? (
        <div className={`${styles.restart_flow.base}`}>
          <button
            type="button"
            className="mx-auto cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3.5 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none"
            onClick={onRestartFlow}
          >
            Book another meeting
          </button>
        </div>
      ) : (
        <></>
      )}
      {/* {embedType === EMBED_TYPE_POPUP && (
        <div className="mx-auto mt-2 text-sm">
          Click here to get your own{" "}
          <a
            href="https://zipteams.com/meeetingscheduling/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="font-bold text-secondary underline">zipme.at</span>
          </a>{" "}
          link
        </div>
      )} */}
    </div>
  );
};

export default Confirmation;
