import { IFieldProps } from "../question-form/index";
import UserLogo from "../../assets/icons/user-logo.svg";
import EmailLogo from "../../assets/icons/email-logo.svg";
import CompanyLogo from "../../assets/icons/company-icon.svg";
import { EyeOff, Info } from "react-feather";
import Tooltip from "../tooltip";

const InputComponent = (props: IFieldProps) => {
  const {
    type = "",
    label,
    optionalLabel,
    required,
    error,
    onChange,
    tooltipText,
    hidden,
    disabled,
    name,
    ...rest
  } = props;

  const getPrefixIcon = () => {
    if (type === "zt_contact_email" || type === "email") {
      return (
        <img
          src={EmailLogo}
          alt="mail-icon"
          className="w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3"
        />
      );
    } else if (type === "zt_customer_name") {
      return (
        <img
          src={CompanyLogo}
          alt="mail-icon"
          className="w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3"
        />
      );
    } else if (type === "zt_contact_name") {
      return (
        <img
          src={UserLogo}
          alt="mail-icon"
          className="w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3"
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="flex flex-col mb-3.5 md:mb-[18px]">
      <div className="flex items-start gap-2">
        <label
          className={`${
            !type ? "text-base" : "text-sm"
          } font-bold text-primary-dark mb-1.5 md:mb-3`}
        >
          {`${label} ${required ? "*" : ""}`}
          {hidden && disabled ? <EyeOff className="inline w-4 h-4 ml-2" /> : []}
          <span className="text-sm ml-2 text-secondary-dark font-normal">
            {optionalLabel}
          </span>
        </label>
        {tooltipText && (
          <Tooltip tooltipText={tooltipText || ""} position="bottom">
            <button className="cursor-pointer bg-transparent p-1 rounded-full ">
              <Info className="w-4 h-4 text-secondary-dark" />
            </button>
          </Tooltip>
        )}
      </div>
      <div className="relative">
        <input
          id={name}
          name={name}
          className={`${
            error
              ? "outline-red-500 border-red-500"
              : "outline-secondary border-secondary-dark"
          } block w-full px-3.5 py-2.5 ${
            type === "zt_contact_email" ||
            type === "email" ||
            type === "zt_customer_name" ||
            type === "zt_contact_name"
              ? "pl-10"
              : ""
          } text-primary-dark text-base leading-5 border rounded-lg appearance-none disabled:bg-white`}
          onInput={() => {
            const input: any = document.getElementsByName(name);
            if (input[0] && input[0]?.value) {
              const value = input[0]?.value?.replace(/<[^>]*>/g, '');
              input[0].value = value;
            }
          }}
          onChange={onChange}
          {...rest}
        />
        {type ? getPrefixIcon() : []}
      </div>
      <div className="text-red-500 text-xs mt-1 font-semibold">{error}</div>
    </div>
  );
};

export default InputComponent;
