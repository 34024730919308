import { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { addSeconds } from 'date-fns';
import { Settings } from 'react-feather';

import ToggleComponent from "../../components/common/toggle";
import { ReactComponent as LogoWhite } from '../../assets/icons/logo-white.svg';
import { ReactComponent as BackArrowWhite } from '../../assets/icons/back-arrow-white.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as Avatar } from '../../assets/icons/avatar-icon.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit-icon.svg';
import SnackBar from '../../components/snackbar';
import { convertToBase64, hexToRgb, sleep } from '../../utils/helper';
import ZipteamsService from '../../services/zipteams.service';
import EventService, { EventConfig } from '../../services/event.service';
import AuthService from '../../services/auth.service';
import ProfileImg from '../../components/profile-img';
import Loader from '../../components/loader';
import { ZIPTEAMS_OAUTH_ENDPOINT } from '../../utils/constants';
import { ReactComponent as ErrorModalIcon } from '../../assets/icons/error-modal.svg'
import styles from './common.module.css';

type SnackProps = {
    visible: boolean;
    label: string;
    type?: string;
    autoDismiss?: number | boolean;
};
type ErrorModalProps = {
  onSubmit: Function;
};

const NewUserOnb = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState<number>(1);
    const [showToggle, setShowToggle] = useState<boolean>(false);
    const [toggleEnabled, setToggleEnabled] = useState<boolean>(false);
    const [snackConfig, setSnackConfig] = useState<SnackProps>();
    const [imgError, setImgError] = useState<string>('');
    const [userId, setUserId] = useState<number>(0);
    const [selectedTab, setSelectedTab] = useState<'onboard' | 'preview'>('onboard');
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventId, setEventId] = useState<number>(0);
    const [fallbackEvent, setFallbackEvent] = useState<{ id: number, slug: string, status: string }>();
    const [showErrorModal, setShowErrorModal]=useState<boolean>(false)
    const [brandColor, setBrandColor] = useState<string>('#31667f');
    const [calloutText, setCalloutText] = useState<string>('May I help you on a quick meeting now?');
    const [realtimeLink, setRealtimeLink] = useState<string>('');
    const [organisationId, setOrganisationId] = useState<number>(0);
    const [organisationName, setOrganisationName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [eventConfigs, setEventConfigs] = useState<{
        widget_color: string,
        widget_callout: string,
        widget_callout_text: string,
        widget_cta_text: string,
        widget_position: string
      }>(
        {
            widget_color: "",
            widget_callout: "",
            widget_callout_text: "",
            widget_cta_text: "",
            widget_position: ""
        }
      );

    const [avatar, setAvatar] = useState<string>('');
    const [calloutTextError, setCalloutTextError] = useState<string>('');
    const [firstNameError, setFirstNameError] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<string>('');
    const [orgNameError, setOrgNameError] = useState<string>('');

    const linkRef = useRef<string>('');

    const fileUploadRef = useRef<HTMLInputElement>(null);
    const pages = new Array(4).fill(0);

    const handleChangeProfilePicture = async(e:ChangeEvent<HTMLInputElement>) => {
        setImgError('');
        try {
            e.preventDefault();
            const file = e?.target && e?.target?.files?.[0];
            const size = (200 * 1024); // 200KB
            const includes = [".jpeg", ".png", ".jpg"]?.includes(`.${file?.name?.substring(file?.name?.lastIndexOf(".") + 1, file?.name?.length) || ""}`);
            if (e?.target && file && file.size < size && includes) {
                const base64 = await convertToBase64(file);
                const base64ToString = base64?.toString() || '';
                const response= await ZipteamsService.updateProfilePicture({ data_url: base64ToString });
                setAvatar(response?.data?.data)

            } else {
                setImgError('Oops! We are not able to accept this image. Please use .jpeg, .jpg or .png file upto 200 KB')
            }
        } catch(err) {
            console.log(err)
        }
    }

    const ErrorModal = (props: ErrorModalProps): JSX.Element => {
      const { onSubmit } = props;

      return (
        <div className={`fixed top-0 left-0 right-0 bottom-0 bg-primary-dark/50 z-50 item-center`}>
          <div className={`absolute border rounded-md bg-white  w-[80%] md:w-[60%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] pt-3 translate-y-[-50%] top-[50%] translate-x-[-50%] left-[50%] flex flex-col items-center px-4`}>
            <ErrorModalIcon />
            <div className={`text-center text-sm lg:text-base mb-3 mt-3 lg:mt-[18px]`}>Oops! It seems something went horribly wrong here. Don't worry, we will help you fix it.</div>
            <div className={`text-center text-sm lg:text-base flex-row-reverse mb-[18px] lg:mb-6`}>
              Just email us at <span className="text-[#31667F]"> info@zipteams.com </span> or reach us on WhatsApp us on <span className="text-[#31667f]"> +91 9653198284</span>
            </div>
            <div className={`w-full flex justify-evenly mb-4`}>
              <button
                type="button"
                className={`border rounded-md py-3 px-12 bg-[#31667F] text-white font-bold`}
                onClick={() => {
                  onSubmit();
                }}
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      );
    }; 

    const loadEventData = async (showLoader: boolean = true) => {
        try {
            const slug = searchParams.get('slug') || linkRef.current || '';
            const response = await EventService.getRealtimeEvent(slug, encodeURIComponent(window.location.href));
            const {event, status} = response.data;
            if (status && status === 404) {
                setShowErrorModal(true);
                return;
            }
            if (event?.fallback_event) {
                setFallbackEvent({
                    id: event.fallback_event.id,
                    slug: event.fallback_event.slug,
                    status: event?.fallback_event?.status
                });
            }
            if (!linkRef.current) {
                const url = `${window.location.origin}${event?.slug}/instant`;
                setRealtimeLink(url);
            }
            setEventId(event?.id);
            setEventConfigs(event?.configs);
            setBrandColor(event?.configs?.widget_color || '');
            setCalloutText(event?.configs?.widget_callout || event?.configs?.widget_callout_text  || '');
        } catch(error) {
            console.error("loadEventData", error);
            setIsLoading(false);
            setShowErrorModal(true)
        }
    };

    const verifyAuth = async () => {
        try {
            await AuthService.verify();
            const response = await ZipteamsService.me();
            const { id, organisation = {}, meta = {}, first_name, last_name, email, avatar_url } = response?.data?.data;
            setFirstName(first_name || '');
            setLastName(last_name || '');
            setOrganisationName(organisation?.name);
            setOrganisationId(organisation?.id)
            setEmail(email)
            // const startDate = new Date().toISOString();
            // const endDate = addSeconds(new Date(), 10).toISOString();
            // await ZipteamsService.getUpcomingConversation(
            //     startDate,
            //     endDate,
            //     true,
            // );
            // startPolling(8000, Boolean(id));
            const { feature_flags = {} } = organisation;
            const { realtime_meeting_availaibility = false, realtime_event_link } = meta;
            const { realtime_widget_enabled = false } = feature_flags;
            if (realtime_event_link) {
                const url = new URL(realtime_event_link);
                let slug = ''
                if (url?.pathname?.includes('/realtime')) {
                  slug = url?.pathname.replace('/realtime', '');
                } else if (url?.pathname?.includes('/instant')) {
                  slug = url?.pathname.replace('/instant', '');
                }
                linkRef.current = slug;
                setRealtimeLink(realtime_event_link)
            }
            if (realtime_widget_enabled) {
                setShowToggle(true);
            }
            if (realtime_meeting_availaibility) {
                setToggleEnabled(true);
            }
            setUserId(id);
            setAvatar(avatar_url || '');
            setAuthenticated(true);
        } catch (err) {
            setAuthenticated(false);
            setShowErrorModal(true)
        }
    }
    
    const startPolling = async (duration:number, isUserSignedIn: Boolean) => {
        const startDate = new Date().toISOString();
        const endDate = addSeconds(new Date(), 10).toISOString();
        if (isUserSignedIn) {
          try {
            await ZipteamsService.getUpcomingConversation(
              startDate,
              endDate,
              true,
            );
          } catch (err) {
            console.log(err);
          }
          await sleep(duration);
          startPolling(duration, isUserSignedIn);
        }
    };

    const OnbFormPageFour = (): JSX.Element => {
        return (
            <div className={`${styles['page-container']} flex-auto flex flex-col items-center text-center text-white overflow-y-auto`}>
                <div className='text-lg leading-5 lg:text-[24px] lg:leading-9 color-white mb-[18px] lg:mb-6 font-bold'>You are all set for Instant Meeting</div>
                <div className='text-base leading-5 lg:text-xl lg:leading-6 mb-3.5 lg:mb-[30px] w-[80%]'>Send your instant meeting link to anyone or embed it on your website</div>
                <div className='w-full lg:w-[75%] flex flex-col items-center flex-auto'>
                    <div className='mb-[70px] w-full flex flex-col p-0.5 relative'>
                        <input 
                            type="text"
                            value={realtimeLink ? realtimeLink.replace(/^http(s?):\/\//i, "") : ""}
                            className='rounded py-4 pl-6 pr-[60px] text-sm leading-4 bg-white bg-opacity-10 outline-none border-none lg:rounded-lg lg:p-3.5 lg:text-base lg:leading-5 lg:pl-[52px] lg:pr-[102px]'
                            disabled
                        />
                        <button 
                            type="button" 
                            className='bg-transparent p-1 cursor-pointer rounded-full absolute top-1/2 right-[30px] lg:right-[70px] -translate-y-1/2'
                            onClick={() => {
                                navigator.clipboard.writeText(realtimeLink);
                                setSnackConfig({
                                    visible: true,
                                    autoDismiss: 2000,
                                    type: "success",
                                    label: "Copied to Clipboard",
                                });
                            }} 
                        >
                            <Copy className='w-6 h-6'/>
                        </button>
                    </div>
                    <button 
                        type="button" 
                        className='cursor-pointer flex items-center justify-center py-3.5 w-[200px] font-bold text-sm leading-4 rounded-lg bg-[#31667F] lg:text-base lg:leading-5 lg:w-[250px] lg:py-4'
                        onClick={() => {
                            const oauthurl = new URL(ZIPTEAMS_OAUTH_ENDPOINT || '');
                            window.location.href = `${oauthurl?.origin}/dashboard?action=ziplanding`
                        }}
                    >
                        Go to your dashboard
                    </button>
                    <div className='mt-3 text-sm leading-4 text-white lg:text-base lg:leading-5 lg:mt-5'>
                        <Link to="/manage/realtime-events">
                            <div className='flex gap-2 lg:gap-3 justify-center items-center'>
                                <Settings className='w-5 h-5 text-white'/>
                                <div className='text-xs md:text-sm'>Embed on website / configure other settings</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
    const OnbFormPageThree = (): JSX.Element => {
        return (
            <div className={`${styles['page-container']} flex-auto flex flex-col items-center text-center text-white overflow-y-auto`}>
                <div className='text-lg leading-5 lg:text-[24px] lg:leading-9 color-white mb-[18px] lg:mb-6 font-bold'>Let’s setup your Instant Meeting Widget</div>
                <div className='text-base leading-5 lg:text-xl lg:leading-6 mb-3.5 lg:mb-[18px]'>Activate yourself for instant meeting</div>
                <div className='w-full lg:w-[75%] flex justify-center items-start flex-auto'>
                    {showToggle && (
                        <ToggleComponent
                            checked={toggleEnabled}
                            onChange={async (val) => {
                                setToggleEnabled(val);
                                await ZipteamsService.updateUser(userId, {
                                    meta: { realtime_meeting_availaibility: val },
                                })
                            }}
                            label=""
                            size='large'
                        />
                    )}
                </div>
            </div>
        )
    }
    const OnbFormPageTwo = (): JSX.Element => {
        return (
            <div className={`${styles['page-container']} flex-auto flex flex-col items-center text-center text-white overflow-y-auto`}>
                <div className='text-lg leading-5 lg:text-[24px] lg:leading-9 color-white mb-[18px] lg:mb-6 font-bold'>Let’s setup your Instant Meeting Widget</div>
                <div className='text-base leading-5 lg:text-xl lg:leading-6 mb-3.5 lg:mb-[18px]'>Write your invite message</div>
                <div className='w-full lg:w-[75%] text-left flex-auto'>
                  <div className='mb-6 w-full flex flex-col p-0.5'>
                    <textarea
                        value={calloutText}
                        onChange={(e) => {
                            const value = e?.target?.value; 
                            if (value?.length >= 80) {
                                setCalloutTextError('Please keep this to a maximum of 80 characters for the widget to look good')
                            } else if (!value ) {
                                setCalloutTextError('Required');
                            } else {
                                setCalloutTextError('');
                            }
                            setCalloutText(value)
                        }}
                        className='resize-none h-20 rounded p-3 text-sm leading-4 bg-white bg-opacity-10 outline-none border-none lg:rounded-lg lg:p-3.5 lg:text-base lg:leading-5 lg:h-[100px]'
                    />
                    <div className='text-[#FF8291] text-xs leading-[14px] mt-4 lg:mt-1.5'>{calloutTextError}</div>  
                  </div>
                </div>
            </div>
        )
    }
    const OnbFormPageOne = (): JSX.Element => {
        return (
            <div className={`${styles['page-container']} flex-auto flex flex-col items-center text-center text-white overflow-y-auto`}>
                <div className='text-lg leading-5 lg:text-[24px] lg:leading-9 color-white mb-[18px] lg:mb-5 font-bold'>Let’s setup your Instant Meeting Widget</div>
                <div className='text-base leading-5 lg:text-xl lg:leading-6 mb-3.5 lg:mb-5'>Complete your profile</div>
                <div className='flex flex-col items-center w-[65%]'>
                    <button type="button" className='relative mb-2.5 lg:mb-5' onClick={() => fileUploadRef.current?.click()}>
                        {avatar ? (
                            <img 
                                src={avatar}
                                className='w-20 h-20 flex items-center justify-center rounded-full bg-white bg-opacity-20 lg:w-[120px] lg:h-[120px]'
                                alt="avtar"
                            />
                        ) : (
                            <div className='w-20 h-20 flex items-center justify-center rounded-full bg-white bg-opacity-20 lg:w-[120px] lg:h-[120px]'>
                                <Avatar className='w-[34px] h-[52px] lg:w-[50px] lg:h-[75px]'/>
                            </div>
                        )}
                        <input
                            type="file"
                            id="file"
                            name="data_url"
                            accept=".jpeg, .png, .jpg"
                            ref={fileUploadRef}
                            style={{ display: 'none' }}
                            onChange={handleChangeProfilePicture}
                            onClick={() => {
                                // @ts-ignore
                                fileUploadRef.current?.setAttribute('value', null);
                            }}
                            value=""
                        />
                        <div className='w-4 h-4 flex justify-center items-center bg-white rounded-full cursor-pointer absolute bottom-0 right-2 lg:right-3 lg:w-6 lg:h-6'>
                            <Edit className='w-3.5 h-3.5` lg:w-4 lg:h-4'/>
                        </div>
                    </button>
                    <div className='text-[#FF8291] text-xs leading-[14px] mt-4 lg:mt-1.5'>{imgError}</div>
                </div>
                <div className='w-full lg:w-[75%] text-left flex-auto'>
                    <div className='flex gap-2.5 lg:gap-5 p-0.5 mb-6'>
                        <div className='w-[48%] flex flex-col'>
                            <label className='text-sm leading-4 lg:text-base lg:leading-5 mb-2'>First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (!value) {
                                        setFirstNameError('Required');
                                    } else {
                                        setFirstNameError('')
                                    }
                                    setFirstName(e?.target?.value)
                                }} 
                                className='rounded p-3 text-sm leading-4 bg-white bg-opacity-10 outline-none border-none lg:rounded-lg lg:p-3.5 lg:text-base lg:leading-5'
                            />
                            <div className='text-[#FF8291] text-xs leading-[14px] mt-1 lg:mt-1.5'>{firstNameError}</div>
                        </div>
                        <div className='w-[48%] flex flex-col'>
                            <label className='text-sm leading-4 lg:text-base lg:leading-5 mb-2'>Last Name</label>
                            <input 
                                type="text"
                                value={lastName}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (!value) {
                                        setLastNameError('Required');
                                    } else {
                                        setLastNameError('')
                                    }
                                    setLastName(e?.target?.value)
                                }}
                                className='rounded p-3 text-sm leading-4 bg-white bg-opacity-10 outline-none border-none lg:rounded-lg lg:p-3.5 lg:text-base lg:leading-5'
                            />
                            <div className='text-[#FF8291] text-xs leading-[14px] mt-1 lg:mt-1.5'>{lastNameError}</div>
                        </div>
                    </div>
                    <div className='flex gap-2.5 lg:gap-5 p-0.5 mb-6'>
                        <div className='w-[65%] flex flex-col'>
                            <label className='text-sm leading-4 lg:text-base lg:leading-5 mb-2'>Organisation Name</label>
                            <input 
                                type="text"
                                value={organisationName}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    if (!value) {
                                        setOrgNameError('Required');
                                    } else {
                                        setOrgNameError('')
                                    }
                                    setOrganisationName(e?.target?.value)
                                }}
                                className='rounded p-3 text-sm leading-4 bg-white bg-opacity-10 outline-none border-none lg:rounded-lg lg:p-3.5 lg:text-base lg:leading-5'
                            />
                            <div className='text-[#FF8291] text-xs leading-[14px] mt-1 lg:mt-1.5'>{orgNameError}</div>
                        </div>
                        <div className='w-[35%] flex flex-col'>
                            <label className='text-sm leading-4 lg:text-base lg:leading-5 mb-2 w-full whitespace-nowrap text-ellipsis'>Brand Color</label>
                            <input 
                                type="color"
                                value={brandColor}
                                onChange={(e) => setBrandColor(e?.target?.value)}
                                className='w-full h-full rounded p-1 bg-white bg-opacity-10 outline-none border-none lg:rounded-lg lg:p-1.5'
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-col p-0.5'>
                        <label className='text-sm leading-4 lg:text-base lg:leading-5 mb-2'>Your Business Email</label>
                        <input 
                            type="text"
                            disabled
                            value={email}
                            className='rounded p-3 text-sm leading-4 bg-white bg-opacity-10 outline-none border-none lg:rounded-lg lg:p-3.5 lg:text-base lg:leading-5 disabled:text-secondary-dark'
                        />
                    </div>
                </div>
            </div>
        )
    }
    const renderPage = () => {
        switch(page) {
            case 1: return OnbFormPageOne()
            case 2: return OnbFormPageTwo()
            case 3: return OnbFormPageThree()
            case 4: return OnbFormPageFour()
            default: return []
        }
    }

    const RealtimePreviewSection = () => {
        return (
            <div className='flex-auto flex flex-col items-center mx-auto p-4 overflow-y-auto bg-white lg:justify-center'>
                <div className='flex flex-col items-center text-center mb-6 lg:w-[52%]'>
                    <div className='font-bold text-base leading-5 mb-2 lg:mb-3 lg:text-2xl lg:leading-7'>Instant Meeting Widget Preview</div>
                    <div className='text-xs leading-4 text-secondary-dark lg:text-base lg:leading-5'>You can send this as a instant meeting URL or embed it on your website or landing pages to get interested visitors to meet you or your team instantly</div>
                </div>

                <div
                    style={{
                        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.08)",
                    }}
                    className={`
                        w-full h-[80%] items-center flex-auto flex flex-col border border-primary-gray rounded-[18px] p-3 bg-white 
                        md:max-w-[500px] max-h-[420px] md:max-h-[530px] md:rounded-3xl
                    `}
                >
                    <div className='flex-auto flex flex-col justify-center items-center'>
                        <ProfileImg setDefaultImg={false} avatar={avatar} />
                        <div className="w-3/4 mt-2 flex flex-col justify-center items-center">
                            <div className='font-bold text-base leading-5 text-center lg:text-2xl lg:leading-7 mb-[18px] lg:mb-6'>{`Hi, I'm ${firstName}, ${calloutText}`}</div>
                            <button 
                                type='button'
                                disabled={!toggleEnabled}
                                className='cursor-pointer w-[200px] flex justify-center items-center py-3 bg-secondary font-bold text-sm leading-4 rounded-lg text-white disabled:bg-opacity-60 disabled:pointer-events-none mb-2 lg:mb-3 lg:py-4 lg:w-[250px] lg:text-base lg:leading-5 lg:rounded-xl'
                            >
                                Sure, I’ll join in
                            </button>
                            {/* <div className='text-secondary-dark text-xs leading-4 underline lg:text-base lg:leading-5 cursor-pointer'>
                                Schedule a demo for later
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    } 

    const onNextClick = async () => {
        if (page >= pages.length) return;
        if (calloutTextError || firstNameError || lastNameError || orgNameError ) return;
        if (!avatar && page === 1) setImgError('Please add your profile image to continue. That makes the widget look great to your guests')
        setPage((prev) => prev + 1);
        if (page === 1) {
            const configs = {
                ...eventConfigs,
                widget_color: brandColor,
                widget_callout: calloutText,
                widget_callout_text: calloutText,
            }
            await EventService.editRealtimeEventConfig(eventId, {configs})
            await loadEventData(false);
            ZipteamsService.updateOrgName(organisationId, {name: organisationName});
            ZipteamsService.updateProfile({
                first_name: firstName || '', 
                last_name: lastName || '',
            });
        } else if (page === 2) {
            const configs = {
                ...eventConfigs,
                widget_color: brandColor,
                widget_callout: calloutText,
                widget_callout_text: calloutText
            }
            await EventService.editRealtimeEventConfig(eventId, {configs})
            await loadEventData()
        }
    }

    const onPrevClick = () => {
        if (page <= 1) return;
        setPage((prev) => prev - 1);
    }
    
    const isFirstPage = () => page <= 1;
    const isLastPage = () => page >= pages.length;

    const init = async () => {
        try {
            setIsLoading(true);
            await verifyAuth();
            await loadEventData();
            setIsLoading(false);
        } catch(err) {
            console.log(err);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        init()
    }, [])

    useEffect (() => {
        if (brandColor) {
            document.documentElement.style.setProperty('--color-secondary', hexToRgb(brandColor && brandColor.includes('#') ? brandColor : `#${brandColor}` || '#000000'));
        }
    } , [brandColor])
    if (isLoading) return <Loader />

    return (
      <>
        {showErrorModal && (
          <ErrorModal
            onSubmit={() => {
              const oauthurl = new URL(ZIPTEAMS_OAUTH_ENDPOINT || "");
              window.location.href = `${oauthurl?.origin}/dashboard?action=ziplanding`;
              setShowErrorModal(false);
            }}
          />
        )}
        <SnackBar
          visible={snackConfig?.visible || false}
          autoDismiss={snackConfig?.autoDismiss || false}
          label={snackConfig?.label || ""}
          type={snackConfig?.type || ""}
          onClose={() => {
            setSnackConfig((data) => ({
              ...data,
              label: "",
              autoDismiss: false,
              visible: false,
            }));
          }}
          isRealtime
        />
        <div className="flex flex-col min-h-screen h-screen">
          <div className="flex w-full flex-auto overflow-y-auto">
            <div className="flex flex-col w-full bg-[#14262F] pt-5 pb-3 px-4 lg:w-2/5 lg:p-[30px] lg:pb-6">
              <div className="bg-transparent flex items-center justify-center gap-2 lg:gap-2 lg:justify-start">
                <LogoWhite className="w-6 h-6 lg:w-10 lg:h-10" />
                <span className="font-fjalla text-white text-xl mt-0.5 leading-6 lg:text-[28px] lg:leading-9">
                  ZIPTEAMS
                </span>
              </div>
              <div className="flex-auto flex flex-col mt-6 border border-[#D7D7D7] rounded-lg overflow-y-auto lg:mt-4 xl:mt-16 lg:border-none lg:rounded-none">
                <div className="flex lg:hidden p-3">
                  <button
                    className={`cursor-pointer w-full flex justify-center items-center py-3 ${
                      selectedTab === "onboard"
                        ? "bg-[#31677f]"
                        : "bg-transparent"
                    } font-bold text-sm leading-4 rounded text-white disabled:bg-opacity-60 disabled:pointer-events-none lg:py-4 lg:text-base lg:leading-5`}
                    onClick={() => setSelectedTab("onboard")}
                  >
                    Onboarding
                  </button>
                  <button
                    className={`cursor-pointer w-full flex justify-center items-center py-3 ${
                      selectedTab === "preview"
                        ? "bg-[#31677f]"
                        : "bg-transparent"
                    } font-bold text-sm leading-4 rounded text-white disabled:bg-opacity-60 disabled:pointer-events-none lg:py-4 lg:text-base lg:leading-5`}
                    onClick={() => setSelectedTab("preview")}
                  >
                    Preview
                  </button>
                </div>
                {selectedTab === "onboard" && (
                  <div className="flex-auto flex flex-col py-3 px-3 overflow-y-auto lg:px-0 lg:py-0">
                    <div className="flex-auto flex rounded-lg max-w-[256rem] overflow-y-auto overflow-x-hidden">
                      {pages &&
                        pages.map((_, idx) => (
                          <div
                            key={`page${idx}`}
                            className="flex-auto min-w-full flex"
                            style={{
                              transform: `translateX(${page - 1 ? "-" : ""}${
                                (page - 1) * 100
                              }%)`,
                              transition: "transform 0.3s ease-in-out",
                              visibility:
                                page !== idx + 1 ? "hidden" : "visible",
                            }}
                          >
                            {renderPage()}
                          </div>
                        ))}
                    </div>
                    <div className="w-full flex justify-between items-center">
                      {isFirstPage() ? (
                        <div className="w-9 h-9 lg:w-[42px] lg:h-[42px] invisible pointer-events-none" />
                      ) : (
                        <button
                          className="p-1.5 bg-transparent cursor-pointer hover:bg-white hover:bg-opacity-20 rounded-full"
                          onClick={onPrevClick}
                        >
                          <BackArrowWhite className="w-6 h-6 lg:w-[30px] lg:h-[30px] transform rotate-180" />
                        </button>
                      )}
                      <div className="flex items-center gap-0.5 lg:gap-1">
                        {pages &&
                          pages.map((_, idx) => (
                            <div
                              className={`${
                                page === idx + 1
                                  ? "w-2 h-2 bg-white lg:w-2.5 lg:h-2.5"
                                  : "w-1.5 h-1.5 bg-secondary-dark lg:w-2 lg:h-2"
                              } rounded-full`}
                            />
                          ))}
                      </div>
                      {isLastPage() ? (
                        <div className="w-9 h-9 lg:w-[42px] lg:h-[42px] invisible pointer-events-none" />
                      ) : (
                        <button
                          className="p-1.5 bg-transparent cursor-pointer hover:bg-white hover:bg-opacity-20 rounded-full"
                          onClick={onNextClick}
                        >
                          <BackArrowWhite className="w-6 h-6 lg:w-[30px] lg:h-[30px]" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {selectedTab === "preview" && <RealtimePreviewSection />}
              </div>
            </div>
            <div className="hidden w-3/5 lg:flex overflow-y-auto">
              <RealtimePreviewSection />
            </div>
          </div>
        </div>
      </>
    );
}

export default NewUserOnb;