import { isToday, format } from "date-fns";
import { useContext } from "react";
import { ScheduledContext } from "../../apps/scheduled";
import { hexToRgb } from "../../utils/helper";
import WeekHeader from "./week-header";
import { RescheduledContext } from "../../apps/reschedule-meeting";

export type DateCellProps = {
  date: Date;
  isVisible: boolean;
  isSelected: boolean;
  isSelectable: boolean;
  onSelect(date: Date): any;
};

const DateCell = (props: DateCellProps): JSX.Element => {
  const { date, isVisible, isSelectable, isSelected, onSelect } = props;

  const styles = {
    parent: {
      base: "rounded-[16px] border border-transparent cursor-pointer p-1",
      selectable: "bg-transparent",
      selected: "bg-secondary border-white",
      non_selectable: "cursor-default pointer-events-none",
    },
    wrapper: {
      base: "flex-grow basis-0 max-w-full flex items-center justify-center h-[32px] mt-1",
      visible: "visible",
      invisible: "invisible pointer-events-none",
    },
    button: {
      base: "w-[40px] h-full text-base leading-5 rounded-full outline-none relative",
      selectable: "text-primary-dark font-semibold",
      selected: "text-white font-semibold",
      non_selectable: "text-app-input-border pointer-events-none",
    },
    marker: {
      base: "w-1 h-1 rounded-full absolute bottom-0 left-1/2 transform -translate-x-1/2",
      selectable: "bg-secondary",
      selected: "bg-white",
      non_selectable: "bg-secondary-dark",
    },
  };

  const context = useContext(ScheduledContext);
  const rescheduledContext = useContext(RescheduledContext);
  const contextThemeColor = rescheduledContext?.themeColor || context?.themeColor;
  const rgbColor = `${hexToRgb(contextThemeColor.includes('#') ? contextThemeColor : `#${contextThemeColor}`)}`;

  return (
    <div
      className={`
        ${styles.parent.base} 
        ${
          !isSelectable
            ? styles.parent.non_selectable
            : isSelected
            ? styles.parent.selected
            : styles.parent.selectable
        }
      `}
      style={isSelected ? {
        background: `linear-gradient(180deg, rgba(${rgbColor}, 0.75), rgba(${rgbColor}, 1))`,
        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
      } : {}}
      onClick={!isSelectable ? () => {} : () => onSelect(date)}
    >
      <WeekHeader
        currentDate={date} 
        isSelected={isSelected}
        isSelectable={isSelectable}
      />
      <div
        className={`
          ${styles.wrapper.base} 
          ${isVisible ? styles.wrapper.visible : styles.wrapper.invisible}
        `}
      >
        <button
          type="button"
          className={`
          ${styles.button.base} 
          ${
            !isSelectable
              ? styles.button.non_selectable
              : isSelected
              ? styles.button.selected
              : styles.button.selectable
          }
        `}
        >
          <span>{format(date, "d")}</span>
          {isToday(date) ? (
            <div
              className={`
              ${styles.marker.base} 
              ${
                !isSelectable
                  ? styles.marker.non_selectable
                  : isSelected
                  ? styles.marker.selected
                  : styles.marker.selectable
              }
            `}
            />
          ) : (
            <></>
          )}
        </button>
      </div>
    </div>
  );
};

export default DateCell;
