import ReactGA from "react-ga4";

export class Tracking {
  static initialise(tracking_id: string) {
    return ReactGA.initialize(tracking_id, { gaOptions: { cookieFlags: 'SameSite=None;Secure' }});
  }

  static pageview() {
    return ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }

  static eventDateSelected(date: string) {
    return ReactGA.event("scheduled_date_click", { date });
  }

  static eventSlotSelected(slot: string) {
    return ReactGA.event("scheduled_slot_click", { slot });
  }

  static eventFormSubmit() {
    return ReactGA.event("scheduled_form_submit");
  }

  static eventMeetLinkClicked() {
    return ReactGA.event("scheduled_meet_link_click");
  }

  static eventSlotNotAvailable() {
    return ReactGA.event("scheduled_meet_slot_not_available");
  }

  static eventCouldNotAllocateUser() {
    return ReactGA.event("scheduled_meet_could_not_allocate_user");
  }

  static eventRealtimeFormSubmit() {
    return ReactGA.event("realtime_form_submit");
  }

  static eventRealtimeScheduleLaterClicked(source: "form" | "allocation_failed") {
    return ReactGA.event("realtime_schedule_later_click", { source });
  }

  static eventRealtimeAllocationFailed() {
    return ReactGA.event("realtime_allocation_failed");
  }

  static meetingLinkCopied() {
    return ReactGA.event("realtime_meeting_link_copied");
  }

  static newTabClicked() {
    return ReactGA.event("realtime_new_tab_clicked");
  }
}