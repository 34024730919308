import { EyeOff } from "react-feather";
import { IFieldProps } from "../question-form/index";

const TextAreaComponent = (props: IFieldProps) => {
  const { label, required, error, disabled, hidden, name, ...rest } = props;
  return (
    <div className="flex flex-col mb-3.5 md:mb-[18px]">
      <label className="text-sm font-bold text-primary-dark mb-1.5 md:mb-3">
        {`${label} ${required ? "*" : ""}`}
        {hidden && disabled ? <EyeOff className="inline w-4 h-4 ml-2"/> : []}
      </label>
      <textarea
        name={name}
        className={`${
          error
            ? "focus:outline-red-500 focus:outline-offset-0 focus:border-red-500 focus-visible:outline-red-500 border-red-500"
            : "focus:outline-secondary focus:outline-offset-0 focus:border-secondary-dark focus-visible:outline-secondary border-secondary-dark "
        }  block w-full px-3.5 py-2.5 text-primary-dark text-base leading-5 border rounded-lg appearance-none resize-y min-h-[100px] focus:ring-0 focus:shadow-none`}
        onInput={() => {
          const input: any = document.getElementsByName(name);
          if (input[0] && input[0]?.value) {
            const value = input[0]?.value?.replace(/<[^>]*>/g, '');
            input[0].value = value;
          }
        }}
        {...rest}
      />
      <div className="text-red-500 text-xs mt-1 font-semibold">{error}</div>
    </div>
  );
};

export default TextAreaComponent;
