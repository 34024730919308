import { useEffect, useRef, useState } from "react";
import styles from './common.module.css';
import { FiCopy as Copy, FiExternalLink as ExternalLink } from 'react-icons/fi';
import MicSVG from '../../assets/icons/mic.svg';
import MicOffSVG from '../../assets/icons/mic-off.svg';
import PhoneSVG from '../../assets/icons/phone.svg';

declare var window: Window;
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}

type VideoMeetProps = {
  jwt: string;
  roomId: string;
  displayName: string;
  handleCallEnd: () => void;
  handleMeetLoad: () => void;
  videoDomain: string;
  roomUrl: string;
  handleCopyToClipboard: () => void;
  handleOpenNewTabClick: () => void;
  isRecordingEnabled: boolean;
  voiceCall: boolean;
  allocatedUserName?: string;
  allocatedUserAvatar?: string;
};

const VideoMeet = (props: VideoMeetProps): JSX.Element => {
  const {
    handleCallEnd,
    handleMeetLoad,
    jwt,
    roomId,
    displayName,
    videoDomain,
    roomUrl,
    handleCopyToClipboard,
    handleOpenNewTabClick,
    isRecordingEnabled,
    voiceCall,
    allocatedUserName,
    allocatedUserAvatar
  } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [isMeetingRecorded, setIsMeetingRecorded] = useState<boolean>(false);
  const [isCopiedtoClipboard, setIsCopiedToClipboard] = useState<boolean>(false);
  const [avatarUrlBroken, setAvatarUrlBroken] = useState<boolean>(false);
  const isMeetingRecordedRef = useRef<boolean>(false);
  const isCopiedToClipboardRef = useRef<boolean>(false);
  const isRecordingEnabledRef = useRef<boolean>(true);
  const api = useRef<any>(null);
  const audioMutedRef = useRef<boolean>(true)

  isMeetingRecordedRef.current = isMeetingRecorded;
  isRecordingEnabledRef.current = isRecordingEnabled;

  const handleOnIframeLoad = () => {
    setLoading(false);
    handleMeetLoad();
  };

  const handleVideoConferenceJoined = () => {
    const participants = api?.current?.getParticipantsInfo();
    if (participants && participants?.length === 1 && isRecordingEnabledRef.current) {
      api?.current?.executeCommand('toggleSubtitles');
      setIsMeetingRecorded(true);
      isMeetingRecordedRef.current = true;
    }
    
    const listener = ({ enabled }: any) => {
      api?.current?.removeEventListener(`tileViewChanged`, listener);

      if (!enabled) {
        api?.current?.executeCommand(`toggleTileView`);
      }
    };

    api?.current?.addEventListener(`tileViewChanged`, listener);
    api?.current?.executeCommand(`toggleTileView`);
  };

  const handleParticipantJoined = () => {
    // whenenver participant joins, a msg is sent to all participants
    // if room recording needs to be started 
    setTimeout(() => {
      if (isMeetingRecordedRef.current) sendDataMessageToAllParticipants('ROOM_RECORDING_START');
    }, 1000);
  };

  const sendDataMessageToAllParticipants = (msg: string) => {
    // ErrorHandler.captureMessage(`Sending message to all participants ${msg}`);
    const participants = api?.current?.getParticipantsInfo();
    if (!participants?.length) return;
    for (let idx = 0; idx < participants?.length; idx++) {
      const participant = participants?.[idx];
      api?.current?.executeCommand(
        'sendEndpointTextMessage',
        participant?.participantId,
        msg,
      );
    }
  };

  const handleIncomingMessage = () => {
    // some action to be performed when incoming message
  };

  const handleReadyToClose = async () => {
    // some action to be performed here when call ended
    handleCallEnd();
  };

  const handleEndpointMsgReceive = (param: any) => {
    const { data } = param;
    const { eventData } = data;
    const { text } = eventData;
    // if ROOM_RECORDING_STOPPED, means stop recording is clicked
    // also setting a flag
    if (text === "ROOM_RECORDING_STOPPED" && isMeetingRecordedRef.current && isRecordingEnabledRef?.current) {
      console.log("messageRecieved - ROOM_RECORDING_STOPPED, stop capture");
      api?.current?.executeCommand("stopScreenshotCapture");
      api?.current?.executeCommand("toggleSubtitles");
      setIsMeetingRecorded(false);
      isMeetingRecordedRef.current = false;
    }

    // New participant joins meeting record flag is false by default
    // other participants will send a msg ROOM_RECORDING_START, to turn it on for new participant 
    // checking the current status of meeting record flag, 
    // to avoid multiple triggers as all participants send msg on joining
    if (text === 'ROOM_RECORDING_START' && !isMeetingRecordedRef.current && isRecordingEnabledRef?.current) {
      console.log('messageRecieved - ROOM_RECORDING_START, start capture');
      api?.current?.executeCommand('toggleSubtitles');
      // ErrorHandler.captureMessage('Substitles turned on');
      setIsMeetingRecorded(true);
      isMeetingRecordedRef.current = true;
    }
  };

  const handleScreenSharingChange = async (param: any) => {
    const { on } = param;
    if (on && isMeetingRecordedRef.current) {
      console.log("screen share - start, start capture");
      api?.current?.executeCommand("startScreenshotCapture");
    } else {
      console.log("screen share - stop, stop capture");
      api?.current?.executeCommand("stopScreenshotCapture");
    }
  };

  useEffect(() => {
    if (window && window?.JitsiMeetExternalAPI) {
      try {
        const domain = videoDomain || process.env.REACT_APP_MEET_DOMAIN_URL;
        const options = {
          roomName: roomId,
          jwt: jwt,
          parentNode: document.querySelector("#video-meet"),
          userInfo: {
            displayName: displayName,
          },
          interfaceConfigOverwrite: {
            filmStripOnly: false,
            SHOW_JITSI_WATERMARK: false,
          },
          onload: handleOnIframeLoad,
          configOverwrite: {
            disableSimulcast: false,
            doNotStoreRoom: true,
            startWithAudioMuted: true, // state?.isAudioMuted,
            startWithVideoMuted: true, // state?.isVideoOff,
            // use the subject property to set the display name of the room
            subject: "", // state?.roomName,
            openBridgeChannel: true,
            p2p: {
              enabled: false,
            },
            disableTileView: false,
            toolbarButtons: [
              "microphone",
              "camera",
              "desktop",
              "chat",
              "hangup",
            ],
          },
          logLevel: "error",
        };
        api.current = new window.JitsiMeetExternalAPI(domain, options);
      } catch (error) {
        console.error("Failed to load API", error);
      }
    }

    if (api.current) {
      api?.current.addEventListener(
        "videoConferenceJoined",
        handleVideoConferenceJoined
      );
      api?.current?.addEventListener('participantJoined', handleParticipantJoined);
      api?.current.addEventListener("incomingMessage", handleIncomingMessage);
      api?.current.addEventListener("readyToClose", handleReadyToClose);

      api?.current.addEventListener("cameraError", (err: any) => {
        console.error("cameraError", err);
        // ErrorHandler.captureError(err.message);
      });
      api?.current.addEventListener("browserSupport", (err: any) => {
        if (!err.supported) {
          console.error("Browser not supported");
          // ErrorHandler.captureError("Browser not supported");
        }
      });
      api?.current.addEventListener("errorOccurred", (err: any) => {
        console.error("errorOccurred", err);
        // ErrorHandler.captureError(err.error.message);
      });
      api?.current.addEventListener("micError", (err: any) => {
        console.error("errorOccurred", err);
        // ErrorHandler.captureError(err.message);
      });
      api?.current.addEventListener(
        "endpointTextMessageReceived",
        handleEndpointMsgReceive
      );
      api?.current.addEventListener(
        "screenSharingStatusChanged",
        handleScreenSharingChange
      );
      api?.current.addEventListener(
        "audioMuteStatusChanged",
        ({ muted }: {muted: boolean}) => { 
          // setAudioMuted(muted);
          audioMutedRef.current = muted;
        }
      );
    }

    return () => {
      if (api?.current) {
        api?.current.removeListener(
          "videoConferenceJoined",
          handleVideoConferenceJoined
        );
        api?.current?.removeListener('participantJoined', handleParticipantJoined);
        api?.current.removeListener("incomingMessage", handleIncomingMessage);
        api?.current.removeListener("readyToClose", handleReadyToClose);

        api?.current.removeListener("cameraError", () => {});
        api?.current.removeListener("browserSupport", () => {});
        api?.current.removeListener("errorOccurred", () => {});
        api?.current.removeListener("micError", () => {});

        api?.current.removeListener(
          "endpointTextMessageReceived",
          handleEndpointMsgReceive
        );
        api?.current.removeListener(
          "screenSharingStatusChanged",
          handleScreenSharingChange
        );
        api?.current.addEventListener(
          "audioMuteStatusChanged",
          () => {}
        );
        isMeetingRecordedRef.current = false;
        api?.current.dispose();
      }
    };
  }, []);

  const copyToClipboard = () => {
    if (roomUrl) {
      handleCopyToClipboard();
      navigator.clipboard.writeText(roomUrl);
      if (isCopiedToClipboardRef) {
        setIsCopiedToClipboard(true);
        isCopiedToClipboardRef.current = true;
        setTimeout(() => {
          setIsCopiedToClipboard(false);
          isCopiedToClipboardRef.current = false
        }, 1500);
      }
    }
  };

  const openInNewTab = () => {
    if (roomUrl) {
      handleOpenNewTabClick();
      window.open(roomUrl, "_blank")?.focus();
    }
  };

  const secondsToHHMMSS = (seconds: number) =>
    new Date(seconds * 1000).toISOString().substr(14, 5);

  const VoiceCallPage = () => {
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [audioMute, setAudioMute] = useState<boolean>(true);
    const timerRef = useRef<any>(null);

    useEffect(() => {
      setAudioMute(audioMutedRef.current);
    }, [audioMutedRef.current])
    
    useEffect(() => {
      if (!loading) {
        timerRef.current = setInterval(() => {
          setCurrentTime((prev) => prev + 1);
        }, 1000);
      }
      return () => {
        if (timerRef.current) clearInterval(timerRef.current);
      };
    }, [loading]);

    return (
      <div className="absolute h-full w-full bg-white flex flex-col">
        <div className="w-full">
          <div className="mb-2 text-lg leading-[18px] font-bold text-center">Connected to our expert</div>
          <div className="text-lg leading-[18px] font-bold text-center text-secondary">{allocatedUserName}</div>
          <div
            className="text-center mt-3 text-xl leading-[26px] font-bold"
          >
            {secondsToHHMMSS(currentTime)}
          </div>
        </div>
        <div className="w-full flex relative">
          <div className="absolute w-[14rem] h-[14rem] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full bg-secondary opacity-20"/>
          <div className="absolute w-[14rem] h-[14rem] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-secondary rounded-full animate-pulsate z-[1]" />
          <div className="w-[14rem] h-[14rem] my-8 mx-auto rounded-full flex justify-center items-center z-[2]">
            <img
              className={`${avatarUrlBroken || !allocatedUserAvatar ? 'hidden' : 'block'} h-[90%] w-[90%] rounded-full`}
              src={allocatedUserAvatar}
              alt="avatar"
              onError={() => {
                setAvatarUrlBroken(true);
              }}
            />
          </div>
        </div>
        <div className="w-full flex justify-center gap-4 my-auto mx-0">
          <button
            type="button"
            className="w-[42px] h-[42px] rounded flex justify-center items-center border-0 cursor-pointer bg-black"
            onClick={() => api?.current?.executeCommand('toggleAudio')}
          >
            {audioMute ? <img src={MicOffSVG} alt="mic-off" /> : <img src={MicSVG} alt="mic" />}
          </button>
          <button
            type="button"
            className="w-[42px] h-[42px] rounded flex justify-center items-center border-0 cursor-pointer bg-[#F0142F]"
            onClick={() => api?.current?.executeCommand('hangup')}
          >
            <img src={PhoneSVG} alt="phone" style={{ rotate: "135deg" }} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div id="zt-vmeet-container-elm" className={styles["zt-vmeet-container"]}>
      <div
        className={`absolute top-2 right-2 flex gap-2.5`}
      >
        <button className="zt-vmeet-actions-copy" onClick={copyToClipboard}>
          <Copy className="w-4 h-4 rotate-90 text-white"/>
        </button>
        <button className="zt-vmeet-actions-newtab" onClick={openInNewTab}>
          <ExternalLink className="w-4 h-4 text-white" />
        </button>
      </div>
      
      <div 
        className={`absolute top-1 left-0.5 bg-[#00AE2F] py-2 px-4 text-sm rounded text-white 
          ${isCopiedToClipboardRef?.current ? 'w-auto flex' : 'hidden w-0'}
        `}
      >
        Meeting Link Copied
      </div>
      <div id="video-meet" className={styles["zt-vmeet"]} />
      {voiceCall && <VoiceCallPage />}
    </div>
  );
};

export default VideoMeet;
