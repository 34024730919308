import DefaultProfile from "../../assets/images/default_profile.png";
import { ReactComponent as Avatar } from '../../assets/icons/avatar-icon.svg';
import styles from './common.module.css';
import { EMBED_TYPE_POPUP } from "../../utils/constants";

type ProfileImgProps = {
  avatar: string;
  small?: boolean;
  setDefaultImg?: boolean
  embedType?: string
};

const ProfileImg = ({ avatar, small = false, setDefaultImg = true, embedType = '' }: ProfileImgProps): JSX.Element => {
  return (
    <div id="zt-profile-img-container-elm" className={`relative ${small ? '' : 'px-8 mb-4'}`}>
      {!setDefaultImg && !avatar? (
        <div
          className={`rounded-full max-w-none ${small ? 'w-11 h-11 md:w-16 md:h-16' : 'w-[140px] h-[140px] md:w-[200px] md:h-[200px]'} bg-secondary flex justify-center items-center`}
        >
          <Avatar className='w-[52px] h-[78px] lg:w-[75px] lg:h-[112px]'/>
        </div>
      ) : (
        <img
          src={avatar || DefaultProfile}
          alt="profile"
          className={`${small ? '' : styles['user-img']} rounded-full max-w-none ${small ? embedType === EMBED_TYPE_POPUP ? 'w-16 h-16' : 'w-11 h-11 md:w-16 md:h-16' : 'w-[140px] h-[140px] md:w-[200px] md:h-[200px]'}`}
        />
      )}
      <div className={`${small ? 'w-[3px] h-[3px] md:w-[5px] md:h-[5px] right-[0.125rem] md:right-[0.25rem]' : 'w-2.5 h-2.5 md:w-3.5 md:h-3.5 right-[2.5rem] md:right-[3.25rem]'} bottom-0 bg-secondary opacity-60 absolute rounded-full`} />
      <div className={`${small ? 'w-[4px] h-[4px] md:w-[7px] md:h-[7px] right-0 md:right-0.5' : 'w-3.5 h-3.5 md:w-5 md:h-5 right-6 md:right-8'} bg-secondary absolute top-0 rounded-full`} />
      <div className={`${small ? 'w-[4px] h-[4px] md:w-[7px] md:h-[7px] top-1 md:top-2 -left-[0.1rem] md:-left-0.5' : 'w-3.5 h-3.5 md:w-5 md:h-5 top-7 left-2.5 md:left-2 md:top-12'} bg-secondary opacity-30 absolute rounded-full`} />
    </div>
  );
};

export default ProfileImg;
