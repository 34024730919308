/* eslint-disable array-callback-return */
import { FormikHelpers, FormikValues, useFormik } from "formik";
import { ChangeEventHandler, FocusEventHandler, useEffect, useState } from "react";
import Autocomplete from "../common/autocomplete";
import CheckboxComponent from "../common/checkbox";
import InputComponent from "../common/input";
import PhoneNumberInputComponent from "../common/phonenumber";
import TextAreaComponent from "../common/textarea";
import ThreeDotLoader from "../loader/three-dots-loader";
import ProfileImg from "../profile-img";

export type IFieldProps = {
  type?: string;
  label: string;
  optionalLabel?: string;
  required: boolean;
  hidden?: boolean;
  name: string;
  error: string | false | undefined;
  value: string;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  options?: {label: string; user_allocated?: boolean; tag_name?: string; step_question?: { step_id: string; label: string } }[] | null;
  disabled?: boolean;
  tooltipText?: string;
  defaultValue?: string;
  formikProps?: any
  onOptionsSelect?: (id: string, questionId: string) => void;
  country?: string;
};

export type FormElement = {
  id: number;
  type:
    | "zt_contact_name"
    | "zt_contact_email"
    | "zt_customer_name"
    | "zt_contact_phone_number"
    | "single_line"
    | "multi_line"
    | "radio_button"
    | "phone_number"
    | "email"
    | "disclaimer";
  is_required: boolean;
  default_value?: string;
  hidden?: boolean;
  is_step?: boolean;
  step_id?: string;
  label: string;
  options?: { label: string; user_allocated?: boolean; tag_name?: string; step_question?: { step_id: string; label: string }}[] | null;
  event_id?: number;
};

export type FormProps = {
  form: FormElement[];
  handleSubmit: Function;
  submitText: string;
  preview?: boolean;
  prefill?: { [key: string]: string };
  avatar?: string;
  embedType?: string;
  businessEmail?: boolean;
  prefillPhoneNumber?: string;
  prefillEmail?: string;
  prefillCustomerName?: string;
  prefillCompanyName?: string;
  isCRMPrefill?: boolean;
  organisationId: number;
  setVisibleStepIds: (data: { [key: string]: string }) => void;
  country: string;
};

export const renderForm = (
  field: FormElement,
  preview: boolean | undefined,
  formik?: FormikValues,
  onOptionsSelect?: (id: string, questionId: string) => void,
  country?: string
) => {
  const { type, id, label, is_required, options, default_value, hidden } = field; 
  switch (type) {
    case "single_line":
    case "zt_contact_name":
    case "zt_customer_name":  
      return (
        <InputComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
        />
      );
    case "multi_line":
      return (
        <TextAreaComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
        />
      );
    case "radio_button":
      return (
        <Autocomplete
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          options={options}
          disabled={preview}
          onOptionsSelect={onOptionsSelect}
          formikProps={formik}
        />
      );
    case "phone_number":
    case "zt_contact_phone_number":
      return (
        <PhoneNumberInputComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
          formikProps={formik}
          country={country || ''}
        />
      );
    case "email":
    case "zt_contact_email":
      return (
        <InputComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
        />
      );
    case "disclaimer":
      return (
        <CheckboxComponent
          type={type}
          label={label}
          required={is_required}
          defaultValue={default_value}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
          formikProps={formik}
        />
      );
    default:
      return <></>;
  }
};

const Form = (props: FormProps): JSX.Element => {
  const {
    form,
    handleSubmit,
    submitText,
    preview,
    prefill,
    avatar,
    embedType,
    businessEmail,
    prefillPhoneNumber,
    prefillEmail,
    prefillCustomerName,
    prefillCompanyName,
    isCRMPrefill,
    organisationId,
    setVisibleStepIds,
    country
  } = props;

  const [showStepIds, setShowStepIds] = useState<{[key: string] : string}>({});

  const getInitValues = () => {
    const index = form && form.findIndex(item => item.type === 'disclaimer')
    if (index !== -1) {
      const item = form?.splice(index, 1);
      if (item && item?.length) form.push(item?.[0])
    }
    const initValues: { [key: string]: string } = {};
    form &&
      form.map((item) => {
        if (item?.type === 'zt_contact_name' && prefillCustomerName && !isCRMPrefill) {
          initValues[item?.id] = prefillCustomerName || prefill?.[item?.label] || '';
        } else if (item?.type === 'zt_customer_name' && prefillCompanyName && !isCRMPrefill) {
          initValues[item?.id] = prefillCompanyName || prefill?.[item?.label] || '';
        } else if ((item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number') && prefillPhoneNumber && !isCRMPrefill){          
          initValues[item?.id] = prefillPhoneNumber ? prefillPhoneNumber?.includes('+') ? prefillPhoneNumber : `+91${prefillPhoneNumber}` : '';
        } else if ((item?.type === 'zt_contact_email' || item?.type === 'email') && prefillEmail && !isCRMPrefill) {
          initValues[item?.id] = prefillEmail || prefill?.[item?.label] || '';
        } else if (item?.type === 'disclaimer') {
          initValues[item?.id] = prefill?.[item?.label] || item?.default_value || 'N';
        } else {
          initValues[item?.id] = prefill?.[item?.label] || "";
        }
      });
    return initValues;
  };

  const getTypes = () => {
    const typeObj: { [key: string]: { type: string; required: boolean; step_id: string } } = {};
    form &&
      form.map((item) => {
        typeObj[item?.id] = { type: item?.type, required: item?.is_required, step_id: item?.step_id || '' };
      });
    return typeObj;
  };

  const FreeMailProviders = ["gmail.com", "yahoo.com", "outlook.com", "protonmail.com", "rediffmail.com", "icloud.com", "aol.com"];

  const checkFreeEmail = (email: string) => {
    const domain = email.split("@")[1];

    const isFreemailProvider = FreeMailProviders.includes(domain.toLowerCase());
    if (isFreemailProvider) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitForm = async (
    values: { [key: string]: string | object },
    formikHelpers: FormikHelpers<{
      [key: string]: string;
    }>
  ) => {
    await handleSubmit(values);
    formikHelpers.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: getInitValues(),
    onSubmit: handleSubmitForm,
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      const types = getTypes();
      const stepIds = showStepIds && Object.values(showStepIds);

      Object.keys(values).map((item) => {
        if (!values?.[item] && types?.[item]?.required) {
          if (types?.[item]?.step_id && stepIds.includes(types?.[item]?.step_id)) {
            errors[item] = "Required";
          } else if (!types?.[item].step_id) {
            errors[item] = "Required";
          }
        }
        else if (
          values?.[item] && 
          (types?.[item]?.type === "email" ||
            types?.[item]?.type === "zt_contact_email") &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
            values?.[item].toString()
          )
        ) {
          errors[item] = "Invalid email";
        }
        else if (
          values?.[item] &&
          types?.[item]?.type === "zt_contact_email" &&
          businessEmail &&
          checkFreeEmail(values?.[item].toString())
        ) {
          errors[item] = "Please enter your business email";
        }
        else if (
          values?.[item] &&
          (types?.[item]?.type === "phone_number" ||
            types?.[item]?.type === "zt_contact_phone_number") &&
          values?.[item] &&
          values?.[item] !== "" &&
          !/^[+-\s\(\)0-9]{10,15}$/g.test(values?.[item])
          // !phone(values?.[item].toString()).isValid
        ) {
          errors[item] = "Invalid number";
        }
      });

      return errors;
    },
  });

  const cannotrender = (item: FormElement, preview: boolean) => {
    if (organisationId === 9 && (item?.type === "zt_contact_email" || item?.type === "email")) {
      return true;
    }
    if (!preview && item?.hidden) {
      return true;
    }
    let isFieldVisible = false;
    for (let index = 0; index < form?.length; index++) {
      if (form[index]?.type === 'radio_button' && showStepIds?.[form?.[index]?.id.toString()] === item?.step_id) {
        isFieldVisible = true;
        break;
      }
    }
    if (!preview && item?.is_step && !isFieldVisible) {
      return true;
    }
  }

  const updateStepFieldArr = (str: string, questionId: string) => {
    setShowStepIds({...showStepIds, [questionId]: str});
    setVisibleStepIds({...showStepIds, [questionId]: str})
  }

  useEffect(() => {
    const keys = Object.keys(formik?.errors);
    if (formik?.isSubmitting && keys?.length) {
      const firstElm = keys?.[0];
      let field = document.querySelector(`[data-name="${firstElm}"]`);
      if (field) field?.scrollIntoView({ behavior: "smooth"});
      else {
        field = document.querySelector(`[name="${firstElm}"]`);
        field?.scrollIntoView({ behavior: "smooth"});
      }
    }
  }, [formik?.isSubmitting]);

  useEffect(() => { 
    if (form && form?.length) {
      const filteredForm = [...form];
      for(let index = 0; index < filteredForm?.length; index++) {
        if (filteredForm[index]?.type === 'radio_button' && filteredForm[index].options && filteredForm[index]?.options?.length) {
          const length = (filteredForm[index].options && filteredForm[index]?.options?.length) || 0;
          for(let index1 = 0; index1 < length; index1++) {
            const option = filteredForm[index]?.options?.[index1];
            if (option && option?.step_question && option?.step_question?.step_id){
              const idx = form.findIndex(item => item?.step_id === option?.step_question?.step_id);
              if (idx >= 0 && idx !== index + 1) {
                const removedItem = form.splice(idx, 1)[0];
                form.splice(index, 0, removedItem);
              } 
            }
          }
        }
      }
    }
  }, [form]);



  return (
    <div className="flex flex-col flex-auto overflow-y-auto">
      <div className="flex gap-2 items-center mt-7 mb-4">
        <ProfileImg avatar={avatar || ''} small embedType={embedType || ''} />
        <div className={`w-auto font-bold text-xl leading-6 ml-3 md:ml-4`}>
          <span>Please help me with some details about you</span>
        </div>
      </div>
      <div className={`flex-auto overflow-y-auto pr-2`}>
        <form onSubmit={formik?.handleSubmit} style={{ marginBlockEnd: 0}}>
          {form.map((item) => (
            <>
              {cannotrender(item, preview || false) ? (
                <></>
              ) : (
                renderForm(item, preview, formik, updateStepFieldArr, country)
              )}
            </>
          ))}
        </form>
      </div>
      <>
        {!preview && (
          <div id="zt_submit_form_cnt" className="py-3 md:py-4">
            <button
              id="zt_submit_form_cta"
              type="submit"
              className={`disabled:bg-secondary-dark mx-auto cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none`}
              disabled={formik?.isSubmitting}
              onClick={() => {
                formik.handleSubmit()
              }}
            >
              {formik?.isSubmitting ? (
              <ThreeDotLoader />
            ) : (
              <>
                {submitText}
              </>
            )}
            </button>
          </div>
        )}
      </>
    </div>
  );
};

export default Form;
