import {
  endOfWeek,
  isBefore,
  isSameMonth,
  isSameWeek,
  startOfWeek,
} from "date-fns";
import DateGrid from "./date-grid";
import WeekSelector from "./week-selector";
import SlotsLoader from "../loader/slots-loader";

export type CalendarProps = {
  month: Date;
  currentWeek?: Date;
  dateSelectability: Record<string, boolean>;
  selectedDate?: Date;
  startDate?: Date;
  endDate?: Date;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  onMonthChange(month: Date): any;
  onDateSelected(date: Date): any;
  onWeekChange?(month: Date): any;
  scheduleLoading: boolean;
};

const Calendar = (props: CalendarProps): JSX.Element => {
  const {
    month,
    currentWeek,
    dateSelectability,
    selectedDate,
    startDate,
    endDate,
    weekStartsOn = 1,
    onMonthChange,
    onWeekChange,
    onDateSelected,
    scheduleLoading
  } = props;

  const styles = {
    wrapper: {
      base: "block relative w-full bg-white overflow-hidden",
    },
  };

  const weekChanged = (newWeek: Date) => {
    const component = document.getElementById("zipme-date-grid");
    if (isBefore(newWeek, currentWeek || new Date())) {
      component?.classList?.add("swipe-right");
    } else {
      component?.classList?.add("swipe-left");
    }
    if (!isSameMonth(endOfWeek(newWeek, { weekStartsOn }), month)) {
      onMonthChange(endOfWeek(newWeek, { weekStartsOn }));
    }
    onWeekChange && onWeekChange(newWeek);
  };

  return (
    <div className={`${styles.wrapper.base}`}>
      <WeekSelector
        weekStartDate={startOfWeek(currentWeek || new Date(), { weekStartsOn })}
        weekStartsOn={weekStartsOn}
        isFirstWeek={
          startDate &&
          isSameWeek(startDate, startOfWeek(currentWeek || new Date()))
        }
        isLastWeek={
          endDate && isSameWeek(endDate, startOfWeek(currentWeek || new Date()))
        }
        onWeekChange={weekChanged}
      />
      {scheduleLoading ? (
        <SlotsLoader text="Fetching schedules" />
      ) : (
        <DateGrid
          week={currentWeek || new Date()}
          selectedDate={selectedDate}
          dateSelectability={dateSelectability}
          weekStartsOn={weekStartsOn}
          onDateSelected={onDateSelected}
        />
      )}
    </div>
  );
};

export default Calendar;
