import { useContext, useEffect, useState } from 'react';
import { formatInTimeZone } from "date-fns-tz";
import { isEqual } from "date-fns";

import { Slot } from "../schedule-list";
import { ReactComponent as RightArrow } from "../../assets/icons/rightarrow.svg";
import { ReactComponent as RightArrowDisabled } from "../../assets/icons/rightarrow-disabled.svg";
import { ScheduledContext } from "../../apps/scheduled";
import { hexToRgb } from '../../utils/helper';
import { EMBED_TYPE_POPUP } from '../../utils/constants';
import { RescheduledContext } from '../../apps/reschedule-meeting';
import SlotsLoader from '../loader/slots-loader';

type SlotListProps = {
  title: string;
  slotLines: number;
  embedType?: string
  timeZone: string;
  slots: Slot[];
  selectedSlot?: Slot;
  currentPage: number;
  changecurrentPage: (page: number) => void;
  onSlotSelected(slot: Slot): any;
  slotsLoading: boolean;
};

const SlotList = (props: SlotListProps): JSX.Element => {
  const {
    slotLines,
    timeZone,
    slots,
    selectedSlot,
    currentPage,
    changecurrentPage,
    onSlotSelected,
    embedType = '',
    title,
    slotsLoading
  } = props;
  const slotsCopy = [...slots];
  const items = slotLines === 1 ? 3 : 6;
  const pages = Math.ceil(slots?.length / items);

  const [slotPage, setSlotPage] = useState<number>(currentPage);

  const slotsPerPage: Slot[][] = [];
  const calculateSlotPerPage = () => {
    const items = slotLines === 1 ? 3 : 6;
    for (let index = 0; index < pages; index++) {
      const slicedItems = slotsCopy.slice(0, items);
      slotsPerPage[index] = slicedItems;
      slotsCopy.splice(0, items);
    }
  };

  calculateSlotPerPage();

  const onClickPrevious = () => {
    if (slotPage <= 0) return;
    setSlotPage(slotPage - 1);
  };

  const onClickNext = () => {
    if (slotPage >= pages - 1) return;
    setSlotPage(slotPage + 1);
  };

  const context = useContext(ScheduledContext);
  const rescheduledContext = useContext(RescheduledContext);
  const contextThemeColor = rescheduledContext?.themeColor || context?.themeColor;
  const rgbColor = `${hexToRgb(contextThemeColor.includes('#') ? contextThemeColor : `#${contextThemeColor}`)}`;

  useEffect(() => {
    if (selectedSlot && Object.keys(selectedSlot)?.length && slotsPerPage && slotsPerPage?.length) {
      let idxPage = -1;
      for(let index = 0; index < slotsPerPage?.length; index ++){
        const page = slotsPerPage[index];
        for(let index1 = 0; index1 < page?.length; index1 ++) {
          if (page?.[index1]?.startTime?.toISOString() === selectedSlot?.startTime?.toISOString()) {
            idxPage = index;
            break;
          }
        }
      }
      setSlotPage(idxPage);
    }
  }, []);

  return (
    <>
      <div className="flex justify-between mb-3">
        <div className="text-base leading-5 font-bold text-primary-dark underline">
          {title}
        </div>
        <div className={`flex gap-2`}>
          <button
            type="button"
            className={`text-secondary disabled:pointer-events-none disabled:text-primary-dark outline-none rounded-full disabled:bg-transparent flex justify-center items-center ${
              !(slotPage <= 0) ? "transform rotate-180" : ""
            }`}
            disabled={slotPage <= 0}
            onClick={onClickPrevious}
          >
            {slotPage <= 0 ? (
              <RightArrowDisabled className="w-[18px] h-[18px]" />
            ) : (
              <RightArrow className="w-[18px] h-[18px]" />
            )}
          </button>
          <button
            type="button"
            className={`text-secondary disabled:pointer-events-none disabled:text-primary-dark outline-none rounded-full disabled:bg-transparent flex justify-center items-center ${
              slotPage >= pages - 1 ? "transform rotate-180" : ""
            }`}
            disabled={slotPage >= pages - 1}
            onClick={onClickNext}
          >
            {slotPage >= pages - 1 ? (
              <RightArrowDisabled className="w-[18px] h-[18px]" />
            ) : (
              <RightArrow className="w-[18px] h-[18px]" />
            )}
          </button>
        </div>
      </div>
      {!(slots && slots?.length) ? (
        <>
          {slotsLoading ? (
            <SlotsLoader />
          ) : (
            <div className="flex align-middle items-end text-sm">
              No slots available
            </div>
          )}
        </>
      ) : (
        <div className="flex w-full overflow-x-hidden max-w-[256rem]">
          {slotsPerPage ? (
            slotsPerPage.map((item, idx) => (
              <div
                key={idx}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, minmax(90px, 1fr))",
                  gridTemplateRows: `40px${slotLines === 1 ? "" : " 40px"}`,
                  gap: "12px",
                  textAlign: "center",
                  verticalAlign: "middle",
                  visibility: slotPage !== idx ? "hidden" : "visible",
                  width: "100%",
                  minWidth: "100%",
                  overflowY: "auto",
                  transform: `translateX(${slotPage ? "-" : ""}${
                    slotPage * 100
                  }%)`,
                  transition:
                    "transform 0.2s ease-in-out, visibility .5s ease-in-out",
                }}
              >
                {item &&
                  item.map((slot) => (
                    <button
                      id={slot?.startTime?.toISOString()}
                      key={slot?.startTime?.toISOString()}
                      type="button"
                      className={`flex items-center justify-center rounded-xl text-base leading-5 font-bold border border-secondary ${
                        selectedSlot &&
                        isEqual(slot?.startTime, selectedSlot?.startTime)
                          ? "bg-secondary text-white"
                          : "text-secondary"
                      }`}
                      style={
                        selectedSlot &&
                        isEqual(slot?.startTime, selectedSlot?.startTime)
                          ? {
                              background: `linear-gradient(97deg, rgba(${rgbColor}, 0.75), rgba(${rgbColor}, 1))`,
                              boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.25)",
                            }
                          : {}
                      }
                      onClick={() => {
                        onSlotSelected(slot)
                        changecurrentPage(slotPage);
                      }}
                    >
                      {formatInTimeZone(slot.startTime, timeZone, "hh:mma")}
                    </button>
                  ))}
              </div>
            ))
          ) : (
            <div className="text-sm">No Slots Available</div>
          )}
        </div>
      )}
    </>
  );
};

export default SlotList;
