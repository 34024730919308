import styles from './common.module.css';

const SlotsLoader = (props: { text?: string}): JSX.Element => {
	const { text = 'Fetching available slots' } = props
  return (
    <div className="flex align-middle items-end">
      <h1 className={styles["loader"]}>{text}</h1>
      <div className={styles["container"]}>
        <div className={styles["dot"]}></div>
        <div className={styles["dot"]}></div>
        <div className={styles["dot"]}></div>
      </div>
    </div>
  );
};

export default SlotsLoader
