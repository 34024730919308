import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { IFieldProps } from "../question-form/index";
import { EyeOff } from "react-feather";

const PhoneNumberInputComponent = (props: IFieldProps) => {
  const { label, required, error, value, onChange, disabled, hidden, formikProps, country, ...rest } = props;
  console.log(country);
  return (
    <div className="flex flex-col mb-3.5 md:mb-[18px]">
      <label className="text-sm font-bold text-primary-dark mb-1.5 md:mb-3">
        {`${label} ${required ? "*" : ""}`}
        {hidden && disabled ? <EyeOff className="inline w-4 h-4 ml-2"/> : []}
      </label>
      <PhoneInput
        enableSearch
        preferredCountries={['in','us','gb','sg','au','de']}
        country={country || 'in'}
        preserveOrder={['preferredCountries']}
        // placeholder={'sads'}
        enableAreaCodes
        inputClass={`${error ? "number-input-error" : ""} number-input`}
        inputProps={{ ...rest }}
        value={value ? value?.includes('+') ? value : `+91${value}` : ''}
        onChange={(v) => {
          formikProps?.setFieldValue(props?.name, `+${v}`, true)
        }}
        disabled={disabled}
      />
      <div className="text-red-500 text-xs mt-1 font-semibold">{error}</div>
    </div>
  );
};

export default PhoneNumberInputComponent;
