/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useSearchParams, useParams } from "react-router-dom";
import { CountdownCircleTimer, useCountdown } from "react-countdown-circle-timer";
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { customAlphabet } from 'nanoid';
import { addSeconds } from "date-fns";
import ct from 'countries-and-timezones';

import MeetErrorImg from "../../assets/images/meet-error.png";
import ThankYouImg from "../../assets/images/thankyou.gif";
import Loader from "../../components/loader";
import Form, { FormElement } from "../../components/question-form";
import Layout from "../../hoc/layout";
import EventService from "../../services/event.service";
import ScheduleService from "../../services/schedule.service";
import { EMBED_TYPE_POPUP } from "../../utils/constants";
import { hexToRgb, searchHas, searchRemove, sleep } from "../../utils/helper";
import { Tracking } from "../../utils/tracking";
import OfferGif from '../../assets/images/offer.gif';
import MeetingJSON from '../../assets/lottie-json/meeting.json';
import MicJSON from '../../assets/lottie-json/micro_phone.json';
import CameraJSON from '../../assets/lottie-json/video_call.json';
import Snackbar from "../../components/snackbar";
import useScript from "../../hooks/useScripts";
import VideoMeet from "../../components/video-meet";
import ProfileImg from "../../components/profile-img";
import EndCallMessage from "../../components/end-call-message";
import FormUpdated from "../../components/question-form-updated";
import ConversationService, { IMeetCustomerSignIn } from "../../services/conversation/conversation.service";
import AuthService from "../../services/auth.service";
import ZipteamsService from "../../services/zipteams.service";
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import MeetErrorUpdatedImg from "../../assets/images/meeterrorupdated.svg";
import { AppTracking } from "../../utils/appTracking";
import ThreeDotLoader from '../../components/loader/three-dots-loader';
import VideoMeetMS from "../../components/video-meet/ms-meet";
import { socket } from "../../contexts/socket.context";

type User = {id: number; firstName: string; lastName: string; avatarURL: string };

type SnackProps = {
  visible: boolean;
  label: string;
  type?: string;
  autoDismiss?: number | boolean;
};

const RealtimeApp = (): JSX.Element => {
  const location = useLocation();
  const { pathname, search, state } = location;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [embedType, setEmbedType] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eventId, setEventId] = useState<number>(0);
  const [businessEmail, setBusinessEmail] = useState<boolean>(false);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [fallbackEvent, setFallbackEvent] = useState<{ id: number, slug: string, status: string }>();
  const [questions, setQuestions] = useState<FormElement[]>([]);
  const [prefill, setPrefill] = useState<{ [key: string]: string }>();
  const [organisationName, setOrganisationName] = useState<string>("");
  const [userAllocationFailed, setUserAllocationFailed] = useState<boolean>(false);
  const [allottedUser, setAllottedUser] = useState<User>();
  const [avatarUrlBroken, setAvatarUrlBroken] = useState<boolean>(false);
  const [meetLink, setMeetLink] = useState<URL>();
  const [organisationId, setOrganisationId] = useState<number>();
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [themeColor, setThemeColor] = useState<string>('#31667f');
  const [submitButtonText, setSubmitButtonText] = useState<string>('');
  const [snackConfig, setSnackConfig] = useState<SnackProps>();
  const [isAvailabilityToggleSet, setIsAvailabilityToggleSet] = useState<boolean>(false);
  const [showMeet, setShowMeet] = useState<boolean>(false);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [introText, setIntroText] = useState<string>('');
  const [formFilled, setFormFilled] = useState<boolean>(false);
  const [meetDisplayName, setMeetDisplayName] = useState<string>('');
  const [meetJwt, setMeetJwt] = useState<string>('');
  const [meetRoomId, setMeetRoomId] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [showRealtimeSwitch, setShowRealtimeSwitch] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const [UTMFlags, setUTMFlags] = useState<Record<string, string>>({});
  const [prefillPhoneNumber, setPrefillPhoneNumber] = useState<string>('');
  const [prefillEmail, setPrefillEmail] = useState<string>('');
  const [workspaceId, setWorkspaceId] = useState<number>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [showFirst, setShowFirstPage] = useState<boolean>(true);
  const [noFallbackPage, setNoFallbackPage] = useState<boolean>(false);
  const [allocatedNode, setAllocatedNode] = useState<string>('');
  const [isNodeLoaded, setIsNodeLoaded] = useState<boolean>(false);
  const [prefillCustomerName, setPrefillCustomerName] = useState<string>('');
  const [prefillCompanyName, setPrefillCompanyName] = useState<string>('');
  const [isCRMPrefill, setIsCRMPrefill] = useState<boolean>(false);
  const [prefillType, setPrefillType] = useState<string>('');
  const [isMSRoomEnabled, setIsMSRoomEnabled] = useState<boolean>(false);
  const [roomCode, setRoomCode] = useState<string>('');
  const [hasUserJoined, setHasUserJoined] = useState<boolean>(false);
  const [isEventLoaded, setIsEventLoaded] = useState<boolean>(false);
  const [roomDetails, setRoomDetails] = useState<{[key:string]: any} | null>(null);
  // const [visibleStepIds, setVisibleStepIds] = useState<{[key: string] : string}>({});
  const [countryFromTz, setCountryFromTz] = useState<string>('in');
  const [guestId, setGuestId] = useState<string>('');
  const [wpSubscriptionTier, setWpSubscriptionTier] = useState<string>('');
  const [voiceCall, setVoiceCall] = useState<boolean>(false);

  const visibleStepIds = useRef<{[key: string] : string}>({});
  
  const referrer = (state as { [key: string]: string })?.referrer || searchParams.get('referrer');


  const domainURL = process.env.REACT_APP_MEET_DOMAIN_URL;
  const status = useScript(`https://${allocatedNode ? `${allocatedNode}.zipteams.com` : domainURL}/external_api.js`);

  const setVisibleStepIds = (param: {[key: string] : string}) => {
    visibleStepIds.current = param;
  }

  const isCustomerInputDone = (): boolean => {
    return !!(meetLink && allottedUser);
  }

  const isInputDone = () => {
    return Boolean(allottedUser && Object.keys(allottedUser)?.length && formFilled)
  }

  const getTypes = () => {
    const typeObj: { [key: string]: { type: string; required: boolean } } = {};
    const questionsArr = [...(questions || [])];
    for (let index = 0; index < questionsArr?.length; index++) {
      const item = questionsArr?.[index];
      typeObj[item?.id] = { type: item?.type, required: item?.is_required };
    }
    return typeObj;
  };

  const getDisplayName = (data: { [key: string]: string }) => {
    const types = getTypes();
    const answers = (data && Object.keys(data)) || [];
    const filteredObj =
      answers &&
      answers?.find((item) => types?.[item]?.type === "zt_contact_name");

    if (filteredObj) {
      return data?.[filteredObj];
    }
    return "";
  };

  const getRoomDetails = async (roomId: string) => {
    try {
      const response = await ZipteamsService.roomDetails(roomId);
      setRoomDetails(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  const meetCustomerSignIn = async (payload: IMeetCustomerSignIn) => {
    try {
      const response = await ConversationService.customerSignIn(payload);
      const jwt = response?.data?.data?.access_token || "";
      const roomCode = response?.data?.data?.room_code || "";
      setMeetJwt(jwt || "");
      setRoomCode(roomCode || '');
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenNewTabClick = () => {
    Tracking.newTabClicked();
    AppTracking.newTabClicked(workspaceId || 0, {
      event_id: eventId,
      is_realtime: true,
      url: window.location.href,
      timestamp: new Date().toJSON(),
      utm_params: {
        ...UTMFlags
      },
      source: "URL",
      userAvailable: Boolean((availableUsers && availableUsers?.length) || false),
      workspace_id: workspaceId
    });
  }

  const handleCopyToClipboard = () => {
    Tracking.meetingLinkCopied();
    AppTracking.meetingLinkCopied(workspaceId || 0, {
      event_id: eventId,
      is_realtime: true,
      url: window.location.href,
      timestamp: new Date().toJSON(),
      utm_params: {
        ...UTMFlags
      },
      source: 'URL',
      userAvailable: Boolean((availableUsers && availableUsers?.length) || false),
      workspace_id: workspaceId
    });
  }

  const CustomerInput = (): JSX.Element => {

    return (
      <div className={`p-8 pt-0 w-full md:w-[80%] ${embedType === EMBED_TYPE_POPUP ? 'max-h-[550px]' : 'max-h-screen'} md:max-h-[600px]`}>
        <div className={`text-center font-bold text-2xl sticky top-0 bg-white pt-8 pb-4`}>
          <span>Meet a {organisationName} expert right away</span>
        </div>
        <div className={`w-[70%] m-auto`}>
          <Form
            country={countryFromTz}
            form={questions}
            prefill={prefill}
            businessEmail={businessEmail}
            submitText={organisationId === 9 ? "Call Now" : submitButtonText || "Meet Now"}
            setVisibleStepIds={() => {}}
            handleSubmit={async (data: Record<string, string>) => {
              Tracking.eventRealtimeFormSubmit();
              try {
                const response = await ScheduleService.postRealtimeSchedule({
                  realtime_event_id: eventId,
                  answers: data,
                  source_url: window.location.href,
                  timezone: (window && window?.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) || 'Asia/Calcutta'
                });

                if (!response?.data) {
                  setUserAllocationFailed(true);
                  return;
                }

                const { 
                  user, 
                  room: { url }
                } = response.data;

                if (!(user || url)) {
                  setUserAllocationFailed(true);
                  return;
                }
                let meetUrl = new URL(url);
                const custNameQuestion = questions.find(q => q.type === "zt_contact_name");
                if (custNameQuestion) {
                  meetUrl.searchParams.append("name", data[custNameQuestion.id]);
                  if (embedType === EMBED_TYPE_POPUP ) meetUrl.searchParams.append("signin", 'auto');
                }

                setMeetLink(meetUrl);
                setAllottedUser({
                  id: user?.id,
                  firstName: user.first_name,
                  lastName: user.last_name,
                  avatarURL: user.avatar_url,
                });
              } catch(error) {
                console.log(error);
                setUserAllocationFailed(true);
              }
            }}
            organisationId={organisationId || 0}
          />
          {organisationId === 9 && (
            <div className="mt-6 flex gap-3 items-center">
              <img 
                alt="offer_img"
                src={OfferGif}
                className="w-10 h-10"
              />
              <p className="text-base leading-5 font-medium" style={{color: '#FC7C49'}}>Join the meeting now to get exciting offers on TV/Tabs and Hindi/CCS subjects</p>
            </div>
          )}
        </div>
        {fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active' &&
          <>
            <div className={`relative flex py-5 items-center`}>
              <div className={`flex-grow border-t border-gray-400`}></div>
              <span className={`flex-shrink mx-4 text-gray-400`}>OR</span>
              <div className={`flex-grow border-t border-gray-400`}></div>
            </div>
            <div className={`pb-6`}>
              <button
                className={`
                  underline w-full lg:w-auto flex justify-center 
                  text-secondary-dark items-center 
                  px-6 font-bold outline-none m-auto
                `}
                onClick={() => {
                  Tracking.eventRealtimeScheduleLaterClicked("form");
                  navigate(
                    `${fallbackEvent.slug}${
                      search && !search.includes("opentype")
                        ? `${search}&opentype=link`
                        : "?opentype=link"
                    }`,
                    {
                      replace: false,
                      state: {
                        ...(prefillType === 'anonymous' ? {} : { prefill }),
                        referrer: "realtime",
                        name: prefillCustomerName,
                        company: prefillCompanyName,
                        number: prefillPhoneNumber,
                        email: prefillEmail,
                        prefilltype: prefillType
                      },
                    }
                  );
                }}
              >
                Schedule a call for later
              </button>
            </div>
          </>
          }
      </div>
    );
  };

  const CustomerInputUpdated = (): JSX.Element => {

    if (showFirst) {
      return (
        <div
          className={`w-full flex flex-col items-center flex-auto p-3 pt-0 md:p-6 pb-0 md:pb-0 overflow-y-auto`}
        >
          <div className="my-auto md:mt-4 flex flex-col items-center">
            <div className="pt-5 md:pt-12">
              <ProfileImg
                avatar={
                  allottedUser?.avatarURL ||
                  ""
                }
              />
            </div>
            <div className="text-lg md:text-xl leading-6 text-center font-semibold w-[75%]">
              Hi, I'm {allottedUser?.firstName || ""}.{" "}
              {introText || "Can I show you around through a meet?"}
            </div>
            <div className="flex flex-col justify-center my-5">
              <button
                type="button"
                className="mb-3 cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3.5 flex items-center justify-center text-white disabled:bg-secondary-dark disabled:bg-opacity-60 disabled:pointer-events-none"
                // className="mb-3 py-3 px-6 bg-secondary rounded-lg flex justify-center items-center shadow-none border-none text-white text-base leading-[18px] font-semibold cursor-pointer w-[220px]"
                disabled={isFormSubmitting}
                onClick={async () => { 
                  AppTracking.realtimeJoinClicked(workspaceId || 0, {
                    event_id: eventId,
                    is_realtime: true,
                    url: window.location.href,
                    timestamp: new Date().toJSON(),
                    utm_params: {
                      ...UTMFlags
                    },
                    source: 'URL',
                    userAvailable: Boolean((availableUsers && availableUsers?.length) || false),
                    workspace_id: workspaceId
                  });
                  await handlePrefilledData(prefill || {}, questions, eventId, true);
                  setShowFirstPage(false);
                  
                }}
              >
                {isFormSubmitting ? (
                  <ThreeDotLoader />
                ) : "Sure, I'll join in" }
              </button>
              {fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active' && (
                <button
                  type="button"
                  className="bg-transparent border-none shadow-none underline text-secondary-dark text-base leading-3.5 font-bold flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    Tracking.eventRealtimeScheduleLaterClicked("form");
                    AppTracking.realtimeScheduleLaterClicked(workspaceId || 0, {
                      event_id: eventId,
                      is_realtime: true,
                      url: window.location.href,
                      timestamp: new Date().toJSON(),
                      utm_params: {
                        ...UTMFlags
                      },
                      source: 'URL',
                      userAvailable: Boolean((availableUsers && availableUsers?.length) || false),
                      workspace_id: workspaceId
                    })
                    navigate(
                      `${fallbackEvent.slug}${
                        search && !search.includes("opentype")
                          ? `${search}&opentype=link`
                          : "?opentype=link"
                      }`,
                      {
                        replace: false,
                        state: {
                          referrer: "realtime",
                          ...(prefillType === 'anonymous' ? {} : { prefill }),
                          ...(allottedUser && allottedUser?.id
                            ? { user_id: allottedUser?.id }
                            : {}),
                          name: prefillCustomerName,
                          company: prefillCompanyName,
                          number: prefillPhoneNumber,
                          email: prefillEmail,
                          prefilltype: prefillType
                        },
                      }
                    );
                  }}
                >
                  Schedule call for later
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={`w-full flex flex-col flex-auto p-3 pt-0 md:p-6 pb-0 md:pb-0 overflow-y-auto relative`}>
        <button 
          className="cursor-pointer bg-transparent p-1 flex justify-center items-center absolute -top-1 -left-1 md:top-0 md:left-0"
          onClick={() => setShowFirstPage(true)}
        >
          <BackArrow className="w-6 h-6 md:w-[30px] md:h-[30px]" />
        </button>
        <div className="flex items-center pt-7 md:pt-5 pb-6 md:pb-8 bg-white">
          <div>
            <ProfileImg small avatar={allottedUser?.avatarURL || ''}/>
          </div>
          <div className={`w-auto font-bold text-xl md:text-2xl leading-6 md:leading-7 ml-3 md:ml-4`}>
            <span>Please help me with some details about you</span>
          </div>
        </div>
        <div className={`flex-auto w-full m-auto flex flex-col overflow-y-auto`}>
          <FormUpdated
            country={countryFromTz}
            prefillPhoneNumber={prefillPhoneNumber}
            prefillEmail={prefillEmail}
            prefillCustomerName={prefillCustomerName}
            prefillCompanyName={prefillCompanyName}
            isCRMPrefill={isCRMPrefill}
            organisationId={organisationId || 0}
            form={questions}
            prefill={prefill}
            submitText={organisationId === 9 ? "Call Now" : submitButtonText || "Meet Now"}
            submitDisabled={isFormSubmitting}
            handleSubmit={async (data: Record<string, string>) => {
              await handleFormSubmit(data);
            }}
            businessEmail={businessEmail}
            setVisibleStepIds={setVisibleStepIds}
          />
          {organisationId === 9 && (
            <div className="mt-6 flex gap-3 items-center">
              <img alt="offer_img" src={OfferGif} className="w-10 h-10" />
              <p className="text-base leading-5 font-medium" style={{ color: "#FC7C49" }}>
                Join the meeting now to get exciting offers on TV/Tabs and Hindi/CCS subjects
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const WaitLoader = (): JSX.Element => {
    const [isCounterRunning, setIsCounterRunning] = useState<boolean>(false);
    useEffect(() => {
      setIsCounterRunning(true);
    }, [])
    const countdownDuration = 20;
    const { elapsedTime } = useCountdown({isPlaying: isCounterRunning, duration: countdownDuration, colors:"#81CBCA"})

    const ratio = Math.floor(elapsedTime) / countdownDuration;
    const oneByFive = ratio > 0 && ratio <= 0.2;
    const twoByFive = ratio > 0.2 && ratio <= 0.4;
    const threeByFive = ratio > 0.4 && ratio <= 0.6;
    const fourByFive = ratio > 0.6 && ratio <= 0.8;
    const fiveByFive = ratio > 0.8 && ratio <= 1;


    const getAnimationJSON = () => {
      if (twoByFive) return MeetingJSON;
      else if (threeByFive) return MicJSON;
      else if (fourByFive) return CameraJSON;
    }

    const getLoadingText = () => {
      if (oneByFive || fiveByFive) return `Connecting you to our ${organisationName} expert`;
      else if (twoByFive) return `You will be entering a meeting room in a few seconds`
      else if (threeByFive || fourByFive) return `Please enable microphone and camera on your browser`;
      else return `Connecting you to our ${organisationName} expert`;
    }

    const renderProfile = (): JSX.Element => {
      return (
        <div className={`h-[70%] w-[70%] flex justify-center`}>
          {oneByFive || fiveByFive ? (
            <img 
              crossOrigin="anonymous" 
              className={`
                h-full w-full rounded-full
                ${avatarUrlBroken ? `hidden` : ``}
              `} 
              src={allottedUser?.avatarURL || ''}
              alt="avatar"
              onError={() => { setAvatarUrlBroken(true); }}
            />
          ) : (
            <Lottie
              lottieRef={lottieRef}
              animationData={getAnimationJSON()}
              autoPlay
              loop
              className="h-full w-full rounded-full"
            />
          )}
        </div>
      );
    };

    return (
      <div className={`w-[85%] md:w-[60%] md:max-h-[600px] flex-1 py-8 flex flex-col`}>
        <div className={`flex flex-col text-center font-bold text-2xl leading-6`}>
          <span>{getLoadingText()}</span>
          {(oneByFive || fiveByFive) ? (
            <span className={`mt-[20px] text-emerald-600`}>{allottedUser?.firstName || ''} {allottedUser?.lastName || ''}</span>
          ) : (<span className={`mt-[20px]`}></span>)}
        </div>
        <div className={`my-auto flex justify-center`}>
          <CountdownCircleTimer
            isPlaying={isCounterRunning}
            duration={countdownDuration}
            colors={`#${themeColor.replace('#', '')}`}
            size={300}
            strokeWidth={20}
            trailColor={`#${themeColor.replace('#', '')}33`}
            onComplete={() => {
              if (meetLink) {
                if (embedType === EMBED_TYPE_POPUP) {
                  window.parent.postMessage({ type: "changeSrc", src: `${meetLink?.href}&embed_type=popup`}, "*");
                }
                else {
                  window.location.href = `${meetLink?.href}&embed_type=popup`;
                }
              }
            }}
          >
            {renderProfile}
          </CountdownCircleTimer>
        </div>
      </div>
    );

    // return (
    //   <div
    //     className={`w-[80%] md:w-[60%] md:max-h-[600px] ${
    //       oneThird ? "h-full" : ""
    //     }`}
    //   >
    //     {!oneThird && (
    //       <div className={`flex flex-col text-center mb-12 font-bold text-2xl`}>
    //         <span>Connecting you to our {organisationName} expert</span>
    //         <span className={`mt-[20px] text-emerald-600`}>
    //           {allottedUser?.firstName} {allottedUser?.lastName}
    //         </span>
    //       </div>
    //     )}
    //     <div
    //       className={`flex flex-col ${oneThird ? "py-8 justify-center" : ""}`}
    //     >
    //       <div
    //         className={`h-full flex justify-center items-center ${
    //           oneThird ? "mb-14 gap-2" : ""
    //         }`}
    //       >
    //         <CountdownCircleTimer
    //           isPlaying
    //           duration={countdownDuration}
    //           colors="#81CBCA"
    //           size={oneThird ? 60 : 220}
    //           strokeWidth={oneThird ? 6 : 20}
    //           trailColor="#24B0B040"
    //           onComplete={() => {
    //             if (meetLink)
    //               window.location.href = `${meetLink?.href}&embed_type=popup`;
    //           }}
    //         >
    //           {renderProfile}
    //         </CountdownCircleTimer>
    //         {oneThird && (
    //           <div className="font-bold text-xl md:text-2xl leading-6 md:leading-7 text-center">
    //             {twoThird
    //               ? "Please check your video and audio connections before joining the meet"
    //               : "You will be entering a meeting room in a while"}
    //           </div>
    //         )}
    //       </div>
    //       {oneThird && (
    //         <div className="flex-1 h-full w-full flex items-center justify-center my-auto relative">
    //           {twoThird ? (
    //             <>
    //               <img
    //                 src={allottedUser?.avatarURL}
    //                 alt="avatar"
    //                 className="w-full h-[300px] sm:h-[340px] rounded-lg backdrop-blur-sm"
    //               />
    //               <div className="absolute bottom-0 flex gap-6">
    //                 <img
    //                   src={VideoGif}
    //                   className="w-[60px] h-[60px]"
    //                   alt="video-gif"
    //                 />
    //                 <img
    //                   src={MicGif}
    //                   className="w-[60px] h-[60px]"
    //                   alt="mic-gif"
    //                 />
    //               </div>
    //             </>
    //           ) : (
    //             <img
    //               src={WaitingGif}
    //               className="w-[300px] h-[300px]"
    //               alt="waiting-time"
    //             />
    //           )}
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // )
  };

  const WaitLoaderUpdated = ({userJoined} : {userJoined: boolean}): JSX.Element => {
    const [isCounterRunning, setIsCounterRunning] = useState<boolean>(false);
    useEffect(() => {
      setIsCounterRunning(true);
    }, []);
    useEffect(() => {
      if (userJoined) {
        handleTimerComplete();
        setIsCounterRunning(false);
      }
    }, [userJoined]);
    const countdownDuration = 20;
    const { elapsedTime } = useCountdown({
      isPlaying: isCounterRunning,
      duration: countdownDuration,
      colors: "#81CBCA",
    });

    const ratio = Math.floor(elapsedTime) / countdownDuration;
    const oneByFive = ratio >= 0 && ratio <= 0.2;
    const twoByFive = ratio > 0.2 && ratio <= 0.4;
    const threeByFive = ratio > 0.4 && ratio <= 0.6;
    const fourByFive = ratio > 0.6 && ratio <= 0.8;
    const fiveByFive = ratio > 0.8 && ratio <= 1;

    const getAnimationJSON = () => {
      if (twoByFive) return MeetingJSON;
      else if (threeByFive) return MicJSON;
      else if (fourByFive) return CameraJSON;
    };

    const getLoadingText = () => {
      if (oneByFive || fiveByFive)
        return `Connecting you to our ${organisationName} expert`;
      else if (twoByFive)
        return `You will be entering a meeting room in a few seconds`;
      else if (threeByFive || fourByFive)
        return `Please enable microphone and camera on your browser`;
      else return `Connecting you to our ${organisationName} expert`;
    };

    const timerComplete = async () => {
      setShowMeet(true);
      setPrefill(undefined);
      if (!isMSRoomEnabled) {
        await fetchAllocatedJitsiNode(meetRoomId, workspaceId!)
      }
    } 

    const handleTimerComplete = () => {
      timerComplete()
    }

    const renderProfile = (): JSX.Element => {
      return (
        <div className={`h-[75%] w-[75%] flex justify-center`}>
          {oneByFive || fiveByFive ? (
            <img
              crossOrigin="anonymous"
              className={`
                h-full w-full rounded-full
                ${avatarUrlBroken ? `hidden` : ``}
              `}
              src={
                allottedUser?.avatarURL ||
                ""
              }
              alt="avatar"
              onError={() => {
                setAvatarUrlBroken(true);
              }}
            />
          ) : (
            <Lottie
              lottieRef={lottieRef}
              animationData={getAnimationJSON()}
              autoPlay
              loop
              className="h-full w-full rounded-full"
            />
          )}
        </div>
      );
    };

    return (
      <div className={`p-4 md:p-8 flex-1 flex flex-col`}>
        <div
          className={`flex flex-col text-center font-bold text-lg md:text-2xl leading-5 md:leading-6`}
        >
          <span>{getLoadingText()}</span>
          {oneByFive || fiveByFive ? (
            <span className={`mt-4 md:mt-5 text-emerald-600`}>
              {allottedUser?.firstName || ""}{" "}
              {allottedUser?.lastName || ""}
            </span>
          ) : (
            <span className={`mt-4 md:mt-5`}></span>
          )}
        </div>
        <div className={`my-auto flex justify-center`}>
          <CountdownCircleTimer
            isPlaying={isCounterRunning}
            duration={countdownDuration}
            colors={`#${themeColor.replace("#", "")}`}
            size={window.innerWidth < 768 ? 230 : 300}
            strokeWidth={window.innerWidth < 768 ? 13 : 16}
            trailColor={`#${themeColor.replace("#", "")}33`}
            onComplete={handleTimerComplete}
            // onComplete={() => {
            //   if (meetLink) {
            //     if (embedType === EMBED_TYPE_POPUP) {
            //       window.parent.postMessage({ type: "changeSrc", src: `${meetLink?.href}&embed_type=popup`}, "*");
            //     }
            //     else {
            //       window.location.href = `${meetLink?.href}&embed_type=popup`;
            //     }
            //   }
            // }}
          >
            {renderProfile}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  };

  const AllocationFailedFallback = (): JSX.Element => {
    Tracking.eventRealtimeAllocationFailed();
    return (
      <div className={`md:w-[80%] p-8 md:max-h-[600px]`}>
        <div className={`flex flex-col text-center mb-12 font-bold text-2xl`}>
          <span>Thank you for reaching out to us.</span>
          <div className={`my-12`}>
            <img 
              className={`m-auto w-36 h-36`}
              src={ThankYouImg}
              alt="thankyou"
            />
          </div>
          <div>We have noted your details, and you should hear from our {organisationName} expert very soon</div>
          {/* <span>Please try again in some time.</span> */}
        </div>
        <div>
          {fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active' && 
            <button
              className={`
                w-full lg:w-auto flex justify-center items-center 
              text-white bg-secondary rounded-3xl px-6 py-3 font-bold 
              disabled:bg-secondary-dark outline-none m-auto
              `}
              onClick={() => {
                Tracking.eventRealtimeScheduleLaterClicked("allocation_failed");
                navigate(
                  `${fallbackEvent.slug}${
                    search && !search.includes("opentype")
                      ? `${search}&opentype=link`
                      : "?opentype=link"
                  }`,
                  {
                    replace: false,
                    state: {
                      referrer: "realtime",
                      ...(prefillType === 'anonymous' ? {} : { prefill }),
                      name: prefillCustomerName,
                      company: prefillCompanyName,
                      number: prefillPhoneNumber,
                      email: prefillEmail,
                      prefilltype: prefillType,
                    },
                  }
                );
              }}
            >
              Schedule a call for later
            </button>
          }
        </div>
      </div>
    );
  };

  const AllocationFailedFallbackUpdated = (): JSX.Element => {
    Tracking.eventRealtimeAllocationFailed();
    return (
      <>
        {fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active' ? (
          <div
            className={`w-full flex-auto p-3 md:p-8 text-center flex flex-col`}
          >
            <div className="mt-10 md:my-auto">
              <div
                className={`flex flex-col text-center font-bold text-xl md:text-2xl my-auto`}
              >
                <span>Thank you for reaching out to us.</span>
                <div className={`my-6 md:my-12`}>
                  <img
                    className={`m-auto w-32 h-32 md:w-40 md:h-40`}
                    src={ThankYouImg}
                    alt="thankyou"
                  />
                </div>
                <div className="text-base md:text-lg">
                  We have noted your details, and you should hear from our{" "}
                  {organisationName} expert very soon
                </div>
              </div>
              <div>
                {fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active' && (
                  <button
                    className={`disabled:bg-secondary-dark my-4 mx-auto cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none`}
                    onClick={() => {
                      Tracking.eventRealtimeScheduleLaterClicked(
                        "allocation_failed"
                      );
                      navigate(
                        `${fallbackEvent.slug}${
                          search && !search.includes("opentype")
                            ? `${search}&opentype=link`
                            : "?opentype=link"
                        }`,
                        {
                          replace: false,
                          state: {
                            referrer: "realtime",
                            ...(prefillType === 'anonymous' ? {} : { prefill }),
                            name: prefillCustomerName,
                            company: prefillCompanyName,
                            number: prefillPhoneNumber,
                            email: prefillEmail,
                            prefilltype: prefillType,
                          },
                        }
                      );
                    }}
                  >
                    Schedule a call for later
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`w-full flex-auto p-3 md:p-8 text-center flex flex-col`}
          >
            <div className="mt-4 sm:mt-12">
              <img
                className={`m-auto`}
                src={MeetErrorUpdatedImg}
                alt="meet-error"
              />
            </div>
            <div className="mt-3 w-full flex-auto">
              <div className="font-bold text-xl leading-6 text-center mx-auto w-[90%]">
                Sorry! We are not able to schedule your meeting right now.{" "}
                <button
                  className="bg-transparent"
                  onClick={async () => {
                    await loadEventData();
                    setShowThankYou(false);
                    setFormFilled(false);
                  }}
                >
                  <span className="font-bold text-[#31667f] hover:underline">
                    Click here
                  </span>
                </button>{" "}
                to try again.
              </div>
            </div>
            <div className="text-secondary-dark text-sm leading-4 text-center mt-6">
              Please get in touch with the host in case this issue persists.
            </div>
          </div>
        )}
      </>
    );
  };

  const NoFallBackPage = (): JSX.Element => {
    return (
      <div
        className={`w-full flex-auto p-3 md:p-8 text-center flex flex-col`}
      >
        <div className="mt-4 sm:mt-12">
          <img
            className={`m-auto`}
            src={MeetErrorUpdatedImg}
            alt="meet-error"
          />
        </div>
        <div className="mt-3 w-full flex-auto">
          <div className="font-bold text-xl leading-6 text-center mx-auto w-[90%]">
            We are sorry! No one is available to take a meeting now.
            <br/><br/>Please try after some time.
          </div>
        </div>
      </div>
    );
  }

  const ThankYouPage = () => {
    return (
      <div className="flex-auto w-full flex flex-col items-center justify-center pt-5 md:pt-12">
        <ProfileImg
          avatar={
            allottedUser?.avatarURL ||
            ""
          }
        />
        {showThankYou && (
          <EndCallMessage
            onScheduleClick={async () => {
                await loadEventData();
              setShowThankYou(false);
              setFormFilled(false);
            }}
          />
        )}
      </div>
    );
  }; 

  const getApplicableScreenUpdated = (): JSX.Element => {
    if (availableUsers.length) {
      if (userAllocationFailed) {
        return <AllocationFailedFallbackUpdated />;
      }
      if (showThankYou) {
        return <ThankYouPage />
      }
      if (showMeet) {
        return (
          <>
            {isMSRoomEnabled ? (
              <VideoMeetMS 
                isAudioMuted
                isVideoMuted
                userName={meetDisplayName}
                roomCode={roomCode}
              />
            ) : (
              <>
                {isNodeLoaded ? (
                  <VideoMeet
                    jwt={meetJwt}
                    roomId={meetRoomId}
                    displayName={meetDisplayName}
                    roomUrl={meetLink?.href || ''}
                    handleMeetLoad={() => {}}
                    handleCallEnd={() => {
                      setShowMeet(false);
                      setShowThankYou(true);
                    }}
                    isRecordingEnabled={roomDetails?.organisation?.is_recording_enabled !== false}
                    videoDomain={
                      allocatedNode
                        ? `${allocatedNode}.zipteams.com`
                        : process.env.REACT_APP_MEET_DOMAIN_URL!
                    }
                    handleCopyToClipboard={handleCopyToClipboard}
                    handleOpenNewTabClick={handleOpenNewTabClick}
                    voiceCall={voiceCall}
                    allocatedUserName={`${allottedUser?.firstName} ${allottedUser?.lastName || ''}`.trim()}
                    allocatedUserAvatar={allottedUser?.avatarURL || ''}
                  />
                ) : []}
              </>
            )}
          </>
        )
      }
      if (formFilled) {
        return <WaitLoaderUpdated userJoined={hasUserJoined} />
      }
      if (!isInputDone()) {
        return <CustomerInputUpdated />;
      }
    }
    if (noFallbackPage) {
      return <NoFallBackPage />
    }
    return <AllocationFailedFallbackUpdated />;
  };

  const getApplicableScreen = (): JSX.Element => {
    if (availableUsers.length) {
      if (userAllocationFailed) {
        return <AllocationFailedFallback />;
      }
      if (!isCustomerInputDone()) {
        return <CustomerInput />;
      }
      return <WaitLoader />
    }
    return <AllocationFailedFallback />;
  };
  
  const App = (): JSX.Element => {

    return (
      <div className={`md:mt-8 lg:mt-16 md:mb-7 flex-auto flex justify-center md:max-h-[600px]`}>
        {isLoading ? 
          <Loader />
        :
          <div className={`
            w-full md:border md:border-primary-dark items-center
            md:border-opacity-10 md:shadow-md md:rounded-lg bg-white 
            flex-auto flex flex-col lg:max-w-3xl md:max-w-[80%] overflow-y-auto md:max-h-[600px]
          `}>
            {eventId ? 
              getApplicableScreen()
            :
              <div className={`md:w-[80%] p-8 md:max-h-[600px] text-center`}>
                <img className={`mb-8 mx-auto`} src={MeetErrorImg} alt="error_img"/>
                <span className={`text-2xl font-bold mb-2 block`}>Oops! It seems this link is not available.</span>
                <span className={`text-secondary-dark font-bold`}>It looks like this scheduling link is incorrect or made inactive by the host.</span>
              </div>
            }
          </div>
        }
      </div>
    );
  };

  const UpdatedApp = (): JSX.Element => {
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            className={`p-3.5 md:p-5 flex-1 flex flex-col ${isAuthenticated && embedType !== EMBED_TYPE_POPUP ? '' : 'justify-center'} items-center md:max-h-full bg-secondary bg-opacity-10`}
          >
            {/* {isAuthenticated && embedType !== EMBED_TYPE_POPUP &&(
              <>
                <div className="w-full bg-transparent flex items-center gap-1 md:gap-2 mb-5 lg:mb-0">
                  <img
                    src={LogoImg}
                    alt="logo-img"
                    className="w-6 h-6 md:w-10 md:h-10 "
                  />
                  <div className="text-secondary text-xl leading-6 md:text-[30px] md:leading-[38px] font-fjalla mt-0.5">
                    ZIPTEAMS
                  </div>
                </div>
                <div className="text-xl leading-6 md:text-[30px] md:leading-9 text-primary-dark mb-1 md:mb-2 font-bold">
                  {organisationName} Instant Meeting URL
                </div>
                <div className="mb-[18px] md:mb-9 flex gap-3.5">
                  <div className="text-secondary font-semibold text-base leading-5 md:text-2xl md:leading-[34px]">
                    {window.location.origin.replace(/^http(s?):\/\//i, "")}
                    {window.location.pathname}
                  </div>
                  <button
                    type="button"
                    className="bg-transparent cursor-pointer rounded-full p-1"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}${window.location.pathname}`
                      );
                      setSnackConfig({
                        visible: true,
                        autoDismiss: 2000,
                        type: "success",
                        label: "Copied to Clipboard",
                      });
                    }}
                  >
                    <Copy className={`w-4 h-4 md:w-6 md:h-6 text-secondary-dark`} />
                  </button>
                </div>
              </>
            )} */}
            <div
              style={{
                boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.08)",
              }}
              className={`
                relative w-full h-[80%] items-center flex-auto flex flex-col my-auto border border-primary-gray rounded-[18px] md:rounded-3xl bg-white 
                md:max-w-[520px] ${embedType === EMBED_TYPE_POPUP ? '' : 'max-h-[450px]'} md:max-h-[550px] p-3 overflow-y-auto
              `}
            >
              {eventId ? (
                <>
                  {getApplicableScreenUpdated()}
                  {(!isLoading && showThankYou && wpSubscriptionTier !== 'enterprise_tier') && <div className="absolute bottom-0 right-0 text-xs p-2 px-4 leading-4 flex items-center gap-[3px]">Powered by <a className="font-fjalla text-[#31667F] mt-[1px]" href="https://zipteams.com/" target="_blank" rel="noreferrer">Zipteams</a></div>}
                </>
              ) : (
                <div className={`w-full flex-auto p-3 md:p-8 text-center`}>
                  <img
                    className={`mb-8 mx-auto`}
                    src={MeetErrorImg}
                    alt="error_img"
                  />
                  <span className={`text-xl md:text-2xl font-bold mb-2 block`}>
                    Oops! It seems this link is not available.
                  </span>
                  <span
                    className={`text-secondary-dark text-sm md:text-base font-bold`}
                  >
                    It looks like this link is incorrect or made inactive by the
                    host.
                  </span>
                </div>
              )}
            </div>
            {/* {isAuthenticated && embedType !== EMBED_TYPE_POPUP && (
              <div className="w-full flex flex-col md:flex-row md:justify-between mt-auto">
                <div className="hidden lg:block bg-transparent w-[105px]" />
                {showRealtimeSwitch && (
                  <div className="flex items-center gap-2">
                    <ToggleComponent
                      checked={isAvailabilityToggleSet}
                      onChange={async (val) => {
                        setIsAvailabilityToggleSet(val);
                        if (userId) {
                          await ZipteamsService.updateUser(userId, {
                            meta: { realtime_meeting_availaibility: val },
                          })
                        }
                      }}
                      label=""
                    />
                    <div className="font-normal text-sm leading-4 md:text-[18px] md:leading-5">
                      You are {isAvailabilityToggleSet ? "online" : "offline"} for
                      instant meetings
                    </div>
                  </div>
                )}
                <div className="self-end font-bold">
                  <button
                    type="button"
                    className="bg-transparent cursor-pointer rounded-full p-1 flex items-center gap-2"
                    onClick={() => {
                      navigate("/manage/realtime-events/configure");
                    }}
                  >
                    <Settings className="w-4 h-4 md:w-6 md:h-6 text-primary-dark" />
                    <span className="text-sm leading-4 md:text-[18px] md:leading-5">
                      Settings
                    </span>
                  </button>
                </div>
              </div>
            )} */}
          </div>
        )}
      </>
    );
  };

  const fetchContactInfo = async (orgId: number, phonenumber: string, email: string) => {
    try {
      if (!orgId) return [];
      const params = {
        ...(phonenumber ? { phone_number: phonenumber } : {}),
        ...(email ? { email } : {}),
      }
      const contactInfo = await ZipteamsService.getContactInfo(orgId, params);
      return contactInfo?.data?.data;
    } catch (err) {
      console.log(err);
      return []; 
    }
  }

  const fetchAllocatedJitsiNode = async (room_id: string, workspace_id: number) => {
    try {
      const response = await ZipteamsService.getAllocatedNode(
        room_id,
        workspace_id,
      );
      const { data }: any = response?.data;
      setAllocatedNode(data?.data?.allocated_node);
      setIsNodeLoaded(true);
    } catch (err) {
      console.log(err);
      setIsNodeLoaded(true);
    }
  };

  const getUTMObject = () => {
    const utm_term = searchParams.get('utm_term') || (state as { [key: string]: string })?.utm_term;
    const utm_source = searchParams.get('utm_source') || (state as { [key: string]: string })?.utm_source;
    const utm_content = searchParams.get('utm_content') || (state as { [key: string]: string })?.utm_content;
    const utm_campaign = searchParams.get('utm_campaign') || (state as { [key: string]: string })?.utm_campaign;
    const utm_medium =  searchParams.get('utm_medium') || (state as { [key: string]: string })?.utm_medium;

    const utm_flags = {
      ...(utm_term ? { utm_term } : {}),
      ...(utm_source ? { utm_source } : {}),
      ...(utm_content ? { utm_content } : {}),
      ...(utm_campaign ? { utm_campaign } : {}),
      ...(utm_medium ? { utm_medium } : {}),
    }

    return utm_flags;
  }

  const getPrefillData = () => {
    const prefillData = searchParams.get("prefill") || "";
    if (prefillData) {
      const parsedData: { [key: string]: string } =
        typeof prefillData === "string" ? JSON.parse(prefillData) : prefillData;
      return parsedData
    }
    return '';
  }

  const handleFormSubmit = async (data: Record<string, string>) => {
    Tracking.eventRealtimeFormSubmit();
    AppTracking.realtimeFormSubmit(workspaceId || 0, {
      event_id: eventId,
      is_realtime: true,
      url: window.location.href,
      timestamp: new Date().toJSON(),
      utm_params: {
        ...UTMFlags
      },
      source: 'URL',
      userAvailable: Boolean((availableUsers && availableUsers?.length) || false),
      workspace_id: workspaceId
    })
    const form = questions || [];
    const prefillData: {[key:string]: string } = {};
    form && form.map((item) => {
      prefillData[item.label] = data?.[item?.id];
    });
    setPrefill({...prefill,...prefillData});
    setIsFormSubmitting(true);
    try {
      const displayName = getDisplayName(data);
      setMeetDisplayName(displayName || "");
      const response = await ScheduleService.postRealtimeSchedule({
        realtime_event_id: eventId,
        answers: data,
        source_url: window.location.href,
        ...(UTMFlags && Object.keys(UTMFlags)?.length ? {utm_flags: {...UTMFlags}} : {}),
        guest_id: guestId || '',
        timezone: (window && window?.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) || 'Asia/Calcutta'
      });

      const { 
        user, 
        room = {},
        response: resp
      } = response.data;

      if (!response?.data) {
        if (fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active') {
          return navigate(
            `${fallbackEvent.slug}${
              search && !search.includes("opentype")
                ? `${search}&opentype=link`
                : "?opentype=link"
            }`,
            {
              replace: false,
              state: {
                ...(resp?.code !== 'INSTANT_MEETING_NOT_ALLOWED' ? {referrer: "forced"} : {}),
                ...(prefillType === 'anonymous' ? {} : { prefill: prefillData }),
                ...(allottedUser && allottedUser?.id
                  ? { user_id: allottedUser?.id }
                  : {}),
                name: prefillCustomerName,
                company: prefillCompanyName,
                number: prefillPhoneNumber,
                email: prefillEmail,
                prefilltype: prefillType,
              },
            }
          );
        } else {
          setUserAllocationFailed(true);
        }
        return;
      }

      if (!(user || room?.url)) {
        if (fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active') {
          return navigate(
            `${fallbackEvent.slug}${
              search && !search.includes("opentype")
                ? `${search}&opentype=link`
                : "?opentype=link"
            }`,
            {
              replace: false,
              state: {
                ...(resp?.code !== 'INSTANT_MEETING_NOT_ALLOWED' ? {referrer: "forced"} : {}),
                ...(prefillType === 'anonymous' ? {} : { prefill: prefillData }),
                ...(allottedUser && allottedUser?.id
                  ? { user_id: allottedUser?.id }
                  : {}),
                name: prefillCustomerName,
                company: prefillCompanyName,
                number: prefillPhoneNumber,
                email: prefillEmail,
                prefilltype: prefillType
              },
            }
          );
        } else {
          setUserAllocationFailed(true);
        }
        return;
      }
      let meetUrl = new URL(room?.url);
      const meetRoomId = meetUrl?.pathname?.replace("/", "") || "";
      setMeetRoomId(meetRoomId);
      setFormFilled(true);
      setMeetLink(meetUrl);
      setAllottedUser({
        id: user?.id,
        firstName: user.first_name,
        lastName: user.last_name,
        avatarURL: user.avatar_url,
      });

      if (!response || !Object.keys(response)?.length) return;

      const payload = {
        room_id: meetRoomId || "",
        meet_passcode: room?.passcode || "",
        participant_name: displayName,
      };
      await meetCustomerSignIn(payload);
      await getRoomDetails(meetRoomId);
      // await fetchAllocatedJitsiNode(meetRoomId, workspaceId!)
      setIsFormSubmitting(false);
    } catch (error) {
      console.log(error);
      if (fallbackEvent && fallbackEvent?.id && fallbackEvent?.status === 'active') {
        return navigate(
          `${fallbackEvent.slug}${
            search && !search.includes("opentype")
              ? `${search}&opentype=link`
              : "?opentype=link"
          }`,
          {
            replace: false,
            state: {
              referrer: "forced",
              ...(prefillType === 'anonymous' ? {} : { prefill: prefillData }),
              ...(allottedUser && allottedUser?.id
                ? { user_id: allottedUser?.id }
                : {}),
              name: prefillCustomerName,
              company: prefillCompanyName,
              number: prefillPhoneNumber,
              email: prefillEmail,
              prefilltype: prefillType
            },
          }
        );
      } else {
        setUserAllocationFailed(true);
      }
      setIsFormSubmitting(false);
    }
  };

  const handlePrefilledData = async (prefillData: {[key: string]: string}, formQuestions: FormElement[], eventId: number, shouldPrefill: boolean) => {
    const parsedData  = prefillData || getPrefillData();
    const visibleStepIdsArr = visibleStepIds.current && Object.values(visibleStepIds.current);
    const found = formQuestions && formQuestions?.filter((question: any) => !Object.keys(parsedData)?.find(key => {
      if (key === question?.label) { 
        if (question?.is_required) {
          if (question?.step_id && !visibleStepIdsArr.includes(question?.step_id)) {
            return true;
          }
          if (parsedData[key]) {
            return true;  
          } 
          return false;
        }
        return true;
      }
      return false;
    }));
    const equalLengths = (formQuestions && formQuestions?.length) === (parsedData && Object.keys(parsedData)?.length) 
    if (equalLengths && (found && !found?.length) && shouldPrefill){      
      const payload: any = {};
      formQuestions?.map((question: any) => {
        const label = question?.label || '';
        payload[question?.id] = parsedData && parsedData[label]
      });
      await handleFormSubmit(payload);
    }
  }

  const loadEventData = async () => {
    setIsLoading(true);

    let slug = `/${params.org}`;
    if (params.slug && !(params.slug === 'instant' || params.slug === 'realtime')) {
      slug = `${slug}/${params.slug}`
    }
    try {
      const response = await EventService.getRealtimeEvent(slug, encodeURIComponent(window.location.href));
      const { organisation, event, available_users, questions, meta } = response.data;

      if (!available_users?.length) {
        const schEvent = meta && meta?.length && meta.find((itm: any) => !itm?.is_realtime);

        if (schEvent?.id && schEvent?.slug && schEvent?.status === 'active') {
          return navigate(
            `${schEvent?.slug}${
              search && !search.includes("opentype")
                ? `${search}&opentype=link`
                : "?opentype=link"
            }`,
            {
              replace: false,
              state: {
                prefill: prefill,
                name: prefillCustomerName,
                company: prefillCompanyName,
                number: prefillPhoneNumber,
                email: prefillEmail,
                prefilltype: prefillType,
              },
            }
          );
        } else {
          setNoFallbackPage(true);
        }
      }

      let prefillObjectTop :any = getPrefillData();
      if (prefillObjectTop && Object.keys(prefillObjectTop)?.length) {
        const prefillObject:any = {}
        for (let index = 0; index < questions?.length; index ++) {
          const item = questions?.[index];
          prefillObject[item?.label] = prefillObjectTop?.[item?.label] || ''
        }
        setPrefill({...prefillObjectTop, ...prefillObject});
        prefillObjectTop = {...prefillObjectTop, ...prefillObject};
      }

      let phonenumber = searchParams.get("number") || (state as { [key: string]: string })?.number;
      if (phonenumber && phonenumber.includes(' ') && phonenumber.indexOf(' ') === 0) {
        phonenumber = phonenumber.replace(' ', '+');
      }
      if (phonenumber && phonenumber.includes(' ')) {
        phonenumber = phonenumber.replaceAll(' ', '');
      }
      if (!phonenumber) {
        const prefillData = getPrefillData();
        const question = questions && questions?.find((item: any) => item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number')
        phonenumber = (prefillData && prefillData[question?.label || '']) || '';
        if (phonenumber && phonenumber.includes(' ')) {
          phonenumber = phonenumber.replaceAll(' ', '');
        }
      }
      let email = searchParams.get("email") || (state as { [key: string]: string })?.email;
      if (!email) {
        const prefillData = getPrefillData();
        const question = questions && questions?.find((item: any) => item?.type === 'zt_contact_email' || item?.type === 'email')
        email = (prefillData && prefillData[question?.label || '']) || '';
      }
      if (organisation?.id && (phonenumber || email)) {
        const prefillData = getPrefillData();
        const contactInfo = await fetchContactInfo(organisation?.id, phonenumber, email); 
        if (contactInfo && contactInfo?.length) {
          const prefillObject:any = {}
          for (let index = 0; index < questions?.length; index ++) {
            const item = questions?.[index];
            prefillObject[item?.label] = prefillObjectTop?.[item?.label] || '';
            if (item?.type === 'zt_customer_name' || (item?.type === 'single_line' && item?.label.toLowerCase() === 'company name')) {
              prefillObject[item?.label] = contactInfo?.[0]?.cust_name || ''
            }
            if (item?.type === 'zt_contact_name') {
              prefillObject[item?.label] = contactInfo?.[0]?.con_name || '';
            }
            if ((item?.type === 'zt_contact_email' || item?.type === 'email')) {
              prefillObject[item?.label] = contactInfo?.[0]?.con_email || '';
            }
            if ((item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number')) {
              prefillObject[item?.label] = contactInfo?.[0]?.con_phone || '';
            }
          }
          
          setPrefill({...prefillData, ...prefillObject});
          prefillObjectTop = {...prefillData, ...prefillObject};
          setIsCRMPrefill(true);
        }
      }

      let prefillTypeParam = searchParams.get("prefilltype") || (state as { [key: string]: string })?.prefilltype;
      prefillTypeParam = event?.id === 656 ? 'anonymous' : prefillTypeParam;
      setPrefillType(prefillTypeParam);
      if (event?.anonymous_guest && prefillTypeParam && prefillTypeParam === 'anonymous') {
        const prefillObject:any = {}
        const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';
        const nanoid = customAlphabet(alphabet, 12);
        const id =  nanoid();
        for (let index = 0; index < questions?.length; index ++) {
          const item = questions?.[index];
          prefillObject[item?.label] = '';
          if (item?.type === 'zt_customer_name' || (item?.type === 'single_line' && (item?.label.toLowerCase() === 'company name' || item?.label.toLowerCase() === 'company'))) {
            prefillObject[item?.label] = `new_${id || ''}`;
          }
          if (item?.type === 'zt_contact_name') {
            prefillObject[item?.label] = `new_${id || ''}`;
          }
          if ((item?.type === 'zt_contact_email' || item?.type === 'email')) {
            prefillObject[item?.label] = `new_${id || ''}@noemail.com`;
          }
          // if ((item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number')) {
          //   prefillObject[item?.label] = '9999999999';
          // }
        }
        setPrefill({ ...prefillObject });
        prefillObjectTop = { ...prefillObject };
        setIsCRMPrefill(true);
      }

      setEventId(event?.id);
      setBusinessEmail(event?.business_email);
      setAvailableUsers(available_users);
      if (event?.fallback_event) {
        const tz = (window && window?.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone);
        const country = ct.getCountryForTimezone(tz || event?.fallback_event?.timezone || 'Asia/Kolkata')
        setCountryFromTz(country?.id?.toLowerCase() || 'in');
        setFallbackEvent({
          id: event?.fallback_event?.id,
          slug: event?.fallback_event?.slug,
          status: event?.fallback_event?.status
        });
      }

      setAllottedUser({
        id: available_users[0]?.id,
        firstName: available_users[0]?.first_name || '',
        lastName: available_users[0]?.last_name || '',
        avatarURL: available_users[0]?.avatar_url || '',
      });

      setQuestions(questions);

      setOrganisationName(organisation?.name);
      setOrganisationId(organisation?.id);
      setWorkspaceId(organisation?.workspace?.id);
      setIsMSRoomEnabled(organisation?.feature_flags?.ms_room_activated || false);
      setWpSubscriptionTier(organisation?.workspace?.subscription_tier || 'free_tier');

      const themecolor = searchParams.get("themecolor") || (state as { [key: string]: string })?.themecolor;
      const ctaText = searchParams.get("ctaText") || (state as { [key: string]: string })?.ctaText;
      const calloutText = searchParams.get("calloutText") || (state as { [key: string]: string })?.calloutText;
      setThemeColor(event?.configs?.widget_color || themecolor || '#31667f');
      setAppThemeColor(event?.configs?.widget_color || themecolor || '#31667f')
      setIntroText(event?.configs?.widget_callout_text || calloutText || "Let's meet and solve your need now!");
      setSubmitButtonText(event?.configs?.widget_cta_text || ctaText || 'Meet Now');
      if (process.env.REACT_APP_ENV === 'development') {
        setVoiceCall(true);  
      } else {
        setVoiceCall(event?.id === 656);
      }
      setIsEventLoaded(true);

      const utm_flags = getUTMObject();
      if (embedType !== EMBED_TYPE_POPUP) {
        AppTracking.widgetLoad(organisation?.workspace?.id, {
          event_id: event?.id,
          is_realtime: true,
          url: window.location.href,
          timestamp: new Date().toJSON(),
          utm_params: {
            ...utm_flags
          },
          source: 'URL',
          userAvailable: Boolean((available_users && available_users?.length) || false),
          workspace_id: organisation?.workspace?.id,
        })
      } 

      window.parent.postMessage({
        type: 'updateCalloutText',
        value: event?.configs?.widget_callout_text || calloutText || "Let's meet and solve your need now!"
      }, "*");
      window.parent.postMessage({
        type: 'canShowCallout',
        value: event?.configs?.widget_callout_show !== false
      }, "*");
      window.parent.postMessage({
        type: 'updateWidgetPosition',
        value: event?.configs?.widget_position || "bottomright" 
      }, "*");
      window.parent.postMessage({
        type: 'updateThemeColor',
        value: event?.configs?.widget_color || themecolor || '#31667f'
      }, "*")

      const apprefresh = searchParams.get("apprefresh") || (state as { [key: string]: string })?.apprefresh
      if (apprefresh) {
        window.location.href = `${window.location.origin}${pathname}`;
        return;
      }

      setIsLoading(false);
    } catch(error) {
      console.error("loadEventData", error);
      setIsEventLoaded(true);
      setIsLoading(false);
    }
  };

  const verifyAuth = async () => {
    try {
      await AuthService.verify();
      const response = await ZipteamsService.me();
      const { id, organisation = {}, meta = {} } = response?.data?.data;
      const startDate = new Date().toISOString();
      const endDate = addSeconds(new Date(), 10).toISOString();
      await ZipteamsService.getUpcomingConversation(
        startDate,
        endDate,
        true,
      );
      startPolling(8000, Boolean(id));
      const { feature_flags = {} } = organisation;
      const { realtime_meeting_availaibility = false } = meta;
      const { realtime_widget_enabled = false } = feature_flags;
      if (realtime_widget_enabled) {
        setShowRealtimeSwitch(true);
      }
      if (realtime_meeting_availaibility) {
        setIsAvailabilityToggleSet(true);
      }
      setUserId(id);
      setIsAuthenticated(true);
  
    } catch (err) {
      setIsAuthenticated(false);
    }
  }

  const startPolling = async (duration:number, isUserSignedIn: Boolean) => {
    const startDate = new Date().toISOString();
    const endDate = addSeconds(new Date(), 10).toISOString();
    if (isUserSignedIn) {
      try {
        await ZipteamsService.getUpcomingConversation(
          startDate,
          endDate,
          true,
        );
        // const { data = [] } = response?.data;
        // if (data?.length) {
        //   const userSchedule = data?.filter(item => item?.user_id === user?.id);
        //   if (userSchedule?.length) {
        //     const sch = userSchedule?.[0];
        //     const {
        //       meet_url,
        //       passcode,
        //       customer_id,
        //       meeting_source,
        //       scheduled_at,
        //     } = sch;
        //     const resp = await CustomerService.getCustomer(customer_id);
        //     const { data: respData } = resp?.data;
        //     const { name, contacts = [] } = respData;
        //     const { name: contactName } = contacts?.[0];
        //     dispatch({
        //       type: SHOW_MEETING_NOTIFY_MODAL,
        //       payload: {
        //         meetingUrl: meet_url,
        //         passcode,
        //         customerName: name,
        //         contactName,
        //         meetingSource: meeting_source,
        //         scheduledAt: scheduled_at,
        //       },
        //     });
        //   }
        // }
      } catch (err) {
        console.log(err);
      }
      await sleep(duration);
      startPolling(duration, isUserSignedIn);
    }
  };

  const initialize = async () => {
    setIsLoading(true);
    const embed = searchParams.get("embed_type") 
    if (embed !== EMBED_TYPE_POPUP) {
      await verifyAuth();
    }
    await loadEventData();
    setIsLoading(false);
  }

  const setAppThemeColor = (value: string) => {
    document.documentElement.style.setProperty('--color-secondary', hexToRgb(value.includes('#') ? value : `#${value}` || '#31667f'));
  }

  useEffect(() => {
    setEmbedType(searchParams.get("embed_type"));
  }, [location]);

  useEffect(() => {
    if (isEventLoaded && eventId && !isMSRoomEnabled) {
        const wsIO = socket();
        const user = (wsIO?.auth as any)?.user_id;
        setGuestId(user);
        wsIO.on('connect', () => {
          console.log('WS connected'); 
        });
        wsIO.on('userJoinedCall', (data) => {
          setHasUserJoined(true);
        });
        return () => {
          console.log('removing sockets')
          wsIO.off('connect');
          wsIO.off('userJoinedCall');
        }
      } 
      return () => {}
  }, [eventId, isMSRoomEnabled, isEventLoaded])

  useEffect(() => {
    const prefillData = searchParams.get('prefill') || (state as { [key: string]: string })?.prefill;
    const themecolor = searchParams.get("themecolor") || (state as { [key: string]: string })?.themecolor;
    const ctaText = searchParams.get("ctaText") || (state as { [key: string]: string })?.ctaText;
    const calloutText = searchParams.get("calloutText") || (state as { [key: string]: string })?.calloutText;
    let phonenumber = searchParams.get("number") || (state as { [key: string]: string })?.number;
    const email = searchParams.get("email") || (state as { [key: string]: string })?.email;
    const customerName = searchParams.get("name") || (state as { [key: string]: string })?.name;
    const companyName = searchParams.get("company") || (state as { [key: string]: string })?.company;
    const prefillTypeParam = searchParams.get("prefilltype") || (state as { [key: string]: string })?.prefilltype;

    const utm_flags = getUTMObject();

    if (params.org && !params.slug) {
      navigate(`/${params.org}/instant${search}`, {
        replace: true,
        state: {
          prefill: prefill,
          name: customerName,
          company: companyName,
          email,
          number: phonenumber,
        },
      });
    }

    if (utm_flags && Object.keys(utm_flags)?.length) {
      setUTMFlags(utm_flags)
    }

    if (phonenumber) {
      if (phonenumber && phonenumber.includes(' ') && phonenumber.indexOf(' ') === 0) {
        phonenumber = phonenumber.replace(' ', '+');
      }
      if (phonenumber && phonenumber.includes(' ')) {
        phonenumber = phonenumber.replaceAll(' ', '');
      }
      setPrefillPhoneNumber(phonenumber);
    }

    if (email) {
      setPrefillEmail(email);
    }

    if (customerName) {
      setPrefillCustomerName(customerName);
    }

    if (companyName) {
      setPrefillCompanyName(companyName);
    }

    if (prefillData) {
      const parsedData: { [key: string]: string } = typeof prefillData === 'string' ? JSON.parse(prefillData) : prefillData;
      if (!prefill) setPrefill(parsedData);
      navigate(`${pathname}${searchRemove(search, 'prefill')}`, { replace: true });
    }

    if (searchHas(search, 'name') || searchHas(search, 'email') || searchHas(search, 'number') || searchHas(search, 'company') || searchHas(search, 'prefilltype')) {
      setPrefillType(prefillTypeParam);
      navigate(
        `${pathname}${searchRemove(
          searchRemove(
            searchRemove(
              searchRemove(searchRemove(search, "company"), "number"),
              "email"
            ),
            "name"
          ),
          "prefilltype"
        )}`,
        {
          replace: true,
          state: { referrer },
        }
      );
    }
    if (themecolor) {
      setThemeColor(themecolor);
      setAppThemeColor(themecolor);
    }
    if (ctaText) {
      setSubmitButtonText(ctaText);
    }
    if (calloutText) {
      setIntroText(calloutText)
    }

    initialize()
    window.addEventListener('message', async (event) => {
      if (event?.data?.type === 'onCallEnd') {
        setShowMeet(false);
        setShowThankYou(true);
      }
    });
    return () => {
      window.removeEventListener('message', () => {});
    }
  }, []);
  
  return (
    <Layout>
      <Snackbar
        visible={snackConfig?.visible || false}
        autoDismiss={snackConfig?.autoDismiss || false}
        label={snackConfig?.label || ""}
        type={snackConfig?.type || ""}
        onClose={() => {
          setSnackConfig((data) => ({
            ...data,
            label: "",
            autoDismiss: false,
            visible: false,
          }));
        }}
        isRealtime
      />
      <div className={`
        flex flex-col min-h-screen 
        ${embedType === EMBED_TYPE_POPUP ? 'bg-transparent' : 'bg-background-app'}
      `}>
        <UpdatedApp />
      </div>
    </Layout>
  );
};

export default RealtimeApp;