import { FC, useEffect, useState } from "react";
import ProfileImg from "../../components/profile-img";
import {
  useSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ZipteamsService from "../../services/zipteams.service";
import { format, parseISO } from "date-fns";
import ScheduleService from "../../services/schedule.service";
import EventService from "../../services/event.service";
import Loader from "../../components/loader";
import MeetErrorImg from "../../assets/icons/meet-not-found.svg";
import clockIcon from "../../assets/icons/clock.svg";

interface CancelMeetingProps {}

const CancelMeeting: FC<CancelMeetingProps> = ({}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const room_id = searchParams?.get("room");
  const booking_id = searchParams?.get("id");

  const navigate = useNavigate();

  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [scheduledAt, setScheduledAt] = useState<string>("");
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [organisationName, setOrganisationName] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [contactEmail, setContactEmail] = useState<string>("");
  const [allotedUserEmail, setAllotedUserEmail] = useState<string>("");
  const [bookingTimeZone, setBookingTimeZone] = useState<string>("");
  const [eventId, setEventId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);
  const [cancelScheduleClick, setCancelScheduleClick] =
    useState<boolean>(false);
  const [eventDuration, setEventDuration]=useState<number>(0)
  const [disabled, setDisabled]=useState<boolean>(false);
  const [allowRestartFlow, setAllowRestartFlow] = useState<boolean>(false);

  const getEventData = async () => {
    setLoading(true);
    try {
      const allowrestartflow = searchParams.get("allowrestartflow")

      if (allowrestartflow && allowrestartflow === 'false' ) {
        setAllowRestartFlow(false);
      }
      const response = room_id && (await ZipteamsService.roomDetails(room_id));
      if (response && response.data) {
        setAvatarUrl(response?.data?.data?.user?.avatar);
        setScheduledAt(response?.data?.data?.schedule?.schedule_at);
        setOrganisationName(response?.data?.data?.organisation?.name);
        setContactName(response?.data?.data?.contact?.contact_name);
        setAllotedUserEmail(response?.data?.data?.user?.email);
        setContactEmail(response?.data?.data?.contact?.contact_email);
      }
      const booking_response =
        booking_id &&
        (await EventService.getBookingDetails(Number(booking_id)));
      if (booking_response && !booking_response?.data) {
        setShowErrorScreen(true);
      }
      if (booking_response) {
        setBookingTimeZone(booking_response?.data?.timezone);
        setEventId(booking_response?.data?.event_id);
        setEventDuration(booking_response?.data?.event?.duration);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowErrorScreen(true);
    }
  };

  const cancelBooking = async () => {
    const cancelBookingRequestParams = {
      cancellation_reason: cancellationReason,
      booking_id: Number(booking_id),
      room_id: String(room_id),
      contact_name: contactName,
      booking_scheduled_at: scheduledAt,
      organisation_name: organisationName,
      alloted_user_email: allotedUserEmail,
      booking_timezone: bookingTimeZone,
      event_id: eventId,
      contact_email: contactEmail,
    };
    const response = await ScheduleService.cancelSchedule(
      cancelBookingRequestParams
    );
  };

  useEffect(() => {
    getEventData();
  }, [room_id, booking_id]);

  const handleSubmit = async () => {
    setDisabled(true)
    await cancelBooking();
    setCancelScheduleClick(true);
  };

  const handleBookMeeting = () => {
    navigate(`/${params.org}/${params.slug}`);
  };

  return (
    <div
      className={`flex-auto flex flex-col items-center m-auto p-4 overflow-y-auto ${
        showErrorScreen ? "bg-white" : "bg-[#31667f]/10"
      }  lg:justify-center h-screen`}
    >
      {loading ? (
        <Loader />
      ) : showErrorScreen ? (
        <div className="flex flex-col justify-between items-center my-auto">
          <img
            className={`mb-8 h-40 w-40 md:h-52 md:w-52`}
            src={MeetErrorImg}
            alt="meet-error"
          />
          <span
            className={`text-base mb-2 flex-1 text-center md:text-xl font-medium md:w-3/5 text-[#1c1d21]`}
          >
            Oops! This meeting is already cancelled or rescheduled. You may schedule the
            meeting again using the below link.
          </span>
          {allowRestartFlow && (
            <button
              type="button"
              onClick={() => {
                navigate(`/${params?.org}/${params?.slug}`);
              }}
              className={`bg-[#31667f]  font-bold text-center py-3 md:py-3.5 px-5 md:px-6 text-white text-sm rounded-md mt-4`}
            >
              Schedule Meeting
            </button>
          )}
          <p className="text-sm md:text-base absolute bottom-8">
            Schedule your own meeting on{" "}
            <a
              className="font-bold text-[#31667f]"
              href="https://app.zipteams.com"
              target="_blank"
              rel="noreferrer"
            >
              app.zipteams.com
            </a>
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-center text-center md:mb-6 lg:w-[52%] my-auto md:m-0">
          <h2 className="font-bold text-xl leading-5 mb-2 lg:mb-3 lg:text-2xl lg:leading-7">
            {cancelScheduleClick ? "Meeting Cancelled" : "Cancel Meeting"} - “
            {params?.slug}”
          </h2>
          <div className="text-base leading-4 md:text-lg lg:leading-5 mb-4 md:mb-6 mt-1">
            <span className=" text-[#1c1d21]">
              {`${
                scheduledAt && format(parseISO(scheduledAt), "MMM dd, yyyy")
              } | `}
              {scheduledAt && format(parseISO(scheduledAt), "hh:mm a")}
            </span>
            <span className="text-[#1c1d21]">
              <img
                src={clockIcon}
                className="inline-block w-3 md:w-4 ml-2 md:ml-3 mb-0.5 md:mb-1"
                alt="clock"
              />{" "}
              {eventDuration} min
            </span>
          </div>
          {cancelScheduleClick ? (
            <div
              className={`
                  w-full h-[80%] items-center flex-auto flex flex-col border border-primary-gray rounded-[18px] py-8 md:py-16 bg-white 
                  md:max-w-[520px] max-h-[450px] md:max-h-[550px] md:rounded-3xl
              `}
            >
              <div className="flex-auto flex flex-col justify-center items-center">
                <ProfileImg avatar={avatarUrl} />
                <div className="w-3/4 mt-2 flex flex-col justify-center items-center">
                  <div className="font-bold text-lg leading-5 text-center lg:text-2xl lg:leading-7 mb-[18px] lg:mb-6">
                    {`Sorry to have missed you this time! Looking forward to meeting you soon.`}
                  </div>
                  {allowRestartFlow && (
                    <button
                      type="button"
                      className="cursor-pointer w-[200px] flex justify-center items-center py-3 bg-secondary font-bold text-sm leading-4 rounded-lg text-white disabled:bg-opacity-60 disabled:pointer-events-none mb-2 lg:mb-3 lg:py-4 lg:w-[250px] lg:text-base lg:leading-5 lg:rounded-xl"
                      onClick={handleBookMeeting}
                    >
                      Book Another Meeting
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`
               h-full border border-primary-dark items-center lg:items-stretch
              border-opacity-10 shadow-md rounded-3xl bg-white
              flex-auto flex flex-col lg:flex-row md:justify-end mx-4 my-auto max-h-[520px] md:max-w-[500px] md:max-h-[550px]
            `}
            >
              <div
                className={`flex-auto flex pt-4 flex-col p-5 md:p-7 w-full items-center overflow-y-auto`}
              >
                <div className="flex gap-2 items-center">
                  <ProfileImg avatar={avatarUrl} small />
                  <div
                    className={`w-auto font-bold text-lg md:text-xl leading-6 ml-3 md:ml-4 my-auto text-left mb-2`}
                  >
                    Please confirm your cancellation of meeting on{" "}
                    {`${
                      scheduledAt &&
                      format(parseISO(scheduledAt), "MMM dd, yyyy")
                    } | `}
                    {scheduledAt && format(parseISO(scheduledAt), "hh:mm a")}{" "}
                    (IST)
                  </div>
                </div>
                <div className="mt-4 text-sm md:text-base font-semibold text-left flex justify-start w-full">
                  Reason for cancellation
                </div>
                <textarea
                  className="w-full h-28 border border-[#D7D7D7] rounded-lg resize-none"
                  name="cancellationReason"
                  value={cancellationReason}
                  onChange={(e) => setCancellationReason(e.target.value)}
                />
                <div className="mt-36 mb-4">
                  <button
                    type="button"
                    className="mx-auto disabled:bg-secondary-dark cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3.5 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none"
                    onClick={handleSubmit}
                    disabled={disabled}
                  >
                    Cancel Meeting
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CancelMeeting;
