import TrackingService from "../services/tracking.service";


export class AppTracking {
  static widgetLoad(workspace_id: number, meta: { [key: string]: any } = {}) {
    try {
      if (!(workspace_id && meta.event_id)) return;
      const param = {
        event_name: "event_load",
        workspace_id,
        event_meta: meta,
      };
      TrackingService.postTrackingEvents(param);
    } catch (err) {
      console.log(err);
    }
  }

  static realtimeFormSubmit(
    workspace_id: number,
    meta: { [key: string]: any } = {}
  ) {
    try {
      if (!(workspace_id && meta.event_id)) return;
      const param = {
        event_name: "realtime_form_submit",
        workspace_id,
        event_meta: meta,
      };
      TrackingService.postTrackingEvents(param);
    } catch (err) {
      console.log(err);
    }
  }

  static scheduledFormSubmit(
    workspace_id: number,
    meta: { [key: string]: any } = {}
  ) {
    try {
      if (!(workspace_id && meta.event_id)) return;
      const param = {
        event_name: "scheduled_form_submit",
        workspace_id,
        event_meta: meta,
      };
      TrackingService.postTrackingEvents(param);
    } catch (err) {
      console.log(err);
    }
  }

  static realtimeJoinClicked(
    workspace_id: number,
    meta: { [key: string]: any } = {}
  ) {
    try {
      if (!(workspace_id && meta.event_id)) return;
      const param = {
        event_name: "realtime_join_clicked",
        workspace_id,
        event_meta: meta,
      };
      TrackingService.postTrackingEvents(param);
    } catch (err) {
      console.log(err);
    }
  }

  static realtimeScheduleLaterClicked(
    workspace_id: number,
    meta: { [key: string]: any } = {}
  ) {
    try {
      if (!(workspace_id && meta.event_id)) return;
      const param = {
        event_name: "realtime_schedule_later_clicked",
        workspace_id,
        event_meta: meta,
      };
      TrackingService.postTrackingEvents(param);
    } catch (err) {
      console.log(err);
    }
  }

  static meetingLinkCopied(
    workspace_id: number,
    meta: { [key: string]: any } = {}
  ) {
    try {
      if (!(workspace_id && meta.event_id)) return;
      const param = {
        event_name: "realtime_meeting_link_copied",
        workspace_id,
        event_meta: meta,
      };
      TrackingService.postTrackingEvents(param);
    } catch (err) {
      console.log(err);
    }
  }

  static newTabClicked(
    workspace_id: number,
    meta: { [key: string]: any } = {}
  ) {
    try {
      if (!(workspace_id && meta.event_id)) return;
      const param = {
        event_name: "realtime_new_tab_clicked",
        workspace_id,
        event_meta: meta,
      };
      TrackingService.postTrackingEvents(param);
    } catch (err) {
      console.log(err);
    }
  }
}
