import { ShareIcon, YTIcon } from "../../assets/vectors";

type EndCallMessageProps = {
  onScheduleClick: () => void;
};

const EndCallMessage = ({
  onScheduleClick,
}: EndCallMessageProps): JSX.Element => {
  const onShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          text: `Hey, I got to know about this Zipteams smart meeting widget that helps drive instant demos and visitor conversions on the website. Integrates in a few minutes and starts improving your marketing ROI. Get more at https://zipteams.com/product-zipconnect/`,
        })
        .catch(() => console.log("Error"));
    }
  };

  return (
    <div
      id="zt-end-call-msg-container-elm"
      className="flex-auto w-full flex flex-col items-center mt-2"
    >
      <div className="text-center text-xl leading-[1.375rem] font-bold">
        Thank you!!
        <span className="block font-normal text-base leading-[1.375rem] mt-1">
          It was great meeting you!
        </span>
      </div>
      <div className="mt-6">
        <button
          type="button"
          className="w-[220px] cursor-pointer py-3.5 px-[1.375rem] bg-secondary rounded-lg flex justify-center items-center shadow-none text-white border-none text-base leading-[18px] font-semibold"
          onClick={onScheduleClick}
        >
          Schedule another call
        </button>
      </div>
      {/* <div className="mt-auto">
        <a
          className="text-secondary no-underline hover:underline"
          href="https://zipteams.com/instantmeetings/"
          target="_blank"
          rel="noreferrer"
        >
          <p className="m-0 p-0 text-secondary text-sm md:text-base leading-5 text-center">
            Add <span className="font-fjalla text-sm leading-4">Zipteams</span>{" "}
            meeting widget to your website
          </p>
        </a>
        <div className="flex justify-center items-center gap-4 mt-2.5">
          <button
            type="button"
            onClick={onShareClick}
            className="flex justify-center items-center p-1 bg-transparent border-none cursor-pointer"
          >
            <ShareIcon />
          </button>
          <a
            href="https://www.youtube.com/watch?v=lNozzEZ6nUk"
            target="_blank"
            rel="noreferrer"
          >
            <YTIcon />
          </a>
        </div>
      </div> */}
    </div>
  );
};
export default EndCallMessage;
