import Select from 'react-select'
import { EyeOff } from 'react-feather';

import { IFieldProps } from "../question-form";


export default function Autocomplete(props: IFieldProps) {
  const {
    label,
    name,
    required,
    error,
    options,
    value,
    hidden,
    disabled,
    onOptionsSelect,
    formikProps
  } = props;

  const root: any = document.querySelector(":root");
  const color = root?.style?.getPropertyValue("--color-secondary");

  const styles = {
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor:
        state.isSelected || state.isFocused
          ? `rgba(${color}, 0.5)`
          : "transparent",
      color: state.isSelected || state.isFocused ? "#fff" : "#1c1d21",
    }),
  };

  return (
    <div className="flex flex-col mb-3.5 md:mb-[18px]">
      <div className="flex items-baseline">
        <label className="text-sm font-bold text-primary-dark mb-1.5 md:mb-3">
          {`${label} ${required ? "*" : ""}`}
          {hidden && disabled ? <EyeOff className="inline w-4 h-4 ml-2" /> : []}
        </label>
        <div className="text-red-500 text-xs font-semibold ml-3">{error}</div>
      </div>
      <div className='relative' data-name={name}>
        <Select
          name={name}
          isDisabled={disabled}
          className="zt-autocomp-select"
          classNamePrefix="zt-autocomp-select"
          value={options?.find((itm) => itm?.label === value)}
          options={options?.map((opt) => ({ ...opt, value: opt?.label }))}
          menuPortalTarget={document.querySelector('body') as HTMLElement}
          onChange={(value) => {
            formikProps.setFieldValue(name, value?.label, true);
            const selectedOption = options?.filter(
              (opt) => opt?.label === value?.label
            );
            if (typeof onOptionsSelect === "function") {
              onOptionsSelect(
                selectedOption?.[0]?.step_question?.step_id || "",
                props?.name
              );
            }
          }}
          onBlur={props?.onBlur}
          styles={styles}
        />
      </div> 
    </div>
  );
}
