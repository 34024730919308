export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const EMBED_TYPE_POPUP = "popup";

export const ZIPTEAMS_OAUTH_ENDPOINT = process.env.REACT_APP_ZIPTEAMS_OAUTH_ENDPOINT;
export const ZIPTEAMS_OAUTH_CLIENT_ID = process.env.REACT_APP_ZIPTEAMS_OAUTH_CLIENT_ID;
export const ZIPTEAMS_API_BASE_URL = process.env.REACT_APP_ZIPTEAMS_API_BASE_URL;

export const ZIPTEAMS_API_CLIENT_KEY = process.env.REACT_APP_API_CLIENT_KEY;
export const ZIPTEAMS_API_SECRET = process.env.REACT_APP_API_CLIENT_SECRET;

export const TRACKING_APP_URL = process.env.REACT_APP_TRACKING_API_URL;
export const MS_APP_DOMAIN = process.env.REACT_APP_MS_APP_DOMAIN;
