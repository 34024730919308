import { formatInTimeZone } from "date-fns-tz";

export type Slot = {
  startTime: Date;
  endTime: Date;
  timeZone: string;
};

export const getTimeZoneNameFromDate = (date: Date | undefined) => {
  const dateString = date?.toTimeString();
  return (
    dateString?.substring(
      dateString?.indexOf("(") + 1,
      dateString?.lastIndexOf(")")
    ) || ""
  );
};

export const getDurationBreakdown = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration - hours * 60);

  return { hours, minutes };
};

export const formatDurationToHrsAndMins = (duration: number): string => {
  if (!duration) {
    return "0 mins";
  }
  const breakdown = getDurationBreakdown(duration);
  if (breakdown.hours < 0 || breakdown.minutes < 0) return "";
  const hours =
    breakdown.hours === 0
      ? ""
      : breakdown.hours === 1
      ? `${breakdown.hours} hr`
      : `${breakdown.hours} hrs`;
  const minutes =
    breakdown.minutes === 0
      ? ""
      : breakdown.minutes === 1
      ? `${breakdown.minutes} min`
      : `${breakdown.minutes} mins`;
  return `${
    breakdown.hours === 0 && breakdown.minutes === 0
      ? "Less than a minute"
      : `${hours} ${minutes}`
  }`;
};

export const formattedSlot = (slot: Slot): string => {
  const start = formatInTimeZone(slot.startTime, slot.timeZone, "hh:mm a");
  const end = formatInTimeZone(slot.endTime, slot.timeZone, "hh:mm a");
  const date = formatInTimeZone(slot.startTime, slot.timeZone, "EEEE, MMMM dd, yyyy");

  return `${start} - ${end}, ${date}`;
}

export const searchParse = (search: string): Record<string, string> => {
  let parsed: Record<string, string> = {};

  if (search.startsWith("?")) search = search.substring(1);

  const parts = search.split("&").filter(part => part !== '');
  for (let part of parts) {
    const tokens = part.split("=");
    parsed[tokens[0]] = tokens[1];
  }
  return parsed;
};

export const searchStringify = (searchParts: Record<string, string>): string => {
  let result = '?';
  for (let part in searchParts) result += `${part}=${searchParts[part]}&`;
  return result.substring(0, result.length - 1);
};

export const searchHas = (search: string, param: string): boolean => {
  return search.indexOf(param+"=") !== -1;
}

export const searchSet = (search: string, param: string, value: string): string => {
  const parts = searchParse(search);
  parts[param] = value;
  return searchStringify(parts);
}

export const searchRemove = (search: string, param: string): string => {
  if (searchHas(search, param)) {
    let tokens = searchParse(search);
    delete tokens[param];
    return searchStringify(tokens);
  }
  return search;
}

export const getAuthToken = (): string => {
  const authCookie = document.cookie.split(' ').find(cookie => cookie.includes('X-Zipme-Authorization'));
  if (!authCookie) return '';
  else return authCookie.split('=')[1].replace(';', '');
}

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
};

export const sleep = (duration: number) =>
  new Promise(resolve => setTimeout(resolve, duration));

export const convertToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
