import {
  ZIPTEAMS_API_BASE_URL,
  ZIPTEAMS_API_CLIENT_KEY,
  ZIPTEAMS_API_SECRET,
  ZIPTEAMS_OAUTH_CLIENT_ID,
} from "../utils/constants";
import { getAuthToken } from "../utils/helper";
import HttpClient from "../utils/http-client";

class Zipteams {
  me = () => {
    const authToken = getAuthToken();
    return HttpClient.get(`${ZIPTEAMS_API_BASE_URL}/v1/user/me`, {
      headers: {
        Authorization: authToken,
        "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
      },
    });
  };

  updateUser = (id: number, payload: any) => {
    const authToken = getAuthToken();
    return HttpClient.put(`${ZIPTEAMS_API_BASE_URL}/v1/user/${id}`, payload, {
      headers: {
        Authorization: authToken,
        "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
      },
    });
  };

  workspaces = (organisation_id: number) => {
    const authToken = getAuthToken();
    return HttpClient.get(
      `${ZIPTEAMS_API_BASE_URL}/v1/organisation/${organisation_id}/workspaces`,
      {
        headers: {
          Authorization: authToken,
          "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
        },
      }
    );
  };

  getUpcomingConversation = (startDate: string, endDate: string, isPolling: boolean) => {
    const authToken = getAuthToken();
    return HttpClient.get(
      `${ZIPTEAMS_API_BASE_URL}/v1/schedule/organisation?start_date=${startDate}&end_date=${endDate}&is_polling=${isPolling}`,
      {
        headers: {
          Authorization: authToken,
          "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
        },
      }
    );
  }

  updateProfilePicture = (data: { data_url: string }) => {
    const authToken = getAuthToken();
    return HttpClient.put(`${ZIPTEAMS_API_BASE_URL}/v1/user/profilepicture`, data,
      {
        headers: {
          Authorization: authToken,
          "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
        },
      }
    );
  }

  updateOrgName = (org_id: number, data: { name: string }) => {
    const authToken = getAuthToken();
    return HttpClient.put(`${ZIPTEAMS_API_BASE_URL}/v1/organisation/${org_id}`, data,
      {
        headers: {
          Authorization: authToken,
          "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
        },
      }
    );
  }

  updateProfile = (data: { [key: string]: string }) => {
    const authToken = getAuthToken();

    return HttpClient.put(`${ZIPTEAMS_API_BASE_URL}/v1/user/profile`, data,
      {
        headers: {
          Authorization: authToken,
          "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
        },
      }
    );
  }

  getContactInfo = (orgId: number, params = {}) => {
    const authToken = getAuthToken();
    return HttpClient.get(
      `${ZIPTEAMS_API_BASE_URL}/v1/client/contact/details/organization/${orgId}`,
      {
        params,
        headers: {
          Authorization: authToken,
          "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
          'api-client-key': String(ZIPTEAMS_API_CLIENT_KEY),
          'api-client-secret': String(ZIPTEAMS_API_SECRET),
        },
      }
    );
  }

  getAllocatedNode = (roomId: string, workspaceId: number) => {
    const authToken = getAuthToken();
    return HttpClient.get(
      `${ZIPTEAMS_API_BASE_URL}/v1/client/room/node/workspace/${workspaceId}/node/room/${roomId}`,
      {
        headers: {
          Authorization: authToken,
          "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
          'api-client-key': String(ZIPTEAMS_API_CLIENT_KEY),
          'api-client-secret': String(ZIPTEAMS_API_SECRET),
        },
      }
    );
  }

  roomDetails = (room_id:string) => {
    const authToken = getAuthToken();
    return HttpClient.get(`${ZIPTEAMS_API_BASE_URL}/v1/open/room/${room_id}`, {
      headers: {
        Authorization: authToken,
        "X-Zipteams-Client-Id": String(ZIPTEAMS_OAUTH_CLIENT_ID),
      },
    });
  };
}

const ZipteamsService = new Zipteams();
export default ZipteamsService;
