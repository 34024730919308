/* eslint-disable array-callback-return */
import { Formik, FormikHelpers, FormikValues } from "formik";
import { Fragment, useEffect, useRef, useState } from 'react';
import Autocomplete from '../common/autocomplete';
import CheckboxComponent from '../common/checkbox';
import InputComponent from "../common/input";
import PhoneNumberInputComponent from "../common/phonenumber";
import TextAreaComponent from "../common/textarea";
import ThreeDotLoader from '../loader/three-dots-loader';

export type FormElement = {
  id: number;
  type:
    | "zt_contact_name"
    | "zt_contact_email"
    | "zt_customer_name"
    | "zt_contact_phone_number"
    | "single_line"
    | "multi_line"
    | "radio_button"
    | "phone_number"
    | "email"
    | 'disclaimer';
  is_required: boolean;
  hidden?: boolean;
  is_step?: boolean;
  step_id?: string;
  label: string;
  default_value?: string;
  options?: { label: string; user_allocated?: boolean; tag_name?: string; step_question?: { step_id: string; label: string } }[] | null;
};

type FormUpdatedProps = {
  form: FormElement[];
  handleSubmit: Function;
  submitDisabled: boolean;
  submitText: string;
  preview?: boolean;
  prefill?: { [key: string]: string };
  organisationId: number;
  prefillPhoneNumber: string;
  prefillEmail: string;
  prefillCustomerName: string;
  prefillCompanyName: string;
  isCRMPrefill: boolean;
  businessEmail?: boolean;
  setVisibleStepIds: (data: { [key: string]: string }) => void;
  country: string;
};

export const renderForm = (
  field: FormElement,
  preview: boolean | undefined,
  formik?: FormikValues,
  onOptionsSelect?: (id: string, questionId: string) => void,
  country?: string
) => {
  const { type, id, label, is_required, options, default_value, hidden } = field;
  switch (type) {
    case "single_line":
    case "zt_contact_name":
    case "zt_customer_name":
      return (
        <InputComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
        />
      );
    case "multi_line":
      return (
        <TextAreaComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
        />
      );
    case "radio_button":
      return (
        <Autocomplete
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          options={options}
          disabled={preview}
          onOptionsSelect={onOptionsSelect}
          formikProps={formik}
        />
      );
    case "phone_number":
    case "zt_contact_phone_number":
      return (
        <PhoneNumberInputComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
          formikProps={formik}
          country={country || ''}
        />
      );
    case "email":
    case "zt_contact_email":
      return (
        <InputComponent
          type={type}
          label={label}
          required={is_required}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          disabled={preview}
        />
      );
    case "disclaimer":
      return (
        <CheckboxComponent
          type={type}
          label={label}
          required={is_required}
          defaultValue={default_value}
          name={id.toString() || ""}
          hidden={hidden}
          error={formik?.touched?.[id] && formik?.errors?.[id]}
          value={formik?.values?.[id]}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          formikProps={formik}
          disabled={preview}
        />
      );
    default:
      return <></>;
  }
};

const FormUpdated = (props: FormUpdatedProps): JSX.Element => {
  const {
    form,
    handleSubmit,
    submitText,
    preview,
    prefill,
    submitDisabled,
    organisationId,
    prefillPhoneNumber,
    prefillEmail,
    prefillCustomerName,
    prefillCompanyName,
    isCRMPrefill,
    businessEmail,
    setVisibleStepIds,
    country
  } = props;

  const [showStepIds, setShowStepIds] = useState<{[key: string] : string}>({});

  const formRef = useRef<any>(null);

  const getInitValues = () => {
    const index = form && form.findIndex(item => item.type === 'disclaimer')
    if (index !== -1) {
      const item = form?.splice(index, 1);
      if (item && item?.length) form.push(item?.[0])
    }
    const initValues: { [key: string]: string } = {};
    for (let index = 0; index < form?.length; index++) {
      const item = form?.[index];
      if (organisationId === 9 && (item?.type === 'zt_contact_email' || item?.type === 'email')) continue;
      if (item?.type === 'zt_contact_name' && prefillCustomerName && !isCRMPrefill) {
        initValues[item?.id] = prefillCustomerName || prefill?.[item?.label] || '';
      } else if (item?.type === 'zt_customer_name' && prefillCompanyName && !isCRMPrefill) {
        initValues[item?.id] = prefillCompanyName || prefill?.[item?.label] || '';
      } else if ((item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number') && prefillPhoneNumber && !isCRMPrefill){          
        initValues[item?.id] = prefillPhoneNumber ? prefillPhoneNumber?.includes('+') ? prefillPhoneNumber : `+91${prefillPhoneNumber}` : '';
      } else if ((item?.type === 'zt_contact_email' || item?.type === 'email') && prefillEmail && !isCRMPrefill) {
        initValues[item?.id] = prefillEmail || prefill?.[item?.label] || "";
      } else if (item?.type === 'disclaimer') {
        initValues[item?.id] = prefill?.[item?.label] || item?.default_value || 'N';
      } else {
        initValues[item?.id] = prefill?.[item?.label] || "";
      }
    }
    return initValues;
  };

  const getTypes = () => {
    const typeObj: { [key: string]: { type: string; required: boolean; step_id: string } } = {};
    form &&
      form.map((item) => {
        typeObj[item?.id] = { type: item?.type, required: item?.is_required, step_id: item?.step_id || '' };
      });
    return typeObj;
  };

  const FreeMailProviders = ["gmail.com", "yahoo.com", "outlook.com", "protonmail.com", "rediffmail.com", "icloud.com", "aol.com"];

  const checkFreeEmail = (email: string) => {
    const domain = email.split("@")[1];

    const isFreemailProvider = FreeMailProviders.includes(domain.toLowerCase());
    if (isFreemailProvider) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitForm = async (
    values: { [key: string]: string },
    formikHelpers: FormikHelpers<{
      [key: string]: string;
    }>
  ) => {
    const newValues = {...values};
    if (organisationId === 9) {
      let emailFieldId = '';
      let phoneFieldId = '';
      form && form.map((item) => {
        if ((item?.type === 'zt_contact_email' || item?.type === 'email')) emailFieldId = item?.id.toString();
        if ((item?.type === 'zt_contact_phone_number' || item?.type === 'phone_number')) phoneFieldId = item?.id?.toString();
      });
      newValues[emailFieldId] = `no-reply${values?.[phoneFieldId] || ''}@leadschool.in`;
    }

    await handleSubmit(newValues);
    formikHelpers.setSubmitting(false);
  };

  const cannotrender = (item: FormElement, preview: boolean) => {
    if (organisationId === 9 && (item?.type === "zt_contact_email" || item?.type === "email")) {
      return true;
    }
    if (!preview && item?.hidden) {
      return true;
    }
    let isFieldVisible = false;
    for (let index = 0; index < form?.length; index++) {
      if (form[index]?.type === 'radio_button' && showStepIds?.[form?.[index]?.id.toString()] === item?.step_id) {
        isFieldVisible = true;
        break;
      }
    }
    if (!preview && item?.is_step && !isFieldVisible) {
      return true;
    }
  }

  const updateStepFieldArr = (str: string, questionId: string) => {
    setShowStepIds({...showStepIds, [questionId]: str});
    setVisibleStepIds({...showStepIds, [questionId]: str});
  }

  // useEffect(() => {
  //   const keys = Object.keys(formRef.current?.errors);
  //   if (formRef.current?.isSubmitting && keys?.length) {
  //     const firstElm = keys?.[0];
  //     const field = document.getElementsByName(firstElm)?.[0];
  //     field?.scrollIntoView({ behavior: "smooth"});
  //   }
  // }, [formRef.current?.isSubmitting]);

  
  useEffect(() => { 
    if (form && form?.length) {
      const filteredForm = [...form];
      for(let index = 0; index < filteredForm?.length; index++) {
        if (filteredForm[index]?.type === 'radio_button' && filteredForm[index].options && filteredForm[index]?.options?.length) {
          const length = (filteredForm[index].options && filteredForm[index]?.options?.length) || 0;
          for(let index1 = 0; index1 < length; index1++) {
            const option = filteredForm[index]?.options?.[index1];
            if (option && option?.step_question && option?.step_question?.step_id){
              const idx = form.findIndex(item => item?.step_id === option?.step_question?.step_id);
              if (idx >= 0 && idx !== index + 1) {
                const removedItem = form.splice(idx, 1)[0];
                form.splice(index, 0, removedItem);
              } 
            }
          }
        }
      }
    }
  }, [form]);

  return (
    <>
      <div className="flex-auto overflow-y-auto px-1">
        <Formik
          initialValues={getInitValues()}
          onSubmit={handleSubmitForm}
          innerRef={formRef}
          validate={(values) => {
            const errors: { [key: string]: string } = {};
            const types = getTypes();
            const stepIds = showStepIds && Object.values(showStepIds);

            Object.keys(values).map((item) => {
              if (!values?.[item] && types?.[item]?.required) {
                if (types?.[item]?.step_id && stepIds.includes(types?.[item]?.step_id)) {
                  errors[item] = "Required";
                } else if (!types?.[item].step_id) {
                  errors[item] = "Required";
                }
              }
              else if (
                values?.[item] &&
                (types?.[item]?.type === "email" ||
                  types?.[item]?.type === "zt_contact_email") &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
                  values?.[item].toString()
                )
              )
                errors[item] = "Invalid email";
              else if (
                values?.[item] &&
                types?.[item]?.type === "zt_contact_email" &&
                businessEmail &&
                checkFreeEmail(values?.[item].toString())
              )
                errors[item] = "Please enter your business email";
              else if (
                values?.[item] &&
                (types?.[item]?.type === "phone_number" ||
                  types?.[item]?.type === "zt_contact_phone_number") &&
                values?.[item] &&
                values?.[item] !== "" &&
                !/^[+-\s\(\)0-9]{10,15}$/g.test(values?.[item])
              )
                errors[item] = "Invalid number";
            });

            return errors;
          }}
        >
          {(formikProps) => (
            <form
              onSubmit={formikProps?.handleSubmit}
              id="questions-form-updated"
            >
              {form.map((item) => (
                <Fragment key={item?.id}>
                  {cannotrender(item, preview || false) ? (
                    <></>
                  ) : (
                    renderForm(item, preview, formikProps, updateStepFieldArr, country)
                  )}
                </Fragment>
              ))}
            </form>
          )}
        </Formik>
      </div>
      {!preview && (
        <div className="py-3 md:py-4">
          <button
            type="button"
            onClick={() => {
              const keys = Object.keys(formRef.current?.errors);
              if (keys?.length) {
                const firstElm = keys?.[0];
                let field = document.querySelector(`[data-name="${firstElm}"]`);
                if (field) field?.scrollIntoView({ behavior: "smooth"});
                else {
                  field = document.querySelector(`[name="${firstElm}"]`);
                  field?.scrollIntoView({ behavior: "smooth"});
                }
              }
              formRef.current?.submitForm()
            }}
            className={`disabled:bg-secondary-dark mx-auto cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none`}
            // className={`
            //   w-full lg:w-[80%] flex justify-center items-center 
            // text-white bg-secondary rounded-3xl px-6 py-2 md:py-3 font-bold 
            // disabled:bg-secondary-dark outline-none m-auto text-center
            // `}
            disabled={formRef.current?.isSubmitting || submitDisabled}
          >
            {submitDisabled ? (
              <ThreeDotLoader />
            ) : (
              <>
                {submitText}
              </>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default FormUpdated;
