import { useState } from "react";
import { BiWorld } from "react-icons/bi";
import { BsCalendarEvent, BsCameraVideoFill, BsFillClockFill } from "react-icons/bs";
import { EMBED_TYPE_POPUP } from "../../utils/constants";
import { formatDurationToHrsAndMins, formattedSlot } from "../../utils/helper";
import LeadLogo from "../../assets/images/lead_logo_blue.jpg";

export type Slot = {
  startTime: Date;
  endTime: Date;
  timeZone: string;
};

export type EventDetailsProps = {
  organisationId: number;
  organisationName: string;
  organisationLogo: string;
  name: string
  duration: number;
  conferencingDetails: string;
  description: string;
  confirmedSlot?: Slot;
  embedType: string | null;
};

const EventDetails = (props: EventDetailsProps): JSX.Element => {

  const {
    organisationName,
    organisationLogo,
    name,
    duration,
    conferencingDetails,
    description,
    confirmedSlot,
    embedType,
    organisationId,
  } = props;

  const [avatarUrlBroken, setAvatarUrlBroken] = useState<boolean>(false);

  const styles = {
    wrapper: "",
    header: {
      base: "mb-6 text-center lg:text-left",
      org_name: "text-secondary-dark font-bold leading-6 capitalize",
      event_name: "text-primary-dark font-bold text-[26px] md:text-[28px] leading-8"
    },
    body: {
      base: "mb-2 lg:mb-6 hidden xsh:flex flex-col md:flex-row lg:flex-col items-start sm:items-center md:items-start justify-around",
      duration: {
        base: "flex items-center mb-3.5",
        icon: "w-[20px] h-[20px] text-secondary-dark",
        label: "text-secondary-dark font-bold ml-3"
      },
      conferencing: {
        base: "flex items-start mb-3.5",
        icon: "w-[25px] h-[25px] text-secondary-dark",
        label: "text-secondary-dark font-bold ml-3"
      },
      slot: {
        base: "flex items-start mb-3.5",
        icon: "w-[20px] h-[20px] text-secondary-dark",
        label: "text-secondary-dark font-bold ml-3"
      }
    },
    description: "text-primary-dark hidden xsh:block"
  };

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.header.base} ${embedType === EMBED_TYPE_POPUP && !confirmedSlot ? 'mb-0' : 'mb-6'}`}>
      {embedType === EMBED_TYPE_POPUP && !confirmedSlot ? [] : (
          <div className="flex gap-3 items-center mb-2 justify-center md:justify-start">
            {organisationId === 9 && (
              <img 
                src={LeadLogo}
                alt="orgImg"
                className="w-[50px] h-[50px]"
              />
            )}
            {organisationLogo && !avatarUrlBroken ? (
              <img 
                src={organisationLogo}
                onError={() => {
                  setAvatarUrlBroken(true);
                }}  
                alt="org-logo"
                className="h-10"
              />
            ) : (
              <div className={`${styles.header.org_name}`}>
                {organisationName}
              </div>
            )}
          </div>
        )}
        <div className={`${styles.header.event_name}`}>{name}</div>
      </div>
      <div className={`${styles.body.base}`}>
        {embedType === EMBED_TYPE_POPUP && !confirmedSlot ? [] : (
        <div className={`
          ${styles.body.duration.base} 
          ${confirmedSlot ? `flex` : `flex`}
        `}>
          <BsFillClockFill className={`${styles.body.duration.icon}`}/>
          <div className={`${styles.body.duration.label}`}>{formatDurationToHrsAndMins(duration)}</div>
        </div>
        )}
        {embedType === EMBED_TYPE_POPUP && !confirmedSlot ? [] : (
        <div className={`
          ${styles.body.conferencing.base} 
          ${confirmedSlot ? `hidden lg:flex` : `flex`}
        `}>
          <BsCameraVideoFill className={`${styles.body.conferencing.icon}`} />
          <div className={`${styles.body.conferencing.label}`}>{conferencingDetails}</div>
        </div>
        )}
        {confirmedSlot ? 
        <div className={`${styles.body.slot.base}`}>
          <BsCalendarEvent className={`${styles.body.slot.icon}`} />
          <div className={`${styles.body.slot.label}`}>
            {formattedSlot(confirmedSlot)}
          </div>
        </div>
        : <></>
        }
        {confirmedSlot ? 
        <div className={`${styles.body.slot.base}`}>
          <BiWorld className={`${styles.body.slot.icon}`} />
          <div className={`${styles.body.slot.label}`}>
            {confirmedSlot.timeZone} Time
          </div>
        </div>
        : <></>
        }
      </div>
      {!confirmedSlot ? 
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        /> : <></>
      }
    </div>
  );
};

export default EventDetails;
