import { API_BASE_URL } from "../utils/constants";
import HttpClient from "../utils/http-client";

type ISchedulePost = {
  event_id: number;
  time: string;
  timezone: string;
  answers: {
    [key: string]: string;
  };
  source_url: string;
  utm_flags?: Record<string, string>;
  slot_mode: string;
  instant_slot: boolean;
};

type IRealtimeSchedulePost = {
  realtime_event_id: number;
  answers: {
    [key: string]: string;
  };
  source_url: string;
  utm_flags?: Record<string, string>;
  guest_id?: string;
  timezone: string;
};

type ICancelSchedulePayload={
  cancellation_reason: string,
  booking_id: number,
  room_id: string,
  contact_name:string,
  booking_scheduled_at:string,
  organisation_name:string,
  alloted_user_email:string,
  booking_timezone:string,
  event_id:number,
  contact_email:string,
}

type IRescheduleBookingPayload={
  reschedule_reason: string,
  booking_id: number,
  room_id: string,
  contact_name: string,
  booking_scheduled_at: string,
  organisation_name: string,
  alloted_user_email: string,
  booking_timezone: string,
  event_id: number,
  contact_email: string,
  booking_rescheduled_at:string,
  slot_mode: string;
}

type ISchedulePayload = {
  start_date: string; 
  end_date: string; 
  event_id: number; 
  timezone: string; 
  submissions?: string
  booking_id?: string;
  source_url?: string;  
}

class Schedule {
  getSchedule = (data: ISchedulePayload) =>
    HttpClient.post(
      `${API_BASE_URL}/schedule`, data
    );

  getRescheduleSlots = (data: ISchedulePayload) => 
    HttpClient.post(
      `${API_BASE_URL}/schedule/slots/reschedule`, data
    );
    
  postSchedule = (data: ISchedulePost) =>
    HttpClient.post(`${API_BASE_URL}/v2/schedule`, data);
    
  postRealtimeSchedule = (data: IRealtimeSchedulePost) =>
    HttpClient.post(`${API_BASE_URL}/schedule/realtime`, data);

  cancelSchedule = (data: ICancelSchedulePayload)=>
    HttpClient.post(`${API_BASE_URL}/schedule/cancel`, data);
  
  rescheduleBooking = (data: IRescheduleBookingPayload)=>
    HttpClient.post(`${API_BASE_URL}/schedule/reschedule`, data);

}

const ScheduleService = new Schedule();
export default ScheduleService;
