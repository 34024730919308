import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { CheckCircle, X } from "react-feather";

export type SnackbarProps = {
  visible: boolean;
  label: string;
  type?: string;
  autoDismiss?: number | boolean;
  onClose: () => void;
  isRealtime: boolean;
};

const SnackBar = (props: SnackbarProps) => {
  const {
    visible,
    autoDismiss = 1500,
    type,
    label,
    onClose,
    isRealtime,
  } = props;
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (autoDismiss) {
      setTimeout(() => {
        setShow(false);
        onClose();
      }, Number(autoDismiss));
    }
  }, [autoDismiss]);

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  const getBg = () => {
    switch (type) {
      case "success":
        return "bg-app-green";
      case "error":
        return "bg-red-500";
      default:
        return "bg-black";
    }
  };

  return (
    <Transition
      show={show}
      enter="transition ease duration-200 transform"
      enterFrom="opacity-0 translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease duration-100 transform"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-full"
    >
      <div
        className={`${getBg()} w-full lg:w-64 fixed ${
          isRealtime ? "" : "bottom-0"
        } right-0 h-12 p-2 rounded-lg flex items-center text-white gap-2 m-4`}
      >
        <CheckCircle className="w-4 h-4 text-white" />
        <span className="text-sm leading-5">{label}</span>
        <button
          type="button"
          className="w-fit h-fit p-0.5 ml-auto"
          onClick={() => {
            setShow(false);
            onClose();
          }}
        >
          <X className="w-4 h-4 text-white " />
        </button>
      </div>
    </Transition>
  );
};

export default SnackBar;
