import { TRACKING_APP_URL } from "../utils/constants";
import HttpClient from "../utils/http-client";

export type TrackingEventPayload = {
    event_name: string;
    workspace_id: number;
    event_meta?: {[key: string]: any}
}

class Tracking {
  postTrackingEvents = (data: TrackingEventPayload) =>
    HttpClient.post(`${TRACKING_APP_URL}`, data);
}

const TrackingService = new Tracking();

export default TrackingService;
