import { format, endOfWeek, isSameDay, startOfWeek, addDays } from "date-fns";
import DateCell from "./date-cell";

export type DateGridProps = {
  week: Date;
  dateSelectability: Record<string, boolean>;
  selectedDate?: Date;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  onDateSelected(date: Date): any;
};

const DateGrid = (props: DateGridProps): JSX.Element => {
  const {
    week,
    dateSelectability,
    selectedDate,
    weekStartsOn,
    onDateSelected,
  } = props;

  const styles = {
    wrapper: {
      base: "w-full overflow-hidden pb-2",
    },
    row: {
      base: "flex justify-between w-full",
    },
  };

  const calendarStartDate = startOfWeek(week, { weekStartsOn });
  const calendarEndDate = endOfWeek(week, { weekStartsOn });
  let currentDate = calendarStartDate;
  let dayOfWeekItr = 0;

  const rows: JSX.Element[] = [];
  let days: JSX.Element[] = [];

  while (currentDate <= calendarEndDate) {
    days.push(
      <DateCell
        key={currentDate.toISOString()}
        date={currentDate}
        isVisible
        isSelected={
          selectedDate && isSameDay(currentDate, selectedDate) ? true : false
        }
        isSelectable={
          dateSelectability[format(currentDate, "yyyy-MM-dd")] || false
        }
        onSelect={onDateSelected}
      />
    );

    dayOfWeekItr++;
    currentDate = addDays(currentDate, 1);

    if (dayOfWeekItr === 7) {
      rows.push(
        <div key={currentDate.toISOString()} className={styles.row.base}>
          {days}
        </div>
      );
      days = [];
      dayOfWeekItr = 0;
    }
  }

  return (
    <div
      id="zipme-date-grid"
      className={`${styles.wrapper.base}`}
      onAnimationEnd={() => {
        const component = document.getElementById("zipme-date-grid");
        component?.classList?.remove("swipe-right");
        component?.classList?.remove("swipe-left");
      }}
    >
      {rows}
    </div>
  );
};

export default DateGrid;
