import { AiOutlineArrowLeft } from "react-icons/ai";

export type BackNavProps = {
  onClick(): any;
  buttonClass?: any;
  iconClass?: any;
};

const BackNav = (props: BackNavProps): JSX.Element => {
  return (
    <button
      type="button"
      className={`
       ${props?.buttonClass} w-6 md:w-10 h-6 md:h-10 bg-transparent rounded-full border border-secondary-dark 
        outline-none flex items-center justify-center 
        hover:bg-secondary-dark hover:bg-opacity-20
      `}
      onClick={props.onClick}
    >
      <AiOutlineArrowLeft
        className={`${props?.iconClass} w-4 h-4 text-secondary-dark `}
      />
    </button>
  );
};

export default BackNav;
