/* eslint-disable react-hooks/exhaustive-deps */
import {
  addDays,
  addMinutes,
  addWeeks,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isThisWeek,
  isValid,
  parseISO,
  startOfWeek
} from "date-fns";
import { createContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TimezoneSelect from "react-timezone-select";

import { formatInTimeZone, toDate } from "date-fns-tz";
import clockIcon from "../../assets/icons/clock.svg";
import MeetErrorImg from "../../assets/icons/meet-not-found.svg";
import MeetErrorUpdatedImg from "../../assets/images/meeterrorupdated.svg";
import BackNav from "../../components/back-nav";
import Confirmation from "../../components/confirmation";
import { Slot as EventSlot } from "../../components/event-details";
import Loader from "../../components/loader";
import ProfileImg from "../../components/profile-img";
import { Slot } from "../../components/schedule-list";
import SlotList from "../../components/slots";
import WeekCalendar from "../../components/weekCalendar";
import Layout from "../../hoc/layout";
import EventService from "../../services/event.service";
import ScheduleService from "../../services/schedule.service";
import ZipteamsService from "../../services/zipteams.service";
import { APP_BASE_URL } from "../../utils/constants";
import { Tracking } from "../../utils/tracking";

export const RescheduledContext = createContext({ themeColor: "" });

function ResheduleMeeting(): JSX.Element {
  const location = useLocation();
  const { pathname, search, state } = location;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [schedule, setSchedule] =
    useState<
      Record<string, { start_time: string; end_time: string; slots: number }[]>
    >();
  const [offlineSchedule, setOfflineSchedule] =
    useState<
      Record<string, { start_time: string; end_time: string; slots: number }[]>
    >();
  const [slots, setSlots] = useState<{ startTime: Date; endTime: Date }[]>();
  const [offlineSlots, setOfflineSlots] = useState<{ startTime: Date; endTime: Date }[]>();
  const [dateSelectability, setDateSelectability] = useState<
    Record<string, boolean>
  >({});
  const [selectedSlot, setSelectedSlot] = useState<Slot>();
  const [confirmedSlot, setConfirmedSlot] = useState<EventSlot>();
  const [scheduledSlot, setScheduledSlot] = useState<EventSlot>();
  const [schedulingFailed, setSchedulingFailed] = useState<boolean>();
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    (window && window?.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) ||
      "Asia/Calcutta"
  );

  const [eventId, setEventId] = useState<number>();
  const [eventName, setEventName] = useState<string>("");
  const [eventDuration, setEventDuration] = useState<number>(0);
  const [eventStartDate, setEventStartDate] = useState<Date>();
  const [eventEndDate, setEventEndDate] = useState<Date>();
  const [currentWeek, setCurrentWeek] = useState<Date>(new Date());
  const [slotPage, setSlotPage] = useState<number>(0);
  const [themeColor, setThemeColor] = useState<string>("#31667f");


  const [organisationName, setOrganisationName] = useState<string>("");

  const [meetLink, setMeetLink] = useState<URL>();
  const [userAvatar, setUserAvatar] = useState<string>("");

  const room_id = searchParams?.get("room");
  const booking_id = searchParams?.get("id");

  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [scheduledAt, setScheduledAt] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [contactEmail, setContactEmail] = useState<string>("");
  const [allotedUserEmail, setAllotedUserEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);
  const [siteLocation, setSiteLocation] = useState<string>('');
  const [offlineSlotsEnabled, setOfflineSlotsEnabled] = useState<boolean>(false);
  const [slotMode, setSlotMode] = useState<string>('online');
  const [allowRestartFlow, setAllowRestartFlow] = useState<boolean>(false);
  const [isScheduleLoading, setIsScheduleLoading] = useState<boolean>(true);
  const [isSlotsLoading, setIsSlotsLoading] = useState<boolean>(true);
  const [wpSubscriptionTier, setWpSubscriptionTier] = useState<string>('');

  const isFirstLoad = useRef<boolean>(true);
  const slotManuallyChanged = useRef<boolean>(false);
  const selectedDateRef = useRef<Date>();

  // const isSelectedMonthChanged = useRef<boolean>(false);
  // const isDateFirstTimeSelected = useRef<boolean>(false);

  const getEventData = async () => {
    setLoading(true);
    try {
      const response = room_id && (await ZipteamsService.roomDetails(room_id));
      if (response && response.data) {
        setAvatarUrl(response?.data?.data?.user?.avatar);
        setScheduledAt(response?.data?.data?.schedule?.schedule_at);
        setOrganisationName(response?.data?.data?.organisation?.name);
        setContactName(response?.data?.data?.contact?.contact_name);
        setAllotedUserEmail(response?.data?.data?.user?.email);
        setContactEmail(response?.data?.data?.contact?.contact_email);
      }
      const booking_response =
        booking_id &&
        (await EventService.getBookingDetails(Number(booking_id)));
      if (booking_response && !booking_response?.data) {
        setShowErrorScreen(true);
      }
      if (booking_response) {
        setEventId(booking_response?.data?.event_id);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowErrorScreen(true);
    }
  };

  useEffect(() => {
    getEventData();
  }, [room_id, booking_id]);

  const referrer =
    (state as { [key: string]: string })?.referrer ||
    searchParams.get("referrer");

  const App = (): JSX.Element => {
  const [rescheduleReason, setRescheduleReason] = useState<string>("");
    const handleRescheduleReson = (e: any) => {
      e.preventDefault();
      setRescheduleReason(e.target.value);
    };

    if(showErrorScreen){
      return (
        <div className="flex flex-col justify-between items-center my-auto">
          <img
            className={`mb-8 h-40 w-40 md:h-52 md:w-52`}
            src={MeetErrorImg}
            alt="meet-error"
          />
          <span
            className={`text-base mb-2 flex-1 text-center md:text-xl font-medium md:w-2/5 text-[#1c1d21]`}
          >
            Oops! This meeting is already rescheduled or cancelled. You may schedule the
            meeting again using the below link.
          </span>
          {allowRestartFlow && (
            <button
              type="button"
              onClick={() => {
                navigate(`/${params?.org}/${params?.slug}`);
              }}
              className={`bg-[#31667f]  font-bold text-center py-3 md:py-3.5 px-5 md:px-6 text-white text-sm rounded-md mt-4`}
            >
              Schedule Meeting
            </button>
          )}
          <p className="text-sm md:text-base absolute bottom-8">
            Schedule your own meeting on{" "}
            <a
              className="font-bold text-[#31667f]"
              href="https://app.zipteams.com"
              target="_blank"
              rel="noreferrer"
            >
              app.zipteams.com
            </a>
          </p>
        </div>
      );
    }

    return (
      <div
        className={`md:mt-8 lg:mt-16 md:mb-7 flex-auto flex justify-center md:max-h-[700px] relative h-full`}
      >
        <div className="flex flex-col w-full flex-auto items-center">
          {isLoading ? (
            <Loader />
          ) : eventId ? (
            <div className="h-full">
              <div className="flex flex-col justify-center w-full items-center">
                <h4 className="text-xl md:text-2xl font-bold break-all">
                  Reschedule Meeting - “{eventName}”
                </h4>
                <p className="text-lg mt-2 mb-5">
                  {`${
                    scheduledAt && format(parseISO(scheduledAt), "MMM dd, yyyy")
                  } | `}
                  {scheduledAt && format(parseISO(scheduledAt), "hh:mm a")}
                  <span className="text-[#1c1d21]">
                    <img
                      src={clockIcon}
                      className="inline-block w-3 md:w-4 ml-2 md:ml-3 mb-0.5 md:mb-1"
                      alt="clock"
                    />{" "}
                    {eventDuration} min
                  </span>
                </p>
              </div>
              <div
                className={`
                relative h-full border border-primary-dark items-center lg:items-stretch
                border-opacity-10 shadow-md rounded-3xl bg-white
                flex-auto flex flex-col lg:flex-row md:justify-end m-auto w-[340px] md:w-[500px] max-h-[520px] md:max-w-[500px] md:max-h-[550px]
                `}
              >
                {!scheduledSlot ? (
                  schedulingFailed ? (
                    <div
                      className={`flex-auto h-full max-w-[450px] mx-auto py-7 px-4 md:px-0 flex flex-col`}
                    >
                      <div className="mt-4 sm:mt-12">
                        <img
                          className={`m-auto`}
                          src={MeetErrorUpdatedImg}
                          alt="meet-error"
                          referrerPolicy="no-referrer"
                        />
                      </div>
                      <div className="mt-3 w-full flex-auto md:flex-none">
                        <div className="font-bold text-xl leading-6 text-center mx-auto w-[90%]">
                          Sorry! We are not able to schedule your meeting right
                          now.{" "}
                          {allowRestartFlow && (
                            <>
                              <a
                                href={
                                  new URL(
                                    `/${params.org}${
                                      params.slug ? `/${params.slug}` : ""
                                    }${search}`,
                                    APP_BASE_URL
                                  ).href
                                }
                              >
                                <span className="font-bold text-[#31667f] hover:underline">
                                  Click here
                                </span>
                              </a>{" "}
                              to try again.
                            </>
                          )}
                        </div>
                      </div>
                      <div className="text-secondary-dark text-sm leading-4 text-center mt-6">
                        Please get in touch with the host in case this issue
                        persists.
                      </div>
                    </div>
                  ) : (
                    <>
                      {confirmedSlot ? (
                        <div
                          className={`flex-auto flex pt-4 flex-col p-5 md:p-7 w-full items-center lg:items-start overflow-y-auto`}
                        >
                          <div
                            className={`w-full h-full max-w-xl lg:max-w-[500px] flex-auto flex flex-col mx-auto md:mx-0`}
                          >
                            <div className={`flex items-center relative`}>
                              <div
                                className={`flex mb-5 absolute top-0 left-0`}
                              >
                                <BackNav
                                  onClick={() => {
                                    navigate(
                                      `/${params.org}${
                                        params.slug ? `/${params.slug}/reschedule-meeting` : ""
                                      }${search}`,
                                    );
                                  }}
                                  buttonClass={`w-4 h-4 md:h-7 md:w-7`}
                                  iconClass={`w-3 h-3`}
                                />
                              </div>
                            </div>
                            <div
                              className={`h-full flex flex-auto justify-center lg:items-start`}
                            >
                              <div
                                className={`flex flex-col md:pr-3 w-[100%] lg:w-full lg:pl-4`}
                              >
                                <div className="flex flex-col flex-auto overflow-y-auto">
                                  <div className="flex gap-2 items-center mt-7 mb-4">
                                    <ProfileImg
                                      avatar={avatarUrl || ""}
                                      small
                                    />
                                    <div
                                      className={`w-auto font-bold text-xl leading-6 ml-3 md:ml-4`}
                                    >
                                      <span>
                                        Please confirm your meeting on{" "}
                                        {`${
                                          confirmedSlot?.startTime &&
                                          format(
                                            confirmedSlot?.startTime,
                                            "MMM dd, yyyy"
                                          )
                                        } | `}
                                        {scheduledAt &&
                                          format(
                                            confirmedSlot?.startTime,
                                            "hh:mm a"
                                          )} (IST)
                                      </span>
                                    </div>
                                  </div>
                                  <div className={``}>
                                    <div>
                                      <h5 className="text-sm md:text-base font-bold">
                                        Reason for rescheduling
                                      </h5>
                                      <textarea
                                        className="w-full resize-none rounded-lg border-[#d7d7d7] border-[1px] h-[100px]"
                                        name="rescheduleReason"
                                        value={rescheduleReason}
                                        onChange={handleRescheduleReson}
                                      />
                                    </div>
                                  </div>
                                  <>
                                    <div className="py-3 md:py-4 mt-40">
                                      <button
                                        type="button"
                                        className={` ${loading?"bg-secondary-dark":"bg-secondary"} mx-auto cursor-pointer rounded-2xl w-[250px] text-base leading-5 font-bold py-3 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none`}
                                        disabled={loading}
                                        onClick={async () => {
                                          try {
                                            setLoading(true)
                                            const response =
                                              await ScheduleService.rescheduleBooking(
                                                {
                                                  event_id: Number(eventId),
                                                  booking_timezone:
                                                    selectedTimeZone,
                                                  alloted_user_email:
                                                    allotedUserEmail,
                                                  booking_id:
                                                    Number(booking_id),
                                                  booking_scheduled_at:
                                                    scheduledAt,
                                                  booking_rescheduled_at:
                                                    new Date(
                                                      confirmedSlot?.startTime
                                                    ).toISOString(),
                                                  reschedule_reason:
                                                    rescheduleReason,
                                                  contact_email: contactEmail,
                                                  contact_name: contactName,
                                                  organisation_name:
                                                    organisationName,
                                                  room_id: String(room_id),
                                                  slot_mode: slotMode
                                                }
                                              );

                                            const { url, location } = response?.data;
                                            setScheduledSlot(confirmedSlot);
                                            setMeetLink(new URL(url));
                                            setSiteLocation(location);
                                            setLoading(false)
                                          } catch (err) {
                                            setLoading(false);
                                            setSchedulingFailed(true);
                                          }
                                        }}
                                      >
                                        <>Reschedule meeting</>
                                      </button>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`flex flex-col w-full h-full`}>
                          <div
                            className={`flex-auto flex flex-col md:flex-row pt-4 p-5`}
                          >
                            <div
                              className={`flex flex-col flex-auto w-full `}
                            >
                              <div className="flex gap-2 items-center">
                                <ProfileImg
                                  avatar={avatarUrl || userAvatar}
                                  small
                                />
                                <div
                                  className={`w-auto font-bold text-xl leading-6 ml-3 md:ml-4`}
                                >
                                  <span>
                                    Please select an alternate date and time
                                    that works best for you?
                                  </span>
                                </div>
                              </div>
                              <div className={`mb-[18px]`}>
                                <WeekCalendar
                                  month={new Date()}
                                  currentWeek={currentWeek}
                                  dateSelectability={dateSelectability}
                                  selectedDate={selectedDate}
                                  startDate={eventStartDate}
                                  endDate={eventEndDate}
                                  weekStartsOn={1}
                                  onMonthChange={(date: Date) => {
                                    // navigate(
                                    //   `${pathname}${searchSet(
                                    //     search,
                                    //     "month",
                                    //     format(date, "yyyy-MM")
                                    //   )}`,
                                    //   { replace: true }
                                    // );
                                  }}
                                  onWeekChange={(weekStartDate: Date) => {
                                    setCurrentWeek(weekStartDate);
                                  }}
                                  onDateSelected={(date: Date) => {
                                    setSelectedDate(date);
                                    setSlotPage(0);
                                    selectedDateRef.current = date;
                                    slotManuallyChanged.current = false;
                                    // const formattedMonth = format(
                                    //   date,
                                    //   "yyyy-MM"
                                    // );
                                    // const formattedDate = format(
                                    //   date,
                                    //   "yyyy-MM-dd"
                                    // );
                                    // let params = searchSet(
                                    //   search,
                                    //   "month",
                                    //   formattedMonth
                                    // );
                                    // params = searchSet(
                                    //   params,
                                    //   "date",
                                    //   formattedDate
                                    // );
                                    // navigate(`${pathname}${params}`, {
                                    //   replace: true,
                                    //   state: { referrer },
                                    // });
                                  }}
                                  scheduleLoading={isScheduleLoading}
                                />
                              </div>
                              <div className="mb-4 md:max-w-[400px]">
                                <SlotList
                                  title={offlineSlotsEnabled ? 'Video counselling' : 'Time Slots'}
                                  slotLines={offlineSlotsEnabled ? 1 : 2}
                                  embedType={''}
                                  timeZone={selectedTimeZone}
                                  slots={slots ? slots : []}
                                  selectedSlot={slotMode === 'online' ? selectedSlot : undefined}
                                  currentPage={slotPage}
                                  changecurrentPage={(page: number) => {
                                    setSlotPage(page);
                                  }}
                                  onSlotSelected={(slot) => {
                                    slotManuallyChanged.current = true;
                                    setSlotMode('online');
                                    setSelectedSlot({
                                      startTime: slot.startTime,
                                      endTime: slot.endTime,
                                    });
                                    Tracking.eventSlotSelected(
                                      formatInTimeZone(
                                        slot.startTime,
                                        selectedTimeZone,
                                        "yyyy-MM-dd'T'HH:mm:ssxxx"
                                      )
                                    );
                                  }}
                                  slotsLoading={isSlotsLoading || isScheduleLoading}
                                />
                                {/* {selectedDate ? (
                                  <></>
                                ) : (
                                  <div>
                                    <div className="text-base leading-5 font-bold text-primary-dark mb-3 underline">
                                      {offlineSlotsEnabled ? 'Online Slots' : 'Time Slots'}
                                    </div>
                                    <SlotsLoader />
                                  </div>
                                )} */}
                              </div>
                              {offlineSlotsEnabled && (
                                <div className="mb-4 md:max-w-[400px]">
                                  <SlotList
                                    title="In centre counselling"
                                    slotLines={offlineSlotsEnabled ? 1 : 2}
                                    embedType={''}
                                    timeZone={selectedTimeZone}
                                    slots={offlineSlots ? offlineSlots : []}
                                    selectedSlot={slotMode === 'offline' ? selectedSlot : undefined}
                                    currentPage={slotPage}
                                    changecurrentPage={(page: number) => {
                                      setSlotPage(page);
                                    }}
                                    onSlotSelected={(slot) => {
                                      slotManuallyChanged.current = true;
                                      setSlotMode('offline');
                                      setSelectedSlot({
                                        startTime: slot.startTime,
                                        endTime: slot.endTime,
                                      });
                                      Tracking.eventSlotSelected(
                                        formatInTimeZone(
                                          slot.startTime,
                                          selectedTimeZone,
                                          "yyyy-MM-dd'T'HH:mm:ssxxx"
                                        )
                                      );
                                    }}
                                    slotsLoading={isSlotsLoading || isScheduleLoading}
                                  />
                                  {/* {selectedDate ? (
                                    <></>
                                  ) : (
                                    <div>
                                      <div className="text-base leading-5 font-bold text-primary-dark mb-3 underline">
                                      In Centre Counselling
                                      </div>
                                      <SlotsLoader />
                                    </div>
                                  )} */}
                                </div>
                              )}
                              <div className="mb-4">
                                <TimezoneSelect
                                  value={selectedTimeZone}
                                  labelStyle="original"
                                  onChange={(timezone) => {
                                    setSelectedTimeZone(timezone.value);
                                  }}
                                />
                              </div>
                              <div className="mt-auto">
                                <button
                                  type="button"
                                  className="mx-auto cursor-pointer bg-secondary rounded-2xl w-[250px] text-base leading-5 font-bold py-3.5 flex items-center justify-center text-white disabled:bg-opacity-60 disabled:pointer-events-none"
                                  disabled={!selectedDate || !selectedSlot}
                                  onClick={onSlotConfirmed}
                                >
                                  Reschedule meeting
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )
                ) : (
                  <div
                    className={` flex flex-col w-full items-center`}
                  >
                    <Confirmation
                      avatar={userAvatar}
                      organisationName={organisationName}
                      eventName={eventName}
                      meetURL={meetLink}
                      location={siteLocation || ''}
                      scheduledSlot={scheduledSlot}
                      embedType={""}
                      allowRestartFlow={allowRestartFlow}
                      onRestartFlow={() =>
                        (window.location.href = new URL(
                          `/${params.org}${
                            params.slug ? `/${params.slug}` : ""
                          }${search}`,
                          APP_BASE_URL
                        ).href)
                      }
                      rescheduleText
                    />
                  </div>
                )}
                {!isLoading && scheduledSlot && wpSubscriptionTier !== 'enterprise_tier' && <div className="absolute bottom-0 right-0 text-xs p-2 px-4 leading-4 flex items-center gap-[3px]">Powered by <a className="font-fjalla text-[#31667F] mt-[1px]" href="https://zipteams.com/" target="_blank" rel="noreferrer">Zipteams</a></div>}
              </div>
            </div>
          ) : (
            <div
              className={`w-full md:border md:border-primary-dark items-center
            md:border-opacity-10 md:shadow-md md:rounded-lg bg-white p-6 text-center
            flex-auto flex flex-col justify-center md:max-w-[80%] lg:max-w-[800px]`}
            >
              <img className={`mb-8`} src={MeetErrorImg} alt="meet-error" />
              <span className={`text-2xl font-bold mb-2`}>
                Oops! It seems this link is not available.
              </span>
              <span className={`text-secondary-dark font-bold`}>
                It looks like this scheduling link is incorrect or made inactive
                by the host.
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const loadEventData = async (
  ) => {
    setIsLoading(true);
    try {
      const allowrestartflow =
        searchParams.get("allowrestartflow") ||
        (state as { [key: string]: string })?.allowrestartflow;

      if (allowrestartflow && allowrestartflow === 'false' ) {
        setAllowRestartFlow(false);
      }

      const response = await EventService.getEvent(
        `/${params.org}${params.slug ? `/${params.slug}` : ""}`,""
      );
      const {
        event,
        organisation,
        available_users,
        defaultWidgetAvatar,
        selected_user,
      } = response?.data;

      setEventId(event?.id);
      setEventName(event?.name);
      setEventDuration(event?.duration);
      setEventStartDate(new Date(event?.start_date));

      const themecolor =
        searchParams.get("themecolor") ||
        (state as { [key: string]: string })?.themecolor;

      setThemeColor(event?.configs?.widget_color || themecolor || "#31667f");
      setOfflineSlotsEnabled(event?.configs?.offline_enabled || false);
      // setAppThemeColor(event?.configs?.widget_color || themecolor || "#31667f");

      if (event?.end_date) setEventEndDate(new Date(event?.end_date));

      setUserAvatar(
        (selected_user && selected_user?.avatar_url) ||
          available_users?.[0]?.avatar_url ||
          defaultWidgetAvatar ||
          ""
      );

      setOrganisationName(organisation?.name);
      setWpSubscriptionTier(organisation?.workspace?.subscription_tier || 'free_tier');
      isFirstLoad.current = true;
      await fetchSchedulesandSlots(
        event?.id, 
        selectedTimeZone, 
        event?.configs?.offline_enabled || false
      );
      isFirstLoad.current = false;
    } catch (error) {
      console.error("loadEventData", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSlotConfirmed = () => {
    if (!selectedSlot?.startTime) return;
    const startTime = format(
      selectedSlot?.startTime,
      "yyyy-MM-dd'T'HH:mm:ssxxx"
    );
    navigate(`${pathname}/${startTime}${search}`, {
      replace: false,
      state: { referrer },
    });
  };

  const isNormalWorkingHours = (date: string) => {
    if (isValid(new Date(date))) {
      const time = formatInTimeZone(isValid(new Date(date)) ? new Date(date) : new Date(), selectedTimeZone, 'HH');
      if (Number(time) >= 8 && Number(time) <= 20) {
        return true
      }
      return false
    }
    return false;
  }

  const getFirstSlot = (slotsArr: any[]) => {
    let firstSlot: any = {};
    if (!(slotsArr?.[0]?.start_time && isNormalWorkingHours(slotsArr?.[0]?.start_time))) {
      const workingHourSlots = slotsArr.filter((date: any) => isNormalWorkingHours(date?.start_time));
      if (workingHourSlots && workingHourSlots?.length) {
        firstSlot = workingHourSlots?.[0];
      } else {
        firstSlot = slotsArr?.[0];
      }
    }
    else {
      firstSlot = slotsArr?.[0];
    }
    return firstSlot;
  }

  const fetchSchedulesandSlots = async (id: number, tz: string, offlineSlotsAllowed: boolean) => {
    try {
      let currWeek = toDate(currentWeek, { timeZone: selectedTimeZone });
      
      const startDate = toDate(isValid(currWeek) ? startOfWeek(currWeek, { weekStartsOn: 1 }) : new Date(), { timeZone: selectedTimeZone });
      const endDate = toDate(isValid(currWeek) ? endOfWeek(currWeek, { weekStartsOn: 1 }) : new Date(), { timeZone: selectedTimeZone }); 
      setIsScheduleLoading(true);
      const result = await ScheduleService.getRescheduleSlots({
        start_date: formatInTimeZone(isValid(startDate) ? startDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
        end_date: formatInTimeZone(isValid(endDate) ? endDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd"),
        event_id: id,
        timezone: encodeURIComponent(tz || 'Asia/Calcutta'),
        booking_id: booking_id || ''
      });
      updateSchedules(result?.data?.schedules, startDate, endDate, offlineSlotsAllowed);
      updateSlots(result?.data?.schedules, offlineSlotsAllowed); 
    } catch (err) {
      console.log(err);
    }
  }

  const updateSchedules = (schedules: any, startDate: Date, endDate: Date, offlineSlotsAllowed: boolean) => {
    setIsScheduleLoading(true);
    try {

      if (offlineSlotsAllowed) {
        setSchedule(schedules?.online || []);
        setOfflineSchedule(schedules?.offline || []);
      } else {
        setSchedule(schedules || []);
      }
  
      let selectability: { [key:string]: boolean } = {};
      let dateNotSelectableInThisWeekCounter: number = 0;
      let startDateCpy: Date = startDate; 
      let selectabilitySchedules;
      if (offlineSlotsAllowed) {
        const resultSchedules: any = schedules;
        const online = schedules?.online;
        const offline = schedules?.offline;
        
        if (slotMode && resultSchedules[slotMode] && Object.keys(resultSchedules[slotMode])?.length){
          setSlotMode(slotMode);
          selectabilitySchedules = resultSchedules[slotMode];
        } else {
          if (slotMode === 'offline' && online && Object.keys(online)?.length) {
            setSlotMode('offline');
            selectabilitySchedules = online;
          }
          if (slotMode === 'online' && offline && Object.keys(offline)?.length) {
            setSlotMode('offline');
            selectabilitySchedules = offline;
          }
        }
        const selectedDateNew = selectedDateRef.current && isValid(selectedDateRef.current) ? selectedDateRef.current : new Date();

        const date = selectedDateNew && !isAfter(startDate, selectedDateNew) ? selectedDateNew : (selectabilitySchedules && Object.keys(selectabilitySchedules)?.[0]);
        if (date) {
          setSelectedDate(toDate(isValid(new Date(date)) ? new Date(date) : new Date(), { timeZone: selectedTimeZone }));
          selectedDateRef.current = toDate(isValid(new Date(date)) ? new Date(date) : new Date(), { timeZone: selectedTimeZone });
        }
      } else {
        selectabilitySchedules = schedules;
        const date = selectedDateRef.current || (selectabilitySchedules && Object.keys(selectabilitySchedules)?.[0]);
        if (date) {
          setSelectedDate(toDate(isValid(new Date(date)) ? new Date(date) : new Date(), { timeZone: selectedTimeZone }));
          selectedDateRef.current = toDate(isValid(new Date(date)) ? new Date(date) : new Date(), { timeZone: selectedTimeZone});
        }
      }
      while (isSameDay(startDateCpy, endDate) || isBefore(startDateCpy, endDate)) {
        const date = formatInTimeZone(startDateCpy, selectedTimeZone, "yyyy-MM-dd");
        if (selectabilitySchedules && selectabilitySchedules?.[date]) {
          selectability[date] = true;
        } else {
          if (isThisWeek(startDateCpy, { weekStartsOn: 1 })) {
            dateNotSelectableInThisWeekCounter++;
          }
          selectability[date] = false;
        }
        startDateCpy = addDays(startDateCpy, 1);
      }
      setDateSelectability(selectability);
      if (dateNotSelectableInThisWeekCounter >= 7) {
        const newWeek = addWeeks(startOfWeek(startDate, { weekStartsOn: 1 }), 1)
        setCurrentWeek(newWeek);
        return;
      }
      setIsScheduleLoading(false);
    } catch (err) {
      setIsScheduleLoading(false);
      console.log(err);
    }
  }

  const updateSlots = (schedules: any, offlineSlotsAllowed: boolean, selectFirstSlot: boolean = true) => {
    setIsSlotsLoading(true);
    try {
      const selDate = selectedDateRef.current;
      if (offlineSlotsAllowed) {
        const date = formatInTimeZone(
          selDate && isValid(selDate) ? selDate : new Date(), 
          selectedTimeZone,
          "yyyy-MM-dd"
        );
        const slots = schedules?.online[date] || []; 
        const offlineSlots = schedules?.offline[date] || [];
        setSlots(
          (slots && slots?.map((slot: any) => ({
            startTime: new Date(slot.start_time),
            endTime: new Date(slot.end_time),
          }))) || []
        );
        setOfflineSlots(
          (offlineSlots && offlineSlots?.map((slot: any) => ({
            startTime: new Date(slot.start_time),
            endTime: new Date(slot.end_time),
          }))) || []
        )
        if (slotMode === 'offline') {
          const firstSlot = selectFirstSlot ? 
            getFirstSlot(offlineSlots) : 
            { start_time: selectedSlot?.startTime, end_time: selectedSlot?.endTime };
          if (firstSlot) {
            setSelectedSlot({
              startTime: new Date(firstSlot?.start_time),
              endTime: new Date(firstSlot?.end_time),
            })
          }
        } else {
          const firstSlot = selectFirstSlot ? 
            getFirstSlot(slots) : 
            { start_time: selectedSlot?.startTime, end_time: selectedSlot?.endTime };
          if (firstSlot) {
            setSelectedSlot({
              startTime: new Date(firstSlot?.start_time),
              endTime: new Date(firstSlot?.end_time),
            })
          }
        }
      } else {
        const date = formatInTimeZone(
          selDate && isValid(selDate) ? selDate : new Date(), 
          selectedTimeZone,
          "yyyy-MM-dd"
        );
        const slots =
          schedules[date] || [];
  
        setSlots(
          (slots && slots?.map((slot: any) => ({
            startTime: new Date(slot.start_time),
            endTime: new Date(slot.end_time),
          }))) || []
        );
        const firstSlot = selectFirstSlot ? 
          getFirstSlot(slots) : 
          { start_time: selectedSlot?.startTime, end_time: selectedSlot?.endTime };
        if (firstSlot) {
          setSelectedSlot({
            startTime: new Date(firstSlot?.start_time),
            endTime: new Date(firstSlot?.end_time),
          })
        }
      }
      setIsSlotsLoading(false);
    } catch (err) {
      setIsSlotsLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    if (!params.date) {
      setConfirmedSlot(undefined);
    }
    if (params.date && eventDuration) {
      const slot = {
        startTime: new Date(params.date),
        endTime: addMinutes(new Date(params.date), eventDuration),
        timeZone: selectedTimeZone,
      };
      setConfirmedSlot(slot);
    }
  }, [params.date, eventDuration]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      selectedDateRef.current = undefined;
      fetchSchedulesandSlots(
        eventId!,
        selectedTimeZone || 'Asia/Calcutta', 
        offlineSlotsEnabled || false
      );
    }
  }, [currentWeek, selectedTimeZone]);

  useEffect(() => {
    if (!isFirstLoad.current && !slotManuallyChanged.current) {
      updateSlots(
        offlineSlotsEnabled ? { online: schedule, offline: offlineSchedule } : schedule, 
        offlineSlotsEnabled
      )
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!isScheduleLoading) {
      updateSchedules(
        offlineSlotsEnabled ? { online: schedule, offline: offlineSchedule } : schedule, 
        startOfWeek(currentWeek, { weekStartsOn: 1 }),
        endOfWeek(currentWeek, { weekStartsOn: 1 }),
        offlineSlotsEnabled
      );
      updateSlots(
        offlineSlotsEnabled ? { online: schedule, offline: offlineSchedule } : schedule,
        offlineSlotsEnabled,
        !slotManuallyChanged.current
      );
    }
  }, [slotMode]);

  // useEffect(() => {
  //   if (!searchParams.get("month")) {
  //     navigate(
  //       `${pathname}${searchSet(
  //         search,
  //         "month",
  //         format(new Date(), "yyyy-MM")
  //       )}`,
  //       { replace: true, state: { referrer } }
  //     );
  //     return;
  //   }

  //   if (
  //     !isSelectedMonthChanged.current &&
  //     searchParams.get("month") &&
  //     !isThisMonth(new Date(searchParams.get("month") || ""))
  //   ) {
  //     navigate(
  //       `${pathname}${searchSet(
  //         search,
  //         "month",
  //         format(new Date(), "yyyy-MM")
  //       )}`,
  //       { replace: true, state: { referrer } }
  //     );
  //     isSelectedMonthChanged.current = true;
  //     return;
  //   }

  //   if (
  //     (!selectedDate && searchParams.get("date")) ||
  //     (selectedDate &&
  //       !isSameDay(selectedDate, new Date(String(searchParams.get("date")))))
  //   ) {
  //     setSelectedDate(new Date(String(searchParams.get("date"))));
  //     Tracking.eventDateSelected(String(searchParams.get("date")));
  //   }

  //   if (
  //     (!selectedMonth && searchParams.get("month")) ||
  //     (selectedMonth &&
  //       !isSameMonth(
  //         selectedMonth,
  //         new Date(String(searchParams.get("month")))
  //       ))
  //   ) {
  //     setSelectedMonth(new Date(String(searchParams.get("month"))));
  //   }
  // }, [location]);

  // useEffect(() => {
  //   try {
  //     if (
  //       selectedMonth &&
  //       selectedMonth instanceof Date &&
  //       selectedTimeZone &&
  //       eventId
  //     ) {
  //       (async () => {
  //         let startDate = startOfMonth(
  //           subMonths(isValid(selectedMonth) ? selectedMonth : new Date(), 1)
  //         );
  //         const endDate = endOfMonth(addMonths(isValid(selectedMonth) ? selectedMonth : new Date(), 1));
  //         setIsScheduleLoading(true);
  //         const result = await ScheduleService.getRescheduleSlots({
  //           start_date: formatInTimeZone(
  //             isValid(startDate) ? startDate : new Date(),
  //             selectedTimeZone || "Asia/Calcutta",
  //             "yyyy-MM-dd"
  //           ),
  //           end_date: formatInTimeZone(
  //             isValid(endDate) ? endDate : new Date(),
  //             selectedTimeZone || "Asia/Calcutta",
  //             "yyyy-MM-dd"
  //           ),
  //           event_id: eventId,
  //           timezone: encodeURIComponent(selectedTimeZone || "Asia/Calcutta"),
  //           booking_id: booking_id || '', 
  //         });

  //         if (offlineSlotsEnabled) {
  //           setSchedule(result?.data?.schedules?.online);
  //           setOfflineSchedule(result?.data?.schedules?.offline);
  //         } else {
  //           setSchedule(result?.data?.schedules);
  //         }

  //         let selectability: Record<string, boolean> = {};
  //         let dateNotSelectableInThisWeekCounter: number = 0;
  //         while (
  //           isSameDay(startDate, endDate) ||
  //           isBefore(startDate, endDate)
  //         ) {
  //           const date = format(startDate, "yyyy-MM-dd");
  //           if (offlineSlotsEnabled) {
  //             const online = result?.data?.schedules?.online;
  //             const offline = result?.data?.schedules?.offline;
  //             let schedules;
  //             if (slotMode === 'offline') {
  //               if (offline && Object.keys(offline)?.length) {
  //                 schedules = offline;
  //               }
  //               if (online && Object.keys(online)?.length) {
  //                 schedules = online;
  //               }
  //             }  
  //             if (slotMode === 'online')  {
  //               if (online && Object.keys(online)?.length) {
  //                 schedules = online;
  //               }
  //               if (offline && Object.keys(offline)?.length) {
  //                 schedules = offline;
  //               }
  //             }
  //             if (schedules && schedules?.[date]) {
  //               selectability[date] = true;
  //             } else {
  //               if (isThisWeek(startDate, {weekStartsOn: 1}) && isThisMonth(selectedMonth)) {
  //                 dateNotSelectableInThisWeekCounter++;
  //               }
  //               selectability[date] = false;
  //             }
  //           } else {
  //             const schedules = result?.data?.schedules;
  //             if (schedules && schedules?.[date]) {
  //               selectability[date] = true;
  //             } else {
  //               if (isThisWeek(startDate, {weekStartsOn: 1}) && isThisMonth(selectedMonth)) {
  //                 dateNotSelectableInThisWeekCounter++;
  //               }
  //               selectability[date] = false;
  //             }
  //           }
  //           startDate = addDays(startDate, 1);
  //         }
  //         if (dateNotSelectableInThisWeekCounter >= 7) {
  //           const newWeek = addWeeks(startOfWeek(currentWeek, {weekStartsOn: 1}), 1)
  //           if (!isThisMonth(newWeek)) {
  //             isSelectedMonthChanged.current = true;
  //             const newMonth = startOfMonth(newWeek);
  //             const formattedMonth = format(
  //               toDate(newMonth),
  //               "yyyy-MM"
  //             );
  //             let params = searchSet(
  //               search,
  //               "month",
  //               formattedMonth
  //             );
  //             navigate(`${pathname}${params}`, {
  //               replace: true,
  //               state: { referrer },
  //             });
  //             setCurrentWeek(newWeek);
  //             return;
  //           }
  //           setCurrentWeek(newWeek);
  //         }
  //         setDateSelectability(selectability);

  //         const online = result?.data?.schedules?.online;
  //         const offline = result?.data?.schedules?.offline;
  //         let schedules;
  //         if (offlineSlotsEnabled) {
  //           if (slotMode === 'offline') {
  //             if (offline && Object.keys(offline)?.length) {
  //               setSlotMode('offline');
  //               schedules = offline;
  //             }
  //             if (online && Object.keys(online)?.length) {
  //               setSlotMode('online');
  //               schedules = online;
  //             }
  //           }  
  //           if (slotMode === 'online')  {
  //             if (offline && Object.keys(offline)?.length) {
  //               setSlotMode('offline');
  //               schedules = offline;
  //             }
  //             if (online && Object.keys(online)?.length) {
  //               setSlotMode('online');
  //               schedules = online;
  //             }
  //           }
  //         } else {
  //           schedules = result?.data?.schedules;
  //         }
          
  //         if (!isDateFirstTimeSelected.current && schedules && Object.keys(schedules)?.length) {
  //           const firstAvailableDate = Object.keys(schedules)?.[0];
  //           const formattedMonth = format(
  //             new Date(firstAvailableDate),
  //             "yyyy-MM"
  //           );
  //           const formattedDate = format(
  //             new Date(firstAvailableDate),
  //             "yyyy-MM-dd"
  //           );
  //           let params = searchSet(search, "month", formattedMonth);
  //           params = searchSet(params, "date", formattedDate);
  //           navigate(`${pathname}${params}`, {
  //             replace: true,
  //             state: { referrer },
  //           });
  //           isDateFirstTimeSelected.current = true;
  //         }
  //         setIsScheduleLoading(false);
  //       })();
  //     }
  //   } catch (err) {
  //     setIsScheduleLoading(false);
  //     console.log(err);
  //   }
  // }, [selectedMonth, selectedTimeZone, eventId]);

  // useEffect(() => {
  //   try {
  //     if (
  //       selectedDate &&
  //       selectedDate instanceof Date &&
  //       selectedMonth &&
  //       selectedMonth instanceof Date &&
  //       eventId
  //     ) {
  //       if ((schedule || offlineSchedule) && isSameMonth(selectedDate, selectedMonth)) {
  //         let slots: any = [];
  //         let offlineSlots: any = [];
  //         if (offlineSlotsEnabled) {
  //           offlineSlots = offlineSchedule?.[
  //             formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //           ]
  //           slots = schedule?.[
  //             formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //           ];
  //           setOfflineSlots(
  //             (offlineSlots && offlineSlots?.map((slot: any) => ({
  //               startTime: new Date(slot.start_time),
  //               endTime: new Date(slot.end_time),
  //             }))) || []
  //           )
  //           setSlots(
  //             (slots && slots?.map((slot: any) => ({
  //               startTime: new Date(slot.start_time),
  //               endTime: new Date(slot.end_time),
  //             }))) || []
  //           );
  //         } else {
  //           slots = schedule?.[
  //             formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //           ];
  //           setSlots(
  //             (slots && slots?.map((slot: any) => ({
  //               startTime: new Date(slot.start_time),
  //               endTime: new Date(slot.end_time),
  //             }))) || []
  //           );
  //         } 
            
  //         if (slotMode === 'offline') {
  //           setSelectedSlot({
  //             startTime: new Date(offlineSlots?.[0]?.start_time),
  //             endTime: new Date(offlineSlots?.[0]?.end_time),
  //           });
  //         } else {
  //           setSelectedSlot({
  //             startTime: new Date(slots?.[0]?.start_time),
  //             endTime: new Date(slots?.[0]?.end_time),
  //           })
  //         }
  //       } else {
  //         (async () => {
  //           let startDate = startOfMonth(isValid(selectedDate) ? selectedDate : new Date());
  //           const endDate = endOfMonth(isValid(selectedDate) ? selectedDate : new Date());
  //           setIsSlotsLoading(true);
  //           const result = await ScheduleService.getRescheduleSlots({
  //             start_date: formatInTimeZone(
  //               isValid(startDate) ? startDate : new Date(),
  //               selectedTimeZone || "Asia/Calcutta",
  //               "yyyy-MM-dd"
  //             ),
  //             end_date: formatInTimeZone(
  //               isValid(endDate) ? endDate : new Date(),
  //               selectedTimeZone || "Asia/Calcutta",
  //               "yyyy-MM-dd"
  //             ),
  //             event_id: eventId,
  //             timezone: encodeURIComponent(selectedTimeZone || "Asia/Calcutta"),
  //             booking_id: booking_id || '',
  //           });
  //           setIsSlotsLoading(false);
  //           if (offlineSlotsEnabled) {
  //             const slots = result?.data?.schedules?.online[
  //               formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //             ]; 
  //             const offlineSlots = result?.data?.schedules?.offline[
  //               formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //             ];
  //             setSlots(
  //               (slots && slots?.map((slot: any) => ({
  //                 startTime: new Date(slot.start_time),
  //                 endTime: new Date(slot.end_time),
  //               }))) || []
  //             );
  //             setOfflineSlots(
  //               (offlineSlots && offlineSlots?.map((slot: any) => ({
  //                 startTime: new Date(slot.start_time),
  //                 endTime: new Date(slot.end_time),
  //               }))) || []
  //             )
  //             if (slotMode === 'offline') {
  //               setSelectedSlot({
  //                 startTime: new Date(offlineSlots?.[0]?.start_time),
  //                 endTime: new Date(offlineSlots?.[0]?.end_time),
  //               })
  //             } else {
  //               setSelectedSlot({
  //                 startTime: new Date(slots?.[0]?.start_time),
  //                 endTime: new Date(slots?.[0]?.end_time),
  //               })
  //             }
  //           } else {
  //             const slots =
  //               result?.data?.schedules[
  //                 formatInTimeZone(isValid(selectedDate) ? selectedDate : new Date(), selectedTimeZone || 'Asia/Calcutta', "yyyy-MM-dd")
  //               ];
  //             setSlots(
  //               (slots && slots?.map((slot: any) => ({
  //                 startTime: new Date(slot.start_time),
  //                 endTime: new Date(slot.end_time),
  //               }))) || []
  //             );
  //             setSelectedSlot({
  //               startTime: new Date(slots?.[0]?.start_time),
  //               endTime: new Date(slots?.[0]?.end_time),
  //             })
  //           }
  //           setIsSlotsLoading(false);
  //         })();
  //       }
  //     }
  //   } catch (err) {
  //     setIsSlotsLoading(false);
  //     console.log(err);
  //   }
  // }, [selectedDate, eventId]);

  useEffect(() => {
    loadEventData();
  }, []);

  return (
    <Layout>
      <div className={`flex flex-col h-screen md:min-h-screen ${showErrorScreen? "bg-white": "bg-[#31667f]/10"} `}>
        <RescheduledContext.Provider value={{ themeColor }}>
          <App />
        </RescheduledContext.Provider>
      </div>
    </Layout>
  );
}

export default ResheduleMeeting;
