import React from "react";
import styles from "./common.module.css";

const Loader: React.FC = () => (
  <div className={styles["loader-container"]}>
    <div className={styles["lds-spinner"]}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
