import { API_BASE_URL } from "../utils/constants";
import HttpClient from "../utils/http-client";

export type ScheduleExceptionPayload = {
  day?: number;
  date?: Date;
  start_time: string;
  end_time: string;
};

export type ScheduleHolidaysPayload = {
  day?: number;
  date?: Date;
  holiday_date: string;
  holiday_name: string;
};

export type QuestionPayload = {
  type:
  | "zt_contact_name"
  | "zt_contact_email"
  | "zt_customer_name"
  | "zt_contact_phone_number"
  | "single_line"
  | "multi_line"
  | "radio_button"
  | "phone_number"
  | "email"
  | "disclaimer";
  default_value?: string;
  is_required: boolean;
  label: string;
  options?: { label: string }[] | null;
};

export type EventPayload = {
  name: string;
  description?: string;
  branding_color: string;
  room_provider: string;
  calendar_filter: boolean;
  duration: number;
  slots: number;
  start_date: string;
  end_date?: string | null;
  start_time: string;
  end_time: string;
  timezone: string;
  days_of_week: number[];
  delay?: number;
  slots_visible: number;
  buffer_start?: number;
  buffer_end?: number;
  allocation_tags: string[];
  cooldown_duration?: number;
  business_email?: boolean;
  anonymous_guest?: boolean;
};

export type RealtimeEventPayload = {
  allocation_tags: string[];
  fallback_event_id?: number | undefined;
  business_email?: boolean;
  connect_calendar?: boolean;
  anonymous_guest?: boolean;
};

export type EventConfig = {
  configs: object
}

export type EventTrackPayload = {
  url: string;
  timestamp: string;
  event_id: number;
  is_realtime: boolean;
}

class Event {
  getEvent = (slug: string, userId: string) =>
    HttpClient.get(`${API_BASE_URL}/event?slug=${slug}${userId ? `&user_id=${userId}` : ''}`);

  getRealtimeEvent = (slug: string, sourceUrl?: string) =>
    HttpClient.get(`${API_BASE_URL}/event/realtime?slug=${slug}${sourceUrl ? `&source_url=${sourceUrl}` : ''}`);

  getEventByID = (id: number) =>
    HttpClient.get(`${API_BASE_URL}/event/${id}`, { withCredentials: true });

  getRealtimeEventByID = (id: number) =>
    HttpClient.get(`${API_BASE_URL}/event/realtime/${id}`, {
      withCredentials: true,
    });

  getAllRealtimeEvents = () =>
    HttpClient.get(`${API_BASE_URL}/event/realtime/all`, {
      withCredentials: true,
    });

  getAllEvents = () =>
    HttpClient.get(`${API_BASE_URL}/event/all`, { withCredentials: true });

  getBookingDetails = (id: number) =>
    HttpClient.get(`${API_BASE_URL}/event/booking/${id}`);

  trackEventUrl = (data: EventTrackPayload) =>
    HttpClient.post(`${API_BASE_URL}/event/tracking`, data, { withCredentials: true })

  createEvent = (data: EventPayload) =>
    HttpClient.post(`${API_BASE_URL}/event`, data, { withCredentials: true });

  createRealtimeEvent = (data: RealtimeEventPayload) =>
    HttpClient.post(`${API_BASE_URL}/event/realtime`, data, {
      withCredentials: true,
    });

  createQuestions = (event_id: number, data: QuestionPayload[]) =>
    HttpClient.post(`${API_BASE_URL}/event/${event_id}/question`, data, {
      withCredentials: true,
    });

  createRealtimeQuestions = (event_id: number, data: QuestionPayload[]) =>
    HttpClient.post(
      `${API_BASE_URL}/event/realtime/${event_id}/question`,
      data,
      {
        withCredentials: true,
      }
    );

  createExceptions = (event_id: number, data: ScheduleExceptionPayload[]) =>
    HttpClient.post(
      `${API_BASE_URL}/event/${event_id}/schedule_exception`,
      data,
      { withCredentials: true }
    );

  createHolidays = (event_id: number, data: ScheduleHolidaysPayload[]) =>
    HttpClient.post(
      `${API_BASE_URL}/event/${event_id}/schedule_holidays`,
      data,
      { withCredentials: true }
    );

  editEvent = (event_id: number, data: EventPayload) =>
    HttpClient.put(`${API_BASE_URL}/event/${event_id}`, data, {
      withCredentials: true,
    });

  editRealtimeEvent = (event_id: number, data: RealtimeEventPayload) =>
    HttpClient.put(`${API_BASE_URL}/event/realtime/${event_id}`, data, {
      withCredentials: true,
    });

  editRealtimeEventConfig = (event_id: number, data: EventConfig) =>
    HttpClient.put(`${API_BASE_URL}/event/realtime/${event_id}/config`, data, {
      withCredentials: true,
    });

  editScheduledEventConfig = (event_id: number, data: EventConfig) =>
    HttpClient.put(`${API_BASE_URL}/event/${event_id}/config`, data, {
      withCredentials: true,
    });

  inactivateEvent = (event_id: number) =>
    HttpClient.put(
      `${API_BASE_URL}/event/${event_id}/status`,
      { status: "inactive" },
      { withCredentials: true }
    );

  inactivateRealtimeEvent = (event_id: number) =>
    HttpClient.put(
      `${API_BASE_URL}/event/realtime/${event_id}/status`,
      { status: "inactive" },
      { withCredentials: true }
    );

  activateEvent = (event_id: number) =>
    HttpClient.put(
      `${API_BASE_URL}/event/${event_id}/status`,
      { status: "active" },
      { withCredentials: true }
    );

  activateRealtimeEvent = (event_id: number) =>
    HttpClient.put(
      `${API_BASE_URL}/event/realtime/${event_id}/status`,
      { status: "active" },
      { withCredentials: true }
    );

  deleteEvent = (event_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/event/${event_id}`,
      {},
      { withCredentials: true }
    );

  deleteRealtimeEvent = (event_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/event/realtime/${event_id}`,
      {},
      { withCredentials: true }
    );

  deleteQuestion = (event_id: number, question_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/event/${event_id}/question/${question_id}`,
      {},
      { withCredentials: true }
    );

  deleteRealtimeQuestion = (event_id: number, question_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/event/realtime/${event_id}/question/${question_id}`,
      {},
      { withCredentials: true }
    );

  deleteScheduleException = (event_id: number, exception_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/event/${event_id}/schedule_exception/${exception_id}`,
      {},
      { withCredentials: true }
    );

  deleteScheduleHolidays = (event_id: number, holiday_id: number) =>
    HttpClient.delete(
      `${API_BASE_URL}/event/${event_id}/schedule_holiday/${holiday_id}`,
      {},
      { withCredentials: true }
    );
}

const EventService = new Event();
export default EventService;
