import styles from './common.module.css';

const ThreeDotLoader = () => (
  <div className={styles["zt-three-dot-loader"]}>
    <div />
    <div />
    <div />
  </div>
);

export default ThreeDotLoader;
