import Axios, { AxiosRequestConfig } from "axios";

class HttpService {
  get(url: string, config: AxiosRequestConfig = {}) {
    return Axios.get(url, { ...config });
  }

  post(url: string, data = {}, config: AxiosRequestConfig = {}) {
    return Axios.post(url, data, { ...config });
  }

  put(url: string, data = {}, config: AxiosRequestConfig = {}) {
    return Axios.put(url, data, { ...config });
  }

  delete(url: string, data = {}, config: AxiosRequestConfig = {}) {
    return Axios.delete(url, { data, ...config });
  }
}

const HttpClient = new HttpService();

export default HttpClient;
