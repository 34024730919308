import React from "react";

type ILayoutProps = {
  children: React.ReactNode;
};

const Layout = (props: ILayoutProps): JSX.Element => {
  const { children } = props;
  return (
    <div>
      <main>{children}</main>
    </div>
  );
};
export default Layout;
