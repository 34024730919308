import { format } from "date-fns";

export type WeekHeaderProps = {
  currentDate: Date;
  isSelected: boolean;
  isSelectable: boolean;
};

const WeekHeader = (props: WeekHeaderProps): JSX.Element => {
  const { currentDate, isSelected, isSelectable } = props;

  const styles = {
    wrapper: {
      base: "flex flex-wrap w-full capitalize",
    },
    label:
      "text-base font-normal flex-grow basis-0 text-center max-w-full",
    selected: "text-white",
    nonselectable: "text-secondary-dark",
  };

  return (
    <div className={`${styles.wrapper.base}`}>
      <div
        className={`${styles.label} ${
          !isSelectable
            ? styles.nonselectable
            : isSelected
            ? styles.selected
            : "text-secondary-dark"
        }`}
      >
        {format(currentDate, "EEE")}
      </div>
    </div>
  );
};

export default WeekHeader;
