import { ZIPTEAMS_API_BASE_URL } from "../../utils/constants";
import HttpClient from "../../utils/http-client";

export type IMeetCustomerSignIn = {
  room_id: string;
  participant_name: string;
  meet_passcode: string;
};

class Conversation {
  customerSignIn = (payload: IMeetCustomerSignIn) =>
    HttpClient.post(
      `${ZIPTEAMS_API_BASE_URL}/v1/conversation/signin/customer`,
      payload
    );
}

const ConversationService = new Conversation();
export default ConversationService;

// https://api.dev.zipteams.com/api/v1/conversation/signin/customer
