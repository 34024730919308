import React from 'react';
import { MS_APP_DOMAIN } from '../../utils/constants';

  type VideoMSProps = {
    isAudioMuted: boolean;
    isVideoMuted: boolean;
    userName: string;
    roomCode: string;
  };

  const VideoMeetMS: React.FC<VideoMSProps> = (props) => {
    const { isAudioMuted, isVideoMuted, userName, roomCode } = props;
    const styles = {
      height: '100%',
      width: '100%',
      border: 0,
      flex: 1,
    };
    return (
      <iframe
        title="zip-ms-app"
        allow="camera *;microphone *;display-capture *"
        src={`${MS_APP_DOMAIN}/meeting/${roomCode}?name=${
          userName || ''
        }&audiomuted=${Boolean(isAudioMuted)}&videomuted=${Boolean(
          isVideoMuted,
        )}&internalredirect=false&hideheader=true`}
        style={styles}
      />
    );
  };

  export default VideoMeetMS;
