import { endOfWeek, format, subWeeks, addWeeks, isThisWeek } from "date-fns";
import { useState } from "react";
import { ReactComponent as RightArrow } from "../../assets/icons/rightarrow.svg";
import { ReactComponent as RightArrowDisabled } from "../../assets/icons/rightarrow-disabled.svg";

export type WeekSelectorProps = {
  weekStartDate: Date;
  isFirstWeek?: boolean;
  isLastWeek?: boolean;
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
  onWeekChange(month: Date): any;
};

const MonthSelector = (props: WeekSelectorProps): JSX.Element => {
  const {
    weekStartDate,
    isFirstWeek = false,
    isLastWeek = false,
    onWeekChange,
    weekStartsOn = 1,
  } = props;

  const [currentWeekStartDate, setCurrentWeekStartDate] =
    useState<Date>(weekStartDate);

  const styles = {
    wrapper: {
      base: "w-full flex flex-wrap justify-between items-center my-3",
    },
    label: {
      base: "text-base leading-5 font-bold text-primary-dark underline",
    },
    navigation: {
      base: "flex gap-2",
      button: {
        base: "text-secondary disabled:pointer-events-none disabled:text-primary-dark outline-none rounded-full disabled:bg-transparent flex justify-center items-center",
      },
    },
  };

  const onClickPrevious = () => {
    const previousWeek = subWeeks(currentWeekStartDate, 1);
    setCurrentWeekStartDate(previousWeek);
    onWeekChange(previousWeek);
  };

  const onClickNext = () => {
    const nextWeek = addWeeks(currentWeekStartDate, 1);
    setCurrentWeekStartDate(nextWeek);
    onWeekChange(nextWeek);
  };

  return (
    <div className={`${styles.wrapper.base}`}>
      <div className={`${styles.label.base}`}>
        <span>
          {isThisWeek(currentWeekStartDate)
            ? "This week"
            : `${format(currentWeekStartDate, "dd MMM")} - ${format(
                endOfWeek(currentWeekStartDate, { weekStartsOn }),
                "dd MMM"
              )}, ${format(currentWeekStartDate, "yyyy")}`}
        </span>
      </div>
      <div className={`${styles.navigation.base}`}>
        <button
          type="button"
          className={`${styles.navigation.button.base} ${
            !isFirstWeek ? "transform rotate-180" : ""
          }`}
          disabled={isFirstWeek}
          onClick={onClickPrevious}
        >
          {isFirstWeek ? (
            <RightArrowDisabled className="w-[18px] h-[18px]" />
          ) : (
            <RightArrow className="w-[18px] h-[18px]" />
          )}
        </button>
        <button
          type="button"
          className={`${styles.navigation.button.base} ${
            isLastWeek ? "transform rotate-180" : ""
          }`}
          disabled={isLastWeek}
          onClick={onClickNext}
        >
          {isLastWeek ? (
            <RightArrowDisabled className="w-[18px] h-[18px]" />
          ) : (
            <RightArrow className="w-[18px] h-[18px]" />
          )}
        </button>
      </div>
    </div>
  );
};

export default MonthSelector;
