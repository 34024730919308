/* eslint-disable no-useless-escape */
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import * as Sentry from '@sentry/react';
// import * as amplitude from '@amplitude/analytics-browser';

import "./index.css";
import { Tracking } from "./utils/tracking";
import reportWebVitals from "./reportWebVitals";
import ScheduledApp from "./apps/scheduled";
import RealtimeApp from "./apps/realtime";
import {
  Dashboard as ScheduledEventDashboard,
  Create as ScheduledEventCreate,
  Edit as ScheduledEventEdit,
} from "./apps/admin/scheduled";
import {
  Configure as RealtimeEventConfig,
  Dashboard as RealtimeDashboard,
} from "./apps/admin/realtime";
import NewUserOnb from "./apps/onboarding";
import { useEffect, useState } from "react";
import AuthService from "./services/auth.service";
import OAuth from "./components/oauth";
import CancelMeeting from "./apps/cancel-meeting";
import RescheduleMeeting from "./apps/reschedule-meeting";
import ErrorBoundary from "./hoc/errorBoundary";
import packageJson from '../package.json';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_SAMPLING_RATE = process.env.REACT_APP_SENTRY_SAMPLING_RATE;
const ENVIROMENT = process.env.REACT_APP_ENV;
const RELEASE = packageJson.version;
// const AMPLITUDE_VAR = process.env.REACT_APP_AMPLITUDE_VAR!;
// amplitude.init(AMPLITUDE_VAR);

if (ENVIROMENT === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'getReadModeConfig',
      'getReadModeExtract',
      'getReadModeRender',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.'
    ],
    environment: ENVIROMENT,
    release: RELEASE,
    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: Number(SENTRY_SAMPLING_RATE) || 0,
    // Session Replay
    // This sets the sample rate at 10%.
    // You may want to change it to 100% while in development
    // and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.0,
    // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 0.2,
    allowUrls: [
      'dev.zipme.at',
      'zipme.at',
    ]
  });
}

const Authenticated = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        await AuthService.verify();
        setIsAuthenticated(true);
      } catch (error) {
        console.error(error);
        setIsAuthenticated(false);
        navigate("/manage/oauth", {
          state: { redirect: `${location.pathname}${location.search}` },
        });
      }
    })();
  });

  return isAuthenticated ? children : <></>;
};

Tracking.initialise(String(process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS));
Tracking.pageview();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Sentry.ErrorBoundary>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="manage">
          <Route path="oauth" element={<OAuth />} />
          {/* <Route
            path="scheduled-events"
            element={
              <Authenticated>
                <ScheduledEventDashboard />
              </Authenticated>
            }
          />
          <Route
            path="scheduled-events/create"
            element={
              <Authenticated>
                <ScheduledEventCreate />
              </Authenticated>
            }
          />
          <Route
            path="scheduled-events/edit/:id"
            element={
              <Authenticated>
                <ScheduledEventEdit />
              </Authenticated>
            }
          />
          <Route
            path="realtime-events"
            element={
              <Authenticated>
                <RealtimeDashboard />
              </Authenticated>
            }
          />
          <Route
            path="realtime-events/configure"
            element={
              <Authenticated>
                <RealtimeEventConfig />
              </Authenticated>
            }
          /> */}
          <Route
            path="onboarding"
            element={
              <Authenticated>
                <NewUserOnb />
              </Authenticated>
            }
          />
        </Route>
        <Route path="/:org" element={<RealtimeApp />} />
        <Route path="/:org/realtime" element={<RealtimeApp />} />
        <Route path="/:org/instant" element={<RealtimeApp />} />
        <Route path="/:org/:slug/realtime" element={<RealtimeApp />} />
        <Route path="/:org/:slug/instant" element={<RealtimeApp />} />
        <Route path="/:org/:slug" element={<ScheduledApp />} />
        <Route path="/:org/:slug/:date" element={<ScheduledApp />} />
        <Route path="/:org/:slug/cancel-meeting" element={<CancelMeeting />} />
        <Route
          path="/:org/:slug/reschedule-meeting"
          element={<RescheduleMeeting />}
        />
        <Route
          path="/:org/:slug/reschedule-meeting/:date"
          element={<RescheduleMeeting />}
        />
      </Routes>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
