import { Switch } from "@headlessui/react";

type ToggleProps = {
  label?: string;
  required?: boolean;
  checked: boolean;
  size?: string;
  onChange: (val: boolean) => void;
};
const ToggleComponent = (props: ToggleProps) => {
  const { checked, onChange, label, required = false, size } = props;
  return (
    <Switch.Group>
      <div className="flex items-center">
        {label && (
          <Switch.Label className="font-bold text-sm lg:text-base mr-4">
            {label} {required ? "*" : ""}
          </Switch.Label>
        )}
        <Switch
          checked={checked}
          onChange={onChange}
          className={`${
            checked ? "bg-app-green" : "bg-secondary-dark"
          } relative inline-flex ${size === 'large' ? 'w-[60px] h-[30px] lg:w-20 lg:h-10' : 'h-4 w-7'} items-center rounded-full transition-colors focus:outline-none`}
        >
          <span
            className={`${
              checked
                ? `${size === 'large' ? 'translate-x-[34px] lg:translate-x-11 bg-white' : 'translate-x-3 bg-white'}`
                : `${size === 'large' ? 'translate-x-1 lg:translate-x-1.5 bg-white' : 'translate-x-1 bg-primary-gray'}`
            } inline-block ${size === 'large' ? 'w-[22px] h-[22px] lg:w-[30px] lg:h-[30px]' : 'h-3 w-3'} transform rounded-full  transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};
export default ToggleComponent;
